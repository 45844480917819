import { API_ENDPOINTS } from "../config";
import { http } from "../utils/http";
import { store } from "../State/store/configureStore";
import { closeProgress, showProgress } from "../State/actions/uploadProgress";

class MasterDataService {
  static loadData(patientTypeId) {
    let api = API_ENDPOINTS.masterData + "?page=0&size=1000";
    if (patientTypeId) {
      api += "&pTypeId=" + patientTypeId;
    }
    return http.request(api).then(data => {
      return data && data._embedded ? data._embedded.masterDatas : [];
    });
  }
  static addNewData(data) {
    return http.request(API_ENDPOINTS.masterData, "post", data);
  }

  static editMasterData(data, id) {
    return http.request(API_ENDPOINTS.masterDataEdit(id), "patch", data);
  }

  static deleteMasterData(dataId) {
    return http.request(API_ENDPOINTS.masterDataEdit(dataId), "delete");
  }

  static uploadCSV = (patientTypeId, masterDataName, csvFile) => {
    const formData = new FormData();
    formData.append("pTypeId", patientTypeId);
    formData.append("name", masterDataName);
    formData.append("files", csvFile);
    return http
      .request(
        API_ENDPOINTS.masterDataCSVUpload,
        "post",
        formData,
        null,
        progressEvent => {
          const uploadProgress = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          store.dispatch(showProgress(uploadProgress));
        },
        120000
      )
      .then(response => {
        store.dispatch(closeProgress());
        return response;
      });
  };

  static getMasterDataValueOptions(dataKey, callback) {
    if (!dataKey) {
      return;
    }
    MasterDataService.loadData().then((masterData = []) => {
      let filtered = masterData.filter(m => m.dataKey === dataKey)[0];
      let data = filtered ? filtered.data : [];
      let options = data.map(d => ({
        value: d.code,
        label: d.display,
        data: d
      }));

      callback(options);
    });
  }
}

const masterDataService = MasterDataService;

export default masterDataService;
