import React, { Component } from "react";
import { FormGroup, Input } from "reactstrap";
import { generateUIAttributeNameText } from "../Utils";

class HumanNameAttribute extends Component {
  static getDisplayValue(av) {
    return av.valueHumanName
      ? generateUIAttributeNameText(av.valueHumanName)
      : null;
  }

  static getDisplayValueInFilter(av) {
    return av.valueHumanName ? av.valueHumanName.given : null;
  }

  render() {
    let { attributeValue, setData } = this.props;

    attributeValue.valueHumanName = attributeValue.valueHumanName || {};
    let hnVal = attributeValue.valueHumanName;
    return (
      <div className="d-flex">
        <Input
          className="ui-attribute-el"
          type="select"
          value={hnVal.prefix}
          onChange={e => {
            attributeValue.valueHumanName = attributeValue.valueHumanName || {};
            attributeValue.valueHumanName.prefix = e.target.value;
            setData(attributeValue);
          }}
          placeholder={"Prefix"}
          onBlur={this.props.onBlur}
        >
          <option value=""> Not Set </option>
          <option value="Ms"> Ms </option>
          <option value="Miss"> Miss </option>
          <option value="Mrs"> Mrs </option>
          <option value="Mr"> Mr </option>
          <option value="Master"> Master </option>
          <option value="Fr"> Father (Fr) </option>
          <option value="Rev"> Reverend (Rev) </option>
          <option value="Dr"> Doctor (Dr) </option>
          <option value="Atty"> Attorney (Atty) </option>
          <option value="Hon"> Honorable (Hon) </option>
          <option value="Prof"> Professor (Prof) </option>
          <option value="Pres"> President (Pres) </option>
          <option value="VP"> Vice President (VP) </option>
          <option value="Gov"> Governor (Gov) </option>
          <option value="Ofc"> Officer (Ofc) </option>
        </Input>

        <Input
          className="mx-2 ui-attribute-el"
          value={hnVal.given}
          onChange={e => {
            attributeValue.valueHumanName.given = e.target.value;
            setData(attributeValue);
          }}
          type="text"
          placeholder={"Given Name"}
          autoComplete="off"
          onBlur={this.props.onBlur}
        />

        <Input
          className="ui-attribute-el"
          value={hnVal.family}
          onChange={e => {
            attributeValue.valueHumanName.family = e.target.value;
            setData(attributeValue);
          }}
          type="text"
          autoCapitalize="words"
          placeholder={"Family Name"}
          autoComplete="off"
          onBlur={this.props.onBlur}
        />
      </div>
    );
  }
}

export default HumanNameAttribute;
