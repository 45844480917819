import React, { PureComponent } from "react";
import Mandatory from "./Mandatory";
import classnames from "classnames";
import AttributeInfo from "./AttributeInfo";

export default class NameAttribute extends PureComponent {
  render() {
    let {
      placeholder,
      attribute,
      index,
      changeField,
      handleOnBlur
    } = this.props;
    let description = attribute.description || attribute.title;
    let av = attribute.value || {};

    return (
      <div
        key={index}
        className="col-12 my-1 pb-2"
        style={{ backgroundColor: "#fff" }}
        ref={attrEl => {
          if (this.props.setAttributeRef) {
            this.props.setAttributeRef(attribute.name, attrEl);
          }
        }}
      >
        {!placeholder && (
          <label style={{ fontWeight: 500 }}>
            {description}
            {attribute.mandatory && <sup style={{ color: "red" }}>*</sup>}
          </label>
        )}

        <div className="d-flex flex-column">
          <div className="d-flex">
            <input
              value={av && av.valueHumanName ? av.valueHumanName.given : ""}
              onChange={e => {
                av.valueHumanName = av.valueHumanName || {};
                av.valueHumanName.given = e.target.value;
                attribute.value = av;
                changeField(index, attribute);
              }}
              type="text"
              placeholder={placeholder ? "First Name" : "First"}
              autoComplete="off"
              className={classnames(
                attribute.error
                  ? "is-invalid form-control mr-1"
                  : "form-control mr-1"
              )}
              onBlur={() => {
                handleOnBlur(index, attribute);
              }}
              style={{ borderRadius: "4px" }}
            />

            <input
              className={classnames(
                attribute.error
                  ? "is-invalid form-control mr-1"
                  : "form-control mr-1"
              )}
              value={av && av.valueHumanName ? av.valueHumanName.family : ""}
              onChange={e => {
                av.valueHumanName = av.valueHumanName || {};
                av.valueHumanName.family = e.target.value;
                attribute.value = av;
                changeField(index, attribute);
              }}
              type="text"
              placeholder={placeholder ? "Last Name" : "Last"}
              autoComplete="off"
              onBlur={() => {
                handleOnBlur(index, attribute);
              }}
              style={{ borderRadius: "4px" }}
            />
          </div>
          {placeholder && <Mandatory mandatory={attribute.mandatory} />}
          {attribute.error && (
            <div className="invalid-feedback d-block">{attribute.error}</div>
          )}
          <AttributeInfo info={attribute.info} />
        </div>
      </div>
    );
  }
}
