import { API_ENDPOINTS } from "../config";
import { ROLE_FACILITY_ADMIN } from "../security/roles";
import { http } from "../utils/http";
import { MPS_SUPPORT_EMAIL } from "../Shared/Constants";

class OrganizationsService {
  add(data) {
    return http.request(API_ENDPOINTS.addFacility(), "post", data);
  }

  update(role, id, name, address, terms) {
    return http.request(API_ENDPOINTS.updateFacility(role, id), "patch", {
      name: name,
      uiProfile: {
        termsAndConditions: terms
      }
    });
  }

  edit(role, id, data) {
    return http.request(API_ENDPOINTS.updateFacility(role, id), "patch", data);
  }

  updateBranding(role, id, data) {
    delete data.id;
    return http.request(API_ENDPOINTS.updateFacility(role, id), "patch", {
      uiProfile: {
        appTheme: data
      }
    });
  }

  loadSingle(role, id) {
    const url =
        role === ROLE_FACILITY_ADMIN
        ? API_ENDPOINTS.getFacilityInfo()
        : API_ENDPOINTS.loadOrganization(id);

    return http.request(url);
  }

  load(page) {
    return http
      .request(API_ENDPOINTS.getAllFacilities() + `?page=0&size=1000`)
      .then(data => {
        if (data && data._embedded) {
          return {
            data: data._embedded.facilityResources || [],
            loadMore: false
          };
        } else {
          return {
            data: [],
            loadMore: false
          };
        }
      });
  }

  loadProvisionedPackages() {
    return http.request(API_ENDPOINTS.loadProvisionedPackages()).then(data => {
      return data && data._embedded ? data._embedded.provisionedPackages : [];
    });
  }

  reviewReminder(type, data) {
    let api = null;
    if (type === "invite") {
      api = API_ENDPOINTS.previewInviteReminder;
    } else if (type === "inactivity") {
      api = API_ENDPOINTS.previewInactivityReminder;
    } else if (type === "survey") {
      api = API_ENDPOINTS.previewSurveyCompletionReminder;
    }

    api += "?executeMode=false&greaterThanDays=" + data.days;

    if (type === "survey") {
      api += "&surveyId=" + data.surveyId;
    }

    delete data.days;
    delete data.surveyId;
    return http.request(api, "post", data).then(response => {
      if (!response || response.length === 0) {
        return [];
      }
      return response;
      // return response.map(r => {
      //   let parts = r.split(", ");
      //   return {
      //     name: parts[1],
      //     date: parts[2],
      //     email: parts[3]
      //   };
      // });
    });
  }

  sendReminder(type, data) {
    let api = null;
    if (type === "invite") {
      api = API_ENDPOINTS.previewInviteReminder;
    } else if (type === "inactivity") {
      api = API_ENDPOINTS.previewInactivityReminder;
    } else if (type === "survey") {
      api = API_ENDPOINTS.previewSurveyCompletionReminder;
    }

    api += "?executeMode=true&greaterThanDays=" + data.days;

    if (type === "survey") {
      api += "&surveyId=" + data.surveyId;
    }
    return http.request(
      api,
      "post",
      {
        reminderMessage: data.reminderMessage,
        reminderMessageEmailSubject: data.reminderMessageEmailSubject,
        reminderEmailMessage: data.reminderEmailMessage
      },
      {
        "Content-Type": "application/json"
      }
    );
  }

  getReminderAuditLogs(pageNumber) {
    return http
      .request(API_ENDPOINTS.reminderAudits + "?size=20&page=" + pageNumber)
      .then(data => {
        if (data && data._embedded) {
          return {
            audits: data._embedded.audits || [],
            pageInfo: data.page
          };
        }
        return {
          audits: [],
          pageInfo: { number: -1 }
        };
      });
  }

  loadTemplateParametersForMessages() {
    return http
      .request(API_ENDPOINTS.loadTemplateParameters("INVITE_MESSAGE"))
      .then(data => {
        return data && data._embedded ? data._embedded.valueParameters : [];
      });
  }

  fetchSupportEmailFromFacilityInfo(facilityInfo) {
    let supportEmail = MPS_SUPPORT_EMAIL;
    if (!facilityInfo) {
      return supportEmail;
    }
    let supportContacts = facilityInfo.supportContacts || [];
    if (supportContacts[0] && supportContacts[0].value) {
      supportEmail = facilityInfo.supportContacts[0].value;
    }
    return supportEmail;
  }
}

const organizationsService = new OrganizationsService();

export default organizationsService;
