import React, { PureComponent } from "react";
import { Button, Col, Row } from "reactstrap";

class SignupIntro extends PureComponent {
  render() {
    let { facilityInfo, noTitle } = this.props;
    let {
      introductionTitle,
      introductionText,
      introductionContents
    } = facilityInfo;
    let introductionContent = null;
    if (introductionContents) {
      introductionContent = introductionContents[0];
    }
    return (
      <div>
        <Row>
          <Col xs="12">
            {!noTitle && <h5 className="text-center">{introductionTitle}</h5>}

            {introductionContent && (
              <div className="my-3">
                {introductionContent.format === "IMAGE" && (
                  <div className="d-flex justify-content-center">
                    <img src={introductionContent.contentURL} width="75%" />
                  </div>
                )}
                {introductionContent.format === "VIDEO" && (
                  <video
                    controls
                    src={introductionContent.contentURL}
                    width="100%"
                    preload="none"
                    poster={introductionContent.thumbnailURL}
                  >
                    Sorry, your browser doesn't support embedded videos.
                  </video>
                )}
              </div>
            )}
            <div className="my-3">
              <div dangerouslySetInnerHTML={{ __html: introductionText }} />
            </div>

            <Button
              color="primary"
              size="lg"
              className="px-4"
              block
              onClick={this.props.incrementStep}
            >
              Let's Get Started
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}

export default SignupIntro;
