export const REACT_APP_HOSTING_ENV =
  process.env.REACT_APP_HOSTING_ENV || "main-dev";

const Config = env => {
  switch (env) {
    case "main-prod": {
      return [
        {
          env: "production",
          region: "EU",
          url: "https://api.mypatientspace.com",
          regionCode: "eu",
          appURL: "https://web.mypatientspace.com"
        },
        {
          region: "UAE",
          url: "https://uaeapi.mypatientspace.com",
          regionCode: "ae",
          appURL: "https://web.mypatientspace.com"
        }
      ];
    }
    case "covid": {
      return [
        {
          env: "production",
          region: "EU",
          url: "https://covidapi.mypatientspace.com",
          regionCode: "eu",
          appURL: "https://covid.mypatientspace.com"
        }
      ];
    }
    case "main-dev":
    default: {
      return [
        {
          env: "development",
          region: "EU",
          url: "https://testservice.mypatientspace.com",
          // url: "https://covidapi.mypatientspace.com",
          // url: "http://localhost:8080",
          regionCode: "eu",
          appURL: "https://testweb.mypatientspace.com"
        },
        {
          region: "UAE",
          url: "https://uaetestservice.mypatientspace.com",
          regionCode: "ae",
          appURL: "https://testweb.mypatientspace.com"
        }
      ];
    }
  }
};

export default Config(REACT_APP_HOSTING_ENV);
