import { defineMessages } from "react-intl.macro";

const Messages = defineMessages({
  // m: {
  //   id: "",
  //   defaultMessage: ""
  // },

  not_taken_assignments: {
    id: "not_taken_assignments",
    defaultMessage: "You have not taken any assignments yet"
  },

  pt_not_taken_assignments: {
    id: "pt_not_taken_assignments",
    defaultMessage: "This patient has not taken any assessments yet."
  },

  // m: {
  //   id: "",
  //   defaultMessage: ""
  // },

  patient_interactions: {
    id: "patient_interactions",
    defaultMessage: "Patient Interactions"
  },

  select_patient: {
    id: "select_patient",
    defaultMessage: "Select Patient"
  },

  self: {
    id: "self",
    defaultMessage: "Self"
  },

  care_teams: {
    id: "care_teams",
    defaultMessage: "Care Teams"
  },

  copied_appt_sch: {
    id: "copied_appt_sch",
    defaultMessage: "Appointment Schedule URL has been copied to the clipboard."
  },
  appt_schedule_w_name: {
    id: "appt_schedule_w_name",
    defaultMessage: "Appointment Schedule - {name}"
  },

  copied: {
    id: "copied",
    defaultMessage: "Copied!"
  },

  copied_cp_safari: {
    id: "copied_cp_safari",
    defaultMessage: "Copied to clipboard, please paste into Safari browser."
  },

  patients_imported: {
    id: "patients_imported",
    defaultMessage: "Patients have been imported."
  },

  staff_imported: {
    id: "staff_imported",
    defaultMessage: "Staff have been imported."
  },

  import: {
    id: "import",
    defaultMessage: "Import"
  },

  confirm_appointment: {
    id: "confirm_appointment",
    defaultMessage: "Confirm Appointment"
  },
  vc_appointment_confirmation: {
    id: "vc_appointment_confirmation",
    defaultMessage: "Scheduling Video Call with {patientName} for:"
  },
  vc_patient_error: {
    id: "vc_patient_error",
    defaultMessage:
      "Video call is available only for patients who has set up their myPatientSpace account."
  },

  vc_appt_created: {
    id: "vc_appt_created",
    defaultMessage:
      "Created video call appointment with {patientName} for {dateTime}"
  },

  edit_contact: {
    id: "edit_contact",
    defaultMessage: "Edit Contact"
  },

  contact_updated: {
    id: "contact_updated",
    defaultMessage: " Contact has been updated"
  },

  contact_added: {
    id: "contact_added",
    defaultMessage: "Contact has been added"
  },

  contact_add_failed: {
    id: "contact_add_failed",
    defaultMessage: "Unable to add contact. Please try again later."
  },

  contact_removed: {
    id: "contact_removed",
    defaultMessage: "Contact has been removed."
  },

  remove_contact: {
    id: "remove_contact",
    defaultMessage: "Remove Contact"
  },

  add_contact: {
    id: "add_contact",
    defaultMessage: "Add Contact"
  },

  no_contacts: {
    id: "no_contacts",
    defaultMessage: "No Contacts"
  },

  placeholder_created: {
    id: "placeholder_created",
    defaultMessage: "Placeholder has been created"
  },
  create_placeholder_failed: {
    id: "create_placeholder_failed",
    defaultMessage: "Placeholder creation failed."
  },

  video_call: {
    id: "video_call",
    defaultMessage: "Video Call"
  },

  to_schedule_video_call: {
    id: "to_schedule_video_call",
    defaultMessage: "Schedule Video Call?"
  },

  tasks_stats_status: {
    id: "tasks_stats_status",
    defaultMessage: "Status - {status}"
  },

  tasks_stats_priority: {
    id: "tasks_stats_priority",
    defaultMessage: "Priority - {priority}"
  },

  tasks_for_name: {
    id: "tasks_for_name",
    defaultMessage: "{label} tasks for {user}"
  },

  add_status: {
    id: "add_status",
    defaultMessage: "Add Status"
  },

  special_notes_updated: {
    id: "special_notes_updated",
    defaultMessage: "Notes has been updated"
  },

  add_survey: {
    id: "add_survey",
    defaultMessage: "Add Survey"
  },

  profile_updated: {
    id: "profile_updated",
    defaultMessage: "Profile has been updated."
  },

  pref_locale_updated: {
    id: "pref_locale_updated",
    defaultMessage:
      // "Preferred Locale has been updated. Please log out and log back in for the new locale to take effect."
      "Preferred Locale has been updated."
  },

  content_preview: {
    id: "content_preview",
    defaultMessage: "Content Preview"
  },

  file_upload: {
    id: "file_upload",
    defaultMessage: "File Upload"
  },

  preview: {
    id: "preview",
    defaultMessage: "Preview"
  },

  html: {
    id: "html",
    defaultMessage: "HTML"
  },

  rich_text: {
    id: "rich_text",
    defaultMessage: "Rich Text"
  },

  type_content_here: {
    id: "type_content_here",
    defaultMessage: "Type content here."
  },

  category: {
    id: "category",
    defaultMessage: "Category"
  },
  availability: {
    id: "availability",
    defaultMessage: "Availability"
  },
  public: {
    id: "public",
    defaultMessage: "Public"
  },
  private: {
    id: "private",
    defaultMessage: "Private"
  },
  all_members: {
    id: "all_members",
    defaultMessage: "All - All members in the Patient's space"
  },
  staff_ct_members: {
    id: "staff_ct_members",
    defaultMessage: "Staff"
  },
  patient_only: {
    id: "patient_only",
    defaultMessage: "Patient - Only for Patient"
  },
  patient_members: {
    id: "patient_members",
    defaultMessage: "Patient and Members"
  },
  all_roles: {
    id: "all_roles",
    defaultMessage: "All (Admin, Staff/Doctor, Patient and Family)"
  },
  basic_text_update: {
    id: "basic_text_update",
    defaultMessage: "Basic Text update"
  },
  ideal_quick_notes: {
    id: "ideal_quick_notes",
    defaultMessage: "Ideal for quick notes"
  },
  pre_canned_messages: {
    id: "pre_canned_messages",
    defaultMessage: "Pre-canned messages"
  },
  welcome_texts_other: {
    id: "welcome_texts_other",
    defaultMessage: "Welcome texts, reminder & other"
  },
  images: {
    id: "images",
    defaultMessage: "Images"
  },
  notes_with_images: {
    id: "notes_with_images",
    defaultMessage: "Ideal for notes with images"
  },
  notes_with_audio_file: {
    id: "notes_with_audio_file",
    defaultMessage: "Ideal for notes with an audio file"
  },
  notes_with_video: {
    id: "notes_with_video",
    defaultMessage: "Ideal for notes with a video"
  },
  library_surveys: {
    id: "library_surveys",
    defaultMessage: "Library of surveys"
  },
  content_library_items: {
    id: "content_library_items",
    defaultMessage: "Content Library Items"
  },
  consent_documents: {
    id: "consent_documents",
    defaultMessage: "Consent Documents"
  },
  task_templates: {
    id: "task_templates",
    defaultMessage: "Templates for creating Task"
  },
  audio: {
    id: "audio",
    defaultMessage: "Audio"
  },
  video: {
    id: "video",
    defaultMessage: "Video"
  },
  library_item: {
    id: "library_item",
    defaultMessage: "Library Item"
  },

  existing_files: {
    id: "existing_files",
    defaultMessage: "Existing Files"
  },

  set_files: {
    id: "set_files",
    defaultMessage: "Please set files"
  },

  set_status_text: {
    id: "set_status_text",
    defaultMessage: "Set status text and add templateParams using '_'"
  },

  set_template_text: {
    id: "set_template_text",
    defaultMessage: "Please set template text (minimum 4 chars)"
  },
  save_template: {
    id: "save_template",
    defaultMessage: "Save template"
  },

  attach_type_files: {
    id: "attach_type_files",
    defaultMessage: "Please attach {messageType} file(s)."
  },

  what_to_say: {
    id: "what_to_say",
    defaultMessage: "What would you like to say to {name}?"
  },

  template: {
    id: "template",
    defaultMessage: "Template"
  },

  new_template: {
    id: "new_template",
    defaultMessage: "New Template"
  },

  edit_template: {
    id: "edit_template",
    defaultMessage: "Edit Template"
  },
  tabs_reordered: {
    id: "tabs_reordered",
    defaultMessage: "Patient view tabs have been reordered and saved."
  },
  survey_deleted: {
    id: "survey_deleted",
    defaultMessage: "Survey has been deleted."
  },
  delete_survey: {
    id: "delete_survey",
    defaultMessage: "Delete Survey"
  },
  hard_delete_survey_msg: {
    id: "hard_delete_survey_msg",
    defaultMessage:
      "Are you sure you want to delete survey {survey}? It will be permanently deleted from the system and can not be reverted."
  },
  delete_survey_msg: {
    id: "delete_survey_msg",
    defaultMessage:
      "Are you sure you want to delete survey {survey}? This can not be reverted."
  },
  delete_template: {
    id: "delete_template",
    defaultMessage: "Delete Template - {template}"
  },
  delete_s_template_msg: {
    id: "delete_s_template_msg",
    defaultMessage:
      "Are you sure you want to delete template {title}? This can not be reverted."
  },
  hard_delete_s_template_msg: {
    id: "hard_s_delete_template_msg",
    defaultMessage:
      "Are you sure you want to delete template {title}? It will be permanently deleted from the system and can not be reverted."
  },
  s_template_created: {
    id: "s_template_created",
    defaultMessage: "Status Template has been created."
  },
  s_template_create_error: {
    id: "s_template_create_error",
    defaultMessage: "Error while creating the template. {message}"
  },
  s_template_updated: {
    id: "s_template_updated",
    defaultMessage: "Status Template has been updated."
  },
  s_template_update_err: {
    id: "s_template_update_err",
    defaultMessage: "Error while updating the template. Please try again later."
  },
  attributes_saved: {
    id: "attributes_saved",
    defaultMessage:
      "Attribute changes have been saved. Please submit it to save in the backend."
  },
  survey_submitted: {
    id: "survey_submitted",
    defaultMessage: "Survey answers have been submitted."
  },
  s_template_ret_error: {
    id: "s_template_ret_error",
    defaultMessage:
      "Error while retrieving the template. Please try again later."
  },
  care_plan_not_exist: {
    id: "care_plan_not_exist",
    defaultMessage: "Care Plan that you have chosen does not exist."
  },
  care_plan_assigned: {
    id: "care_plan_assigned",
    defaultMessage: "Care Plan has been assigned."
  },
  sure_to_complete: {
    id: "sure_to_complete",
    defaultMessage: "Are you sure you want to complete the care plan?"
  },
  error_assigning_cp: {
    id: "error_assigning_cp",
    defaultMessage:
      "Error while assigning the Care Plan. Please try again later."
  },
  req_complete_cp: {
    id: "req_complete_cp",
    defaultMessage: "Request to complete Care Plan has been triggered."
  },

  view_mode: {
    id: "view_mode",
    defaultMessage: "View Mode"
  },
  trigger_mode: {
    id: "trigger_mode",
    defaultMessage: "Trigger Mode"
  },

  cp_triggered: {
    id: "cp_triggered",
    defaultMessage: "Care Plan has been triggered for the mentioned date."
  },

  both_reqd: {
    id: "both_reqd",
    defaultMessage: "Both the fields are required"
  },

  refresh: {
    id: "refresh",
    defaultMessage: "Refresh"
  },
  view_answers: {
    id: "view_answers",
    defaultMessage: "View Answers"
  },

  no_assigned_care_plans: {
    id: "no_assigned_care_plans",
    defaultMessage: "You have not been assigned any care plans yet."
  },
  milestone_with_title: {
    id: "milestone_with_title",
    defaultMessage: "Milestone - {title}"
  },

  report_last_generated_at: {
    id: "report_last_generated_at",
    defaultMessage: "Report last generated at: {date}."
  },

  day_of_treatment: {
    id: "day_of_treatment",
    defaultMessage: "Day {dayNumberInTreatment} of {treatmentDuration}"
  },

  assign_care_plan: {
    id: "assign_care_plan",
    defaultMessage: "Assign a Care Plan"
  },
  open_test_mode: {
    id: "open_test_mode",
    defaultMessage: "Open Test Mode"
  },
  complete_care_plan: {
    id: "complete_care_plan",
    defaultMessage: "Complete Care Plan"
  },

  download_as_csv: {
    id: "download_as_csv",
    defaultMessage: "Download as CSV"
  },

  change_care_plan: {
    id: "change_care_plan",
    defaultMessage: "Change Care Plan"
  },
  milestones: {
    id: "milestones",
    defaultMessage: "Milestones"
  },
  todays_activities: {
    id: "todays_activities",
    defaultMessage: "Today's Activities"
  },
  care_plan_test_mode: {
    id: "care_plan_test_mode",
    defaultMessage: "Care Plan Test Mode"
  },
  edit_assigned_care_plan: {
    id: "edit_assigned_care_plan",
    defaultMessage: "Edit Assigned Care Plan"
  },

  sh_survey_details: {
    id: "sh_survey_details",
    defaultMessage: "Survey Details"
  },
  new_uc: {
    id: "new_uc",
    defaultMessage: "NEW"
  },
  no_pending_tasks: {
    id: "no_pending_tasks",
    defaultMessage: "No pending tasks for today."
  },
  sh_complete: {
    id: "sh_complete",
    defaultMessage: "Complete"
  },
  survey_pending: { id: "survey_pending", defaultMessage: "SURVEY: PENDING" },
  survey_completed: {
    id: "survey_completed",
    defaultMessage: "SURVEY: COMPLETED"
  },
  article: { id: "article", defaultMessage: "ARTICLE" },

  consent_pending: {
    id: "consent_pending",
    defaultMessage: "CONSENT: PENDING"
  },

  consent_completed: {
    id: "consent_completed",
    defaultMessage: "CONSENT: COMPLETED"
  },

  sh_appointment_type_md: {
    id: "sh_appointment_type_md",
    defaultMessage: "Appointment Type Master Data"
  },
  sh_appointment_location_md: {
    id: "sh_appointment_location_md",
    defaultMessage: "Appointment Schedule Location Master Data"
  },
  sh_appointment_vc_template: {
    id: "sh_appointment_vc_template",
    defaultMessage: "Appointment Template for Video calls"
  },
  fa_location: {
    id: "fa_location",
    defaultMessage: "Location"
  },
  sh_confirmation_messages: {
    id: "sh_confirmation_messages",
    defaultMessage: "Confirmation Messages"
  },
  sh_reminders_config: {
    id: "sh_reminders_config",
    defaultMessage: "Reminders Configuration"
  },
  sh_reminder_config_edit: {
    id: "sh_reminder_config_edit",
    defaultMessage: "Edit Reminder Configuration"
  },
  sh_delete: {
    id: "sh_delete",
    defaultMessage: "Delete"
  },
  sh_before_hours: {
    id: "sh_before_hours",
    defaultMessage: "Before Hours/Mins"
  },
  sh_email_sub: {
    id: "sh_email_sub",
    defaultMessage: "Email Subject"
  },
  sh_email_content: {
    id: "sh_email_content",
    defaultMessage: "Email Content"
  },
  sh_sms_content: {
    id: "sh_sms_content",
    defaultMessage: "SMS Content"
  },
  sh_status_message: {
    id: "sh_status_message",
    defaultMessage: "Status Message"
  },
  sh_submit: {
    id: "sh_submit",
    defaultMessage: "Submit"
  },
  sh_participants: {
    id: "sh_participants",
    defaultMessage: "Participants"
  },
  sh_type: {
    id: "sh_type",
    defaultMessage: "Type"
  },
  sh_master_data: {
    id: "sh_master_data",
    defaultMessage: "Master Data"
  },
  sh_master_data_key: {
    id: "sh_master_data_key",
    defaultMessage: "Master Data Key"
  },
  sh_master_data_value: {
    id: "sh_master_data_value",
    defaultMessage: "Master Data Value"
  },
  sh_staff: {
    id: "sh_staff",
    defaultMessage: "Staff"
  },
  sh_remove: {
    id: "sh_remove",
    defaultMessage: "Remove"
  },
  sh_add_participant: {
    id: "sh_add_participant",
    defaultMessage: "Add Participant"
  },
  sh_reminder_config: {
    id: "sh_reminder_config",
    defaultMessage: "Reminder Configuration"
  },
  sh_users: {
    id: "sh_users",
    defaultMessage: "Users"
  },
  sh_all_users: {
    id: "sh_all_users",
    defaultMessage: "All Users"
  },
  sh_all_patients: {
    id: "sh_all_patients",
    defaultMessage: "All Patients"
  },
  sh_selected_patients: {
    id: "sh_selected_patients",
    defaultMessage: "Selected Patients"
  },
  sh_search_patients: {
    id: "sh_search_patients",
    defaultMessage: "Patients From Search"
  },
  sh_set_campaign_mode: {
    id: "sh_set_campaign_mode",
    defaultMessage: "Please set campaign mode"
  },
  sh_set_sms_content: {
    id: "sh_set_sms_content",
    defaultMessage: "Please set sms content"
  },
  sh_set_subject: {
    id: "sh_set_subject",
    defaultMessage: "Please set subject"
  },
  sh_set_email_content: {
    id: "sh_set_email_content",
    defaultMessage: "Please set email content"
  },
  sh_send_messages_to: {
    id: "sh_send_messages_to",
    defaultMessage: "Send Messages To"
  },
  sh_50_patients: {
    id: "sh_50_patients",
    defaultMessage: "Maximum of 50 Patients"
  },
  sh_view: {
    id: "sh_view",
    defaultMessage: "View"
  },
  sh_cancel: {
    id: "sh_cancel",
    defaultMessage: "Cancel"
  },
  sh_patient_ids: {
    id: "sh_patient_ids",
    defaultMessage: "Patient Ids"
  },
  sh_set_patient_ids: {
    id: "sh_set_patient_ids",
    defaultMessage: "Please set patient Ids"
  },
  sh_set_number_of_patients: {
    id: "sh_set_number_of_patients",
    defaultMessage: "Please set Number of patients"
  },
  sh_set_one_of_message: {
    id: "sh_set_one_of_message",
    defaultMessage: "At least one the following messages is required"
  },
  sh_set_email_subject: {
    id: "sh_set_email_subject",
    defaultMessage: "Please set email subject"
  },
  sh_send_messages: {
    id: "sh_send_messages",
    defaultMessage: "Send Messages"
  },
  sh_sure_send_messages: {
    id: "sh_sure_send_messages",
    defaultMessage: "Are you sure you want to send the messages?"
  },
  patient: {
    id: "patient",
    defaultMessage: "Patient"
  },
  add: {
    id: "add",
    defaultMessage: "Add"
  },
  addNewPatient: {
    id: "add_new_patient",
    defaultMessage: "Add New Patient"
  },
  change: {
    id: "change",
    defaultMessage: "Change"
  },
  select: {
    id: "select",
    defaultMessage: "Select"
  },
  addPatient: {
    id: "add_patient",
    defaultMessage: "Add Patient"
  },
  patientType: {
    id: "patient_type",
    defaultMessage: "Patient Type"
  },
  setPatientType: {
    id: "set_patient_type",
    defaultMessage: "Please set patient type."
  },
  consultant: {
    id: "consultant",
    defaultMessage: "Consultant"
  },
  set_consultant: {
    id: "set_consultant",
    defaultMessage: "Please set consultant."
  },
  procedure: {
    id: "procedure",
    defaultMessage: "Procedure"
  },
  procedureDate: {
    id: "procedure_date",
    defaultMessage: "Procedure Date"
  },

  procedure_date_w_date: {
    id: "procedure_date_w_date",
    defaultMessage: "Procedure Date: {procedureDate}"
  },

  sure_to_deactivate_profile_w_name: {
    id: "sure_to_deactivate_profile_w_name",
    defaultMessage:
      "Are you sure you want to de-activate Patient profile {name}?"
  },

  sure_to_deactivate_profile: {
    id: "sure_to_deactivate_profile",
    defaultMessage: "Are you sure you want to de-activate Patient profile?"
  },

  submit: {
    id: "submit",
    defaultMessage: "Submit"
  },
  reset: {
    id: "reset",
    defaultMessage: "Reset"
  },
  filters: {
    id: "filters",
    defaultMessage: "Filters"
  },
  hideAdvanced: {
    id: "hide_advanced",
    defaultMessage: "Hide Advanced"
  },
  sortBy: {
    id: "sort_by",
    defaultMessage: "Sort By"
  },
  patients: {
    id: "patients",
    defaultMessage: "Patients"
  },
  advanced: {
    id: "advanced",
    defaultMessage: "Advanced"
  },
  agedDebt: {
    id: "aged_debt",
    defaultMessage: "Aged Debt"
  },
  watchlist: {
    id: "watchlist",
    defaultMessage: "Watchlist"
  },
  recent: {
    id: "recent",
    defaultMessage: "Recent"
  },
  removeFromWatchlist: {
    id: "remove_from_watchlist",
    defaultMessage: "Remove from watchlist"
  },
  addToWatchlist: {
    id: "add_to_watchlist",
    defaultMessage: "Add to watchlist"
  },
  create_new_task: {
    id: "create_new_task",
    defaultMessage: "Create Task"
  },
  submit_assessments: {
    id: "submit_assessments",
    defaultMessage: "Submit Assessments"
  },
  statuses_uc: {
    id: "statuses_uc",
    defaultMessage: "STATUSES"
  },
  reset_credentials: {
    id: "reset_credentials",
    defaultMessage: "Reset Credentials"
  },

  send_sms: {
    id: "send_sms",
    defaultMessage: "Send SMS"
  },

  send_email: {
    id: "send_email",
    defaultMessage: "Send Email"
  },

  reorder_tabs: {
    id: "reorder_tabs",
    defaultMessage: "Reorder Tabs"
  },

  de_activate_patient: {
    id: "de_activate_patient",
    defaultMessage: "De-Activate Patient"
  },

  not_viewed_by_patient: {
    id: "not_viewed_by_patient",
    defaultMessage: "Not Viewed By Patient"
  },

  staff_notes_uc: {
    id: "staff_notes_uc",
    defaultMessage: "STAFF NOTES"
  },
  care_plan_uc: {
    id: "care_plan_uc",
    defaultMessage: "CARE PLAN"
  },
  assessments_uc: {
    id: "assessments_uc",
    defaultMessage: "ASSESSMENTS"
  },
  tasks_uc: {
    id: "tasks_uc",
    defaultMessage: "TASKS"
  },
  usage_uc: {
    id: "usage_uc",
    defaultMessage: "USAGE"
  },
  audit_logs_uc: {
    id: "audit_logs_uc",
    defaultMessage: "AUDIT LOGS"
  },
  resendInvite: {
    id: "resend_invite",
    defaultMessage: "Resend Invite"
  },
  sendInvite: {
    id: "send_invite",
    defaultMessage: "Send Invite"
  },
  restore: {
    id: "restore",
    defaultMessage: "Restore"
  },
  noPatients: {
    id: "no_patients",
    defaultMessage: "No patients."
  },
  compactView: {
    id: "compact_view",
    defaultMessage: "Compact View"
  },
  mobilePhoneNumber: {
    id: "mobile_phone_number",
    defaultMessage: "Mobile Phone Number"
  },
  noLogs: {
    id: "no_logs",
    defaultMessage: "No logs available"
  },
  changeLogs: {
    id: "change_logs",
    defaultMessage: "Change Logs"
  },
  accessLogs: {
    id: "access_logs",
    defaultMessage: "Access Logs"
  },
  title: {
    id: "title",
    defaultMessage: "Title"
  },
  setTitle: {
    id: "setTitle",
    defaultMessage: "Please set title"
  },
  details: {
    id: "details",
    defaultMessage: "Details"
  },
  setDetails: {
    id: "set_details",
    defaultMessage: "Please set details"
  },
  visibility: {
    id: "visibility",
    defaultMessage: "Visibility"
  },
  documentType: {
    id: "document_type",
    defaultMessage: "Document Type"
  },
  pdf: {
    id: "pdf",
    defaultMessage: "PDF"
  },
  image: {
    id: "image",
    defaultMessage: "Image"
  },
  documents: {
    id: "documents",
    defaultMessage: "Documents"
  },
  setDocuments: {
    id: "set_documents",
    defaultMessage: "Please set documents"
  },
  topics: {
    id: "topics",
    defaultMessage: "Topics"
  },
  addDocument: {
    id: "add_document",
    defaultMessage: "Add Document"
  },
  description: {
    id: "description",
    defaultMessage: "Description"
  },
  noDocsFound: {
    id: "no_docs_found",
    defaultMessage: "No documents have been found for this patient"
  },
  markAsRIP: {
    id: "mark_as_rip",
    defaultMessage: "Mark as RIP"
  },
  cancel: {
    id: "cancel",
    defaultMessage: "Cancel"
  },
  yes: {
    id: "yes",
    defaultMessage: "Yes"
  },
  delete: {
    id: "delete",
    defaultMessage: "Delete"
  },
  noRecAssessments: {
    id: "no_rec_assessments",
    defaultMessage: "No recent assessments"
  },
  assessments: {
    id: "assessments",
    defaultMessage: "Assessments"
  },
  view: {
    id: "view",
    defaultMessage: "View"
  },
  send: {
    id: "send",
    defaultMessage: "Send"
  },
  sms: {
    id: "sms",
    defaultMessage: "SMS"
  },
  email: {
    id: "email",
    defaultMessage: "Email"
  },
  credentials: {
    id: "credentials",
    defaultMessage: "Credentials"
  },
  model: {
    id: "model",
    defaultMessage: "Model"
  },
  photos: {
    id: "photos",
    label: "Photos"
  },
  noDevsFound: {
    id: "no_devs_found",
    defaultMessage: "No devices have been found for this patient"
  },
  viewCompleteProfile: {
    id: "view_complete_profile",
    defaultMessage: "View Complete Profile"
  },
  save: {
    id: "save",
    defaultMessage: "Save"
  },
  dragDrop: {
    id: "drag_drop",
    defaultMessage: "Drag and drop to reorder the tabs"
  },
  yetToImpl: {
    id: "yet_to_impl",
    defaultMessage: "Yet to be implemented"
  },
  loginUserId: {
    id: "login_user_id",
    defaultMessage: "Login User Id"
  },
  setLoginUserId: {
    id: "set_login_user_id",
    defaultMessage: "Please set User Id"
  },
  sureToResetCreds: {
    id: "sure_to_reset_creds",
    defaultMessage:
      "Are you sure you want to reset the credentials for the patient?"
  },
  resetCredsWarningMsg: {
    id: "reset_creds_warning_msg",
    defaultMessage:
      "Clicking on the submit button will reset the credentials for the patient"
  },
  todayTasks: {
    id: "today_tasks",
    defaultMessage: "Today's Tasks"
  },
  viewTasks: {
    id: "view_tasks",
    defaultMessage: "View Tasks"
  },
  activities: {
    id: "activities",
    defaultMessage: "Activities"
  },
  name: {
    id: "name",
    defaultMessage: "Name"
  },
  relation: {
    id: "relation",
    defaultMessage: "Relation"
  },
  address: {
    id: "address",
    defaultMessage: "Address"
  },
  showAll: {
    id: "show_all",
    defaultMessage: "Show All"
  },
  editTaskData: {
    id: "edit_task_data",
    defaultMessage: "Edit Task Data"
  },
  editPatientProfile: {
    id: "edit_patient_profile",
    defaultMessage: "Edit Patient Profile"
  },
  rip: {
    id: "rip",
    defaultMessage: "RIP"
  },
  birthDate: {
    id: "birth_date",
    defaultMessage: "Birth Date"
  },
  notAvailable: {
    id: "not_available",
    defaultMessage: "Not Available"
  },
  lastActiveOn: {
    id: "last_active_on",
    defaultMessage: "Last Active On"
  },
  loginUserName: {
    id: "login_user_name",
    defaultMessage: "Login Username"
  },
  patientId: {
    id: "patient_id",
    defaultMessage: "Patient ID"
  },
  dob: {
    id: "dob",
    defaultMessage: "DOB"
  },
  fromDate: { id: "from_date", defaultMessage: "From Date" },
  toDate: { id: "to_date", defaultMessage: "To Date" },
  serialNumber: { id: "serial_number", defaultMessage: "Serial Number" },
  mode: { id: "mode", defaultMessage: "Mode" },
  statistics: { id: "statistics", defaultMessage: "Statistics" },
  usage: { id: "usage", defaultMessage: "Usage" },
  maskOnDates: { id: "mask_on_dates", defaultMessage: "Mask On Dates" },
  maskOffDates: { id: "mask_off_dates", defaultMessage: "Mask Off Dates" },
  leak: { id: "leak", defaultMessage: "Leak" },
  tgtIPAP: { id: "tgtIPAP", defaultMessage: "tgtIPAP" },
  spo: { id: "spo", defaultMessage: "SPO" },
  events: { id: "events", defaultMessage: "Events" },
  detailedReport: {
    id: "detailed_report",
    defaultMessage: "Detailed Report"
  },
  fetchDetailedReport: {
    id: "fetch_detailed_report",
    defaultMessage: "Fetch Detailed Report"
  },
  therapyComplianceReport: {
    id: "therapy_compliance_report",
    defaultMessage: "Therapy Compliance Report"
  },
  fetchTherapyComplianceReport: {
    id: "fetch_therapy_compliance_report",
    defaultMessage: "Fetch Therapy Compliance Report"
  },
  showConsolidatedReport: {
    id: "show_consolidated_report",
    defaultMessage: "Show Consolidated Report"
  },
  surveyDateAndScores: {
    id: "survey_date_and_scores",
    defaultMessage: "Assessment Date and Scores"
  },
  recentScore: { id: "recent_score", defaultMessage: "Recent Score" },
  answer: { id: "Ans", defaultMessage: "Answer" },
  na: { id: "na", defaultMessage: "NA" },
  totalScore: { id: "total_score", defaultMessage: "Total Score" },
  daywiseChartFor: {
    id: "day_wise_chart_for",
    defaultMessage: "Day wise Chart for"
  },
  answers: { id: "answers", defaultMessage: "Answers" },
  submissionDate: {
    id: "submission_date",
    defaultMessage: "Submission Date"
  },
  dateSurveyTaken: {
    id: "date_survey_taken",
    defaultMessage: "Date when the assessment was taken:"
  },
  surveyScore: { id: "survey_score", defaultMessage: "Assessment Score" },
  survey_value: { id: "survey_value", defaultMessage: "Assessment Value" },
  score: { id: "score", defaultMessage: "Score" },
  value: { id: "value", defaultMessage: "Value" },
  avgScoresQuestionsPeriod: {
    id: "avg_scores_qs_period",
    defaultMessage: "Average Scores Across Questions and Response Period"
  },
  timeWindow: { id: "time_window", defaultMessage: "Time Window" },
  completed: { id: "completed", defaultMessage: "Completed" },
  inComplete: { id: "in_complete", defaultMessage: "InComplete" },
  overallResponseRate: {
    id: "overall_response_rate",
    defaultMessage: "Overall response rate"
  },
  availableOptions: {
    id: "available_options",
    defaultMessage: "Available Options"
  },
  selectSurveyToView: {
    id: "select_survey_to_view",
    defaultMessage: "Please select a Survey to view the data"
  },
  survey: { id: "survey", defaultMessage: "Survey" },
  surveyQuestions: {
    id: "survey_questions",
    defaultMessage: "Survey Questions"
  },
  tasksByAssignee: {
    id: "tasks_by_assignee",
    defaultMessage: "Tasks By Assignee"
  },
  tasksByPriority: {
    id: "tasks_by_priority",
    defaultMessage: "Tasks By Priority"
  },
  tasksByStatus: {
    id: "tasks_by_status",
    defaultMessage: "Tasks By Status"
  },
  date: { id: "date", defaultMessage: "Date" },
  taskTemplate: { id: "task_template", defaultMessage: "Task Template" },
  taskAssignee: { id: "task_assignee", defaultMessage: "Task Assignee" },
  taskCreatedBy: {
    id: "task_created_by",
    defaultMessage: "Task Created By"
  },
  tasks_count: { id: "tasks_count", defaultMessage: "{count} Tasks" },
  tasks: { id: "tasks", defaultMessage: "Tasks" },
  my_tasks: { id: "my_tasks", defaultMessage: "My Tasks" },
  team_tasks: { id: "team_tasks", defaultMessage: "Team Tasks" },
  tasks_by_me: {
    id: "tasks_by_me",
    defaultMessage: "Tasks Created By Me"
  },
  tasks_completed_by_me: {
    id: "tasks_completed_by_me",
    defaultMessage: "Tasks Completed By Me"
  },
  all_completed_tasks: {
    id: "all_completed_tasks",
    defaultMessage: "All Completed Tasks"
  },
  appointments: {
    id: "appointments",
    defaultMessage: "Appointments"
  },
  template_messages: {
    id: "template_messages",
    defaultMessage: "Template Messages"
  },
  dashboard: {
    id: "dashboard",
    defaultMessage: "Dashboard"
  },
  filter: { id: "filter", defaultMessage: "Filter" },
  close: { id: "close", defaultMessage: "Close" },
  surveys: { id: "surveys", defaultMessage: "Surveys" },
  staff: { id: "staff", defaultMessage: "Staff" },
  patientReport: {
    id: "patient_report",
    defaultMessage: "Patient Report"
  },
  setRelation: {
    id: "please_set_relation",
    defaultMessage: "Please set relation"
  },
  remove: { id: "remove", defaultMessage: "Remove" },
  noMembersYet: { id: "no_members_yet", defaultMessage: "No members yet" },
  addMember: { id: "add_member", defaultMessage: "Add Member" },
  removeMember: { id: "remove_member", defaultMessage: "Remove Member" },
  confirm: { id: "confirm", defaultMessage: "Confirm" },
  myNotes: { id: "my_notes", defaultMessage: "Notes to Myself" },
  addNotes: { id: "add_notes", defaultMessage: "Add Notes" },
  sendNewStatus: {
    id: "send_new_status",
    defaultMessage: "Send New Status"
  },
  noNotesYet: {
    id: "no_notes_yet",
    defaultMessage: "You don't have any notes yet."
  },
  noStaffNotes: {
    id: "no_staff_notes",
    defaultMessage: "No staff notes yet for this patient."
  },
  no_patient_interactions: {
    id: "no_patient_interactions",
    defaultMessage: "There are no patient interactions."
  },
  deleteStaffNote: {
    id: "delete_staff_note",
    defaultMessage: "Delete Staff Note"
  },
  deleteStatusMessage: {
    id: "delete_status_message",
    defaultMessage: "Delete Status Message"
  },
  noStatuses: {
    id: "no_statuses",
    defaultMessage: "No statuses yet for this patient"
  },
  today: { id: "today", defaultMessage: "Today" },
  edit: { id: "edit", defaultMessage: "Edit" },
  carePlanDetails: {
    id: "care_plan_details",
    defaultMessage: "Care Plan Details"
  },
  consentDocumentDetails: {
    id: "consent_document_details",
    defaultMessage: "Consent Document Details"
  },
  status: { id: "status", defaultMessage: "Status" },
  device: { id: "device", defaultMessage: "DEVICE" },
  deviceDetails: {
    id: "device_details",
    defaultMessage: "Device Details"
  },
  position: { id: "position", defaultMessage: "POSITION (Lat/Lng)" },
  surveyAnswers: {
    id: "survey_answers",
    defaultMessage: "Survey Answers"
  },
  taskCreatedAvailable: {
    id: "task_created_available",
    defaultMessage:
      "A Task has been created and its available in the tasks page."
  },
  typeOfEntry: { id: "type_of_entry", defaultMessage: "Type Of Entry" },
  chooseUpdateType: {
    id: "choose_update_type",
    defaultMessage: "Please choose update type"
  },
  videoThumbnails: {
    id: "video_thumbnails",
    defaultMessage: "Video Thumbnails"
  },
  setThumbnails: {
    id: "set_thumbnails",
    defaultMessage: "Please Set Thumbnails"
  },
  setThumbnailsE: {
    id: "set_thumbnails_e",
    defaultMessage: "Please set thumbnails for all video files"
  },
  availableTemplates: {
    id: "available_templates",
    defaultMessage: "Available Templates"
  },
  chooseTemplate: {
    id: "choose_template",
    defaultMessage: "Please Choose Template"
  },
  chooseSurvey: { id: "choose_survey", defaultMessage: "Choose a survey" },
  pleaseChooseSurvey: {
    id: "please_choose_survey",
    defaultMessage: "Please Choose Survey"
  },
  library: { id: "library", defaultMessage: "Library" },
  pleaseSetLibrary: {
    id: "please_set_liLbrary",
    defaultMessage: "Please Set Library"
  },
  consentDocument: {
    id: "consent_document",
    defaultMessage: "Consent Document"
  },
  setConsentDocument: {
    id: "set_consent_document",
    defaultMessage: "Please Set Consent Document"
  },
  setTaskTemplate: {
    id: "set_task_template",
    defaultMessage: "Please Set Task Template"
  },
  text: { id: "text", defaultMessage: "Text" },
  setTextContent: {
    id: "please_set_text_content",
    defaultMessage: "Please Set Text Content"
  },
  optionalTitle: {
    id: "optional_title",
    defaultMessage: "Title (Optional)"
  },
  media: { id: "media", defaultMessage: "Media" },
  artifactType: { id: "artifact_type", defaultMessage: "Artifact Type" },
  sendUpdate: { id: "send_update", defaultMessage: "Send Update" },
  selectCD: { id: "select_cd", defaultMessage: "Select Consent Document" },
  selectTaskTemplate: {
    id: "select_task_template",
    defaultMessage: "Select Task Template"
  },
  newTask: { id: "new_task", defaultMessage: "New Task" },
  task: { id: "task", defaultMessage: "Task" },
  back: { id: "back", defaultMessage: "Back" },
  create: { id: "create", defaultMessage: "Create" },
  choosePatient: {
    id: "choose_patient",
    defaultMessage: "Choose Patient"
  },
  relatedTo: { id: "related_to", defaultMessage: "Related to" },
  setPatient: { id: "set_patient", defaultMessage: "Please set Patient" },
  chooseTaskType: {
    id: "choose_task_type",
    defaultMessage: "Choose Task Type"
  },
  adHocTask: { id: "ad_hoc_task", defaultMessage: "Ad Hoc Task" },
  next: { id: "next", defaultMessage: "Next" },
  assignee: { id: "assignee", defaultMessage: "Assignee" },
  pending: { id: "pending", defaultMessage: "Pending" },
  dueOn: { id: "due_on", defaultMessage: "Due On" },
  createdBy: { id: "created_by", defaultMessage: "Created By" },
  reassign: { id: "reassign", defaultMessage: "Reassign" },
  taskFor: { id: "task_for", defaultMessage: "Task For" },
  accept: { id: "accept", defaultMessage: "Accept" },
  assignToMe: { id: "assign_to_me", defaultMessage: "Assign To Me" },
  acceptNComplete: {
    id: "accept_complete",
    defaultMessage: "Accept & Complete"
  },
  markComplete: { id: "mark_complete", defaultMessage: "Mark Complete" },
  taskCompleteReopen: {
    id: "task_complete_reopen",
    defaultMessage: "Task Complete. Reopen?"
  },
  requester: { id: "requester", defaultMessage: "Requester" },
  taskNotes: { id: "task_notes", defaultMessage: "Task Notes" },
  attachments: { id: "attachments", defaultMessage: "Attachments" },
  taskDetails: { id: "task_details", defaultMessage: "Task Details" },
  taskAuditDetails: {
    id: "task_audit_details",
    defaultMessage: "Task Audit Details"
  },
  setTaskTitle: {
    id: "please_set_task_title",
    defaultMessage: "Please Set Task Title"
  },
  setTaskDescription: {
    id: "please_set_task_description",
    defaultMessage: "Please Set Task Description"
  },
  existingAttachments: {
    id: "existing_attachments",
    defaultMessage: "Existing Attachments"
  },
  attachmentsImages: {
    id: "attachments_images",
    defaultMessage: "Attachments (Images)"
  },
  pleaseSetAssignee: {
    id: "please_set_assignee",
    defaultMessage: "Please Set Assignee"
  },
  dueDate: { id: "due_date", defaultMessage: "Due Date" },
  taskPriority: { id: "task_priority", defaultMessage: "Task Priority" },
  asap: { id: "asap", defaultMessage: "ASAP" },
  urgent: { id: "urgent", defaultMessage: "Urgent" },
  normal: { id: "normal", defaultMessage: "Normal" },
  low: { id: "low", defaultMessage: "Low" },
  stat: { id: "stat", defaultMessage: "Stat" },
  noTasksAvailable: {
    id: "no_tasks_available",
    defaultMessage: "No Tasks Available."
  },
  noTasksFound: {
    id: "no_tasks_found",
    defaultMessage: "No tasks have been found for the given criteria"
  },
  priority: { id: "priority", defaultMessage: "Priority" },
  pLastName: { id: "p_last_name", defaultMessage: "Patient Last Name" },
  pFirstName: { id: "p_first_name", defaultMessage: "Patient First Name" },
  aLastName: { id: "alast_name", defaultMessage: "Assignee Last Name" },
  aFirstName: { id: "afirst_name", defaultMessage: "Assignee First Name" },
  aCareTeam: { id: "acare_team", defaultMessage: "Assignee Care Team" },
  taskTitle: { id: "task_title", defaultMessage: "Task Title" },
  asc: { id: "asc", defaultMessage: "Asc" },
  desc: { id: "desc", defaultMessage: "Desc" },
  allDue: { id: "all_due", defaultMessage: "All Due" },
  dueToday: { id: "due_today", defaultMessage: "Due Today" },
  overdue: { id: "overdue", defaultMessage: "Overdue" },
  upcoming: { id: "Upcoming", defaultMessage: "Upcoming" },
  all: { id: "all", defaultMessage: "All" },
  gotPatientConsent: {
    id: "got_patient_consent",
    defaultMessage:
      "Have you got the patient's consent to add this family member"
  },
  relationToPatient: {
    id: "relation_to_patient",
    defaultMessage: "Relation to the Patient"
  },
  checkIfConsentFromPatient: {
    id: "check_if_consent_from_patient",
    defaultMessage: "Please check this if you have got consent from the patient"
  },
  toDeleteStaffNote: {
    id: "to_delete_staff_note",
    defaultMessage:
      "Are you sure you want to delete the staff note? It will be permanently deleted from the system and can not be reverted."
  },
  toDeleteStatus: {
    id: "to_delete_status",
    defaultMessage:
      "Are you sure you want to delete the status message? It will be permanently deleted from the system and can not be reverted."
  },
  noTaskAuditsAvailable: {
    id: "no_task_audits_available",
    defaultMessage: "No audits available for this task"
  },
  sureToRemove: {
    id: "sure_to_remove",
    defaultMessage: "Are you sure you want to remove"
  },
  admin: {
    id: "admin",
    defaultMessage: "Admin"
  },
  noMembers: {
    id: "no_members",
    defaultMessage: "No members yet"
  },
  newStaffNote: {
    id: "new_staff_note",
    defaultMessage: "New Staff Note"
  },
  newNote: {
    id: "new_note",
    defaultMessage: "New Note"
  },
  newStatusMsg: {
    id: "new_status_msg",
    defaultMessage: "New status message"
  },

  confirmStaffNote: {
    id: "confirm_staff_note",
    defaultMessage: "Confirm adding Staff Note"
  },
  confirmNote: {
    id: "confirm_note",
    defaultMessage: "Confirm adding Note"
  },
  confirmStatus: {
    id: "confirm_status",
    defaultMessage: "Confirm sending Status"
  },
  confirmUpdateStaffNoteQ: {
    id: "confirm_staff_note_q",
    defaultMessage: "Are you sure you want to update the Staff Note"
  },
  confirmUpdateNoteQ: {
    id: "confirm_note_q",
    defaultMessage: "Are you sure you want to update the Note"
  },
  confirmUpdateStatusQ: {
    id: "confirm_status_q",
    defaultMessage: "Are you sure you want to update the status message"
  },
  confirmUpdateStaffNote: {
    id: "confirm_upd_staff_note",
    defaultMessage: "Confirm updating Staff Note"
  },
  confirmUpdateStatus: {
    id: "confirm_upd_status",
    defaultMessage: "Confirm updating Status"
  },
  confirmUpdateNote: {
    id: "confirm_upd_note",
    defaultMessage: "Confirm updating Note"
  },
  by: {
    id: "by",
    defaultMessage: "by"
  },
  selectLibrary: {
    id: "select_library",
    defaultMessage: "Select Library"
  },
  selectConsentDocument: {
    id: "select_consent_document",
    defaultMessage: "Select Consent Document"
  },
  lastUpdatedOn: {
    id: "last_updated_on",
    defaultMessage: "Last Updated On"
  },
  assignedTo: {
    id: "assigned_to",
    defaultMessage: "Assigned to"
  },
  none: {
    id: "none",
    defaultMessage: "NONE"
  },
  reopen: {
    id: "reopen",
    defaultMessage: "Reopen"
  },
  reopenTask: {
    id: "reopenTask",
    defaultMessage: "Reopen Task"
  },
  sureToReopen: {
    id: "sure_to_reopen",
    defaultMessage: "Are you sure you want to reopen the task?"
  },
  reassignTask: {
    id: "reassign_task",
    defaultMessage: "Reassign Task"
  },
  editTask: {
    id: "edit_task",
    defaultMessage: "Edit Task"
  },
  taskAuditLogs: {
    id: "task_audit_logs",
    defaultMessage: "Task Audit Logs"
  },
  taskData: {
    id: "task_data",
    defaultMessage: "Task Data"
  },

  taskAccepted: {
    id: "task_accepted",
    defaultMessage: "Task has been accepted."
  },
  taskAssigned: {
    id: "task_assigned",
    defaultMessage: "Task has been assigned to you."
  },
  taskReassigned: {
    id: "task_reassigned",
    defaultMessage: "Task has been reassigned."
  },
  taskMarkedComplete: {
    id: "task_marked_complete",
    defaultMessage: "Task has been marked complete."
  },
  taskReopened: {
    id: "task_reopened",
    defaultMessage: "Task has been reopened."
  },
  taskUpdated: {
    id: "task_updated",
    defaultMessage: "Task details have been updated."
  },
  patientProfileDeactivated: {
    id: "patient_profile_deactivated",
    defaultMessage: "Patient profile has been de-activated."
  },
  patientDeactivationProblems: {
    id: "patient_deactivation_problems",
    defaultMessage:
      "There were problems in de-activating the patient profile. Please try again later."
  },
  patientRestored: {
    id: "patient_restored",
    defaultMessage: "Patient has been restored."
  },
  natLoadPatient: {
    id: "nat_load_patient",
    defaultMessage: "Not able to load patient details. Please try again."
  },
  inviteResent: {
    id: "invite_resent",
    defaultMessage: "Invite has been resent."
  },
  credentialsReset: {
    id: "credentials_reset",
    defaultMessage: "Patient credentials have been reset."
  },
  removedFromList: {
    id: "removed_from_list",
    defaultMessage: "Patient has been removed from your list."
  },
  addedToList: {
    id: "added_to_list",
    defaultMessage: "Patient has been added to your list"
  },
  taskCreated: {
    id: "task_created",
    defaultMessage: "Task has been created"
  },
  memberRemoved: {
    id: "member_removed",
    defaultMessage: "Member has been removed"
  },
  memberAdded: {
    id: "member_added",
    defaultMessage: "Member has been added"
  },
  staffNotesUpdated: {
    id: "staff_notes_updated",
    defaultMessage: "Staff Note has been updated."
  },
  noteUpdated: {
    id: "note_updated",
    defaultMessage: "Note has been updated."
  },
  statusMessageUpdated: {
    id: "status_message_updated",
    defaultMessage: "Status Message has been updated."
  },
  staffNotedDeleted: {
    id: "staff_noted_deleted",
    defaultMessage: "Staff Note has been deleted."
  },
  statusMessageDeleted: {
    id: "status_message_deleted",
    defaultMessage: "Status Message has been deleted."
  },
  profileUpdated: {
    id: "profileUpdated",
    defaultMessage: "Profile data has been updated."
  },
  document: {
    id: "document",
    defaultMessage: "Document"
  },
  documentAdded: {
    id: "document_added",
    defaultMessage: "Document has been added."
  },
  documentDeleted: {
    id: "document_deleted",
    defaultMessage: "Document has been deleted."
  },
  fileUploadError: {
    defaultMessage:
      "There was a problem with file upload. Request might have timed out due to file size and slow network.",
    id: "file_upload_error"
  },
  patientAdded: {
    id: "patient_added",
    defaultMessage: "Patient {name} has been added."
  },
  patientAddError: {
    id: "patient_add_error",
    defaultMessage: "Error while adding patient {name}. Please try again later"
  },
  staffNoteAdded: {
    id: "staff_note_added",
    defaultMessage: "Staff Note has been added."
  },
  noteAdded: {
    id: "note_added",
    defaultMessage: "Your note has been added."
  },
  statusAdded: {
    id: "status_added",
    defaultMessage: "Status message has been sent."
  },
  addMemberError: {
    id: "add_member_error",
    defaultMessage: "Could not add member. Please try again."
  },
  editStatusMessage: {
    id: "edit_status_message",
    defaultMessage: "Edit Status Message"
  },
  editStaffNote: {
    id: "edit_staff_note",
    defaultMessage: "Edit Staff Note"
  },
  editNote: {
    id: "edit_note",
    defaultMessage: "Edit Note"
  },
  createAppointment: {
    id: "create_appointment",
    defaultMessage: "Create Appointment"
  },
  ch_appointment_template: {
    id: "ch_appointment_template",
    defaultMessage: "Choose Appointment Template"
  },
  ch_appt_schedule: {
    id: "ch_appt_schedule",
    defaultMessage: "Choose Appointment Schedule"
  },
  appointment_slot: {
    id: "appointment_slot",
    defaultMessage: "Choose Appointment Slot"
  },
  appointment_details: {
    id: "appointment_details",
    defaultMessage: "Appointment Details"
  },

  patient_name: {
    id: "patient_name",
    defaultMessage: "Patient Name"
  },
  appointment_type: {
    id: "appointment_type",
    defaultMessage: "Appointment Type"
  },
  appointment_time: {
    id: "appointment_time",
    defaultMessage: "Appointment Time"
  },
  set_appointment_time: {
    id: "set_appointment_time",
    defaultMessage: "Please set valid Appointment date and time"
  },
  participants: {
    id: "participants",
    defaultMessage: "Participants"
  },
  appointment_desc: {
    id: "appointment_desc",
    defaultMessage: "Appointment Description"
  },
  comments: {
    id: "comments",
    defaultMessage: "Comments"
  },
  patient_instructions: {
    id: "patient_instructions",
    defaultMessage: "Patient Instructions"
  },

  medium: {
    id: "medium",
    defaultMessage: "Medium"
  },
  high: {
    id: "high",
    defaultMessage: "High"
  },
  appt_created: {
    id: "appt_created",
    defaultMessage: "Appointment has been created."
  },
  slot_not_free: {
    id: "slot_not_free",
    defaultMessage: "Please select a free slot."
  },
  changePatient: {
    id: "change_patient",
    defaultMessage: "Change Patient"
  },
  selectExistingPatient: {
    id: "select_existing_patient",
    defaultMessage: "Select Existing Patient"
  },
  create_without_schedule: {
    id: "create_without_schedule",
    defaultMessage: "Create Without Schedule"
  },
  view_schedules: {
    id: "view_schedules",
    defaultMessage: "View All Schedules"
  },
  no_template: {
    id: "no_template",
    defaultMessage: "No Template"
  },
  sendMessages: { id: "send_messages", defaultMessage: "Send Messages" },
  proposed: { id: "proposed", defaultMessage: "Proposed" },
  booked: { id: "booked", defaultMessage: "Booked" },
  fulfilled: { id: "fulfilled", defaultMessage: "Fulfilled" },
  cancelled: { id: "cancelled", defaultMessage: "Cancelled" },
  no_show: { id: "no_show", defaultMessage: "No Show" },
  rebooked: { id: "rebooked", defaultMessage: "Re-Booked" },
  appointment_status: {
    id: "appointment_status",
    defaultMessage: "Appointment Status"
  },
  appointmentUpdated: {
    id: "appointment_updated",
    defaultMessage: "Appointment has been updated."
  },
  editAppointment: {
    id: "edit_appointment",
    defaultMessage: "Edit Appointment"
  },
  cancel_appointment: {
    id: "cancel_appointment",
    defaultMessage: "Cancel Appointment"
  },
  appointmentCancelled: {
    id: "appointment_cancelled",
    defaultMessage: "Appointment has been cancelled."
  },
  toCancelAppointment: {
    id: "to_cancel_appointment",
    defaultMessage: "Are you sure you want to cancel the appointment?"
  },
  fa_patient: {
    id: "fa_patient",
    defaultMessage: "Patient"
  },
  fa_change: {
    id: "fa_change",
    defaultMessage: "Change"
  },
  fa_name: {
    id: "fa_name",
    defaultMessage: "Name"
  },
  fa_set_name: {
    id: "fa_set_name",
    defaultMessage: "Please set name"
  },
  fa_title: {
    id: "fa_title",
    defaultMessage: "Title"
  },
  fa_comments: {
    id: "fa_comments",
    defaultMessage: "Comments"
  },
  fa_setTitle: {
    id: "fa_setTitle",
    defaultMessage: "Please set title"
  },
  fa_description: {
    id: "fa_Description",
    defaultMessage: "Description"
  },
  fa_set_description: {
    id: "fa_set_description",
    defaultMessage: "Please set description"
  },
  fa_status_message: {
    id: "fa_status_message",
    defaultMessage: "Status Message"
  },
  fa_type: {
    id: "fa_type",
    defaultMessage: "Type"
  },
  fa_set_type: {
    id: "fa_set_type",
    defaultMessage: "Please set type"
  },
  fa_set_status: {
    id: "fa_set_status",
    defaultMessage: "Please set status message"
  },
  fa_survey: {
    id: "fa_survey",
    defaultMessage: "Survey"
  },
  fa_set_survey: {
    id: "fa_set_survey",
    defaultMessage: "Please set survey"
  },
  fa_status_template: {
    id: "fa_status_template",
    defaultMessage: "Status Template"
  },
  fa_set_status_template: {
    id: "fa_set_status_template",
    defaultMessage: "Please set status template"
  },
  fa_library: {
    id: "fa_library",
    defaultMessage: "Library"
  },
  fa_set_library: {
    id: "fa_set_library",
    defaultMessage: "Please set library"
  },
  fa_care_plan: {
    id: "fa_care_plan",
    defaultMessage: "Care Plan"
  },
  fa_set_care_plan: {
    id: "fa_set_care_plan",
    defaultMessage: "Please set care plan"
  },
  fa_consent_document: {
    id: "fa_consent_document",
    defaultMessage: "Consent Document"
  },
  fa_set_consent_document: {
    id: "fa_set_consent_document",
    defaultMessage: "Please set consent document"
  },
  fa_task_template: {
    id: "fa_task_template",
    defaultMessage: "Task Template"
  },
  fa_set_task_template: {
    id: "fa_set_task_template",
    defaultMessage: "Please set task template"
  },
  fa_send_as_status: {
    id: "fa_send_as_status",
    defaultMessage: "Send As Status Message"
  },
  fa_select_survey: {
    id: "fa_select_survey",
    defaultMessage: "Select Survey"
  },
  fa_select_library: {
    id: "fa_select_library",
    defaultMessage: "Select Library"
  },
  fa_select_care_plan: {
    id: "fa_select_care_plan",
    defaultMessage: "Select Care Plan"
  },
  fa_select_consent: {
    id: "fa_select_consent",
    defaultMessage: "Select Consent Document"
  },
  fa_select_task: {
    id: "fa_select_task",
    defaultMessage: "Select Task"
  },
  fa_select_task_template: {
    id: "fa_select_task_template",
    defaultMessage: "Select Task Template"
  },
  fa_not_able_to_find: {
    id: "fa_not_able_to_find",
    defaultMessage: "Not able to find what you are looking for?"
  },
  fa_create_library: {
    id: "fa_create_library",
    defaultMessage: "Create a Library"
  },
  fa_instead: {
    id: "fa_instead",
    defaultMessage: "instead"
  },
  fa_create: {
    id: "fa_create",
    defaultMessage: "Create"
  },
  fa_appointment_schedule: {
    id: "fa_appointment_schedule",
    defaultMessage: "Appointment Schedule"
  },
  fa_new_appointment_schedule: {
    id: "fa_new_appointment_schedule",
    defaultMessage: "New Appointment Schedule"
  },
  fa_appointment_schedules: {
    id: "fa_appointment_schedules",
    defaultMessage: "Appointment Schedules"
  },
  fa_appointment_templates: {
    id: "fa_appointment_templates",
    defaultMessage: "Appointment Templates"
  },
  fa_patient_type: {
    id: "fa_patient_type",
    defaultMessage: "Patient Type"
  },
  fa_new_schedule: {
    id: "fa_new_schedule",
    defaultMessage: "New Schedule"
  },

  fa_set_participants: {
    id: "fa_set_participants",
    defaultMessage: "Please set participants"
  },

  fa_staff: {
    id: "fa_staff",
    defaultMessage: "Staff"
  },

  fa_remove: {
    id: "fa_remove",
    defaultMessage: "Remove"
  },
  fa_duration: {
    id: "fa_duration",
    defaultMessage: "Duration"
  },
  fa_set_duration: {
    id: "fa_set_duration",
    defaultMessage: "Please set duration"
  },
  fa_from: {
    id: "fa_from",
    defaultMessage: "From"
  },
  fa_to: {
    id: "fa_to",
    defaultMessage: "To"
  },
  fa_slot_interval: {
    id: "fa_slot_interval",
    defaultMessage: "Slot Interval"
  },
  fa_schedule: {
    id: "fa_schedule",
    defaultMessage: "Schedule"
  },
  fa_set_slot_interval: {
    id: "fa_set_slot_interval",
    defaultMessage: "Please set Slot Interval"
  },
  fa_appointment_slots: {
    id: "fa_appointment_slots",
    defaultMessage: "Appointment Slots"
  },
  fa_status: {
    id: "fa_status",
    defaultMessage: "Status"
  },
  fa_submit: {
    id: "fa_submit",
    defaultMessage: "Submit"
  },
  fa_schedule_created: {
    id: "fa_schedule_created",
    defaultMessage: "Appointment Schedule has been created."
  },
  fa_schedule_edited: {
    id: "fa_schedule_edited",
    defaultMessage: "Appointment Schedule has been edited."
  },
  fa_slot_updated: {
    id: "fa_slot_updated",
    defaultMessage: "Appointment slot has been updated."
  },
  fa_slot_deleted: {
    id: "fa_slot_deleted",
    defaultMessage: "Appointment slot has been deleted."
  },
  fa_delete_slot: {
    id: "fa_delete_slot",
    defaultMessage: "Delete Slot"
  },
  fa_delete_slot_message: {
    id: "fa_delete_slot_message",
    defaultMessage: "Are you sure you wan to delete the Slot?"
  },
  fa_create_slots: {
    id: "fa_create_slots",
    defaultMessage: "Create Slots"
  },
  fa_repeat: {
    id: "fa_repeat",
    defaultMessage: "Repeat"
  },
  fa_set_from_to_date: {
    id: "fa_set_from_to_date",
    defaultMessage: "Please set from and to date"
  },
  fa_set_day_repeat: {
    id: "fa_set_day_repeat",
    defaultMessage: "Please set the day"
  },
  fa_edit_slot: {
    id: "fa_edit_slot",
    defaultMessage: "Edit Slot"
  },
  mins: {
    id: "mins",
    defaultMessage: "Mins"
  },
  fa_slot_interval_15: {
    id: "fa_slot_interval_15",
    defaultMessage: "15 Mins"
  },
  fa_slot_interval_30: {
    id: "fa_slot_interval_30",
    defaultMessage: "30 Mins"
  },
  fa_slot_interval_45: {
    id: "fa_slot_interval_45",
    defaultMessage: "45 Mins"
  },
  fa_slot_interval_60: {
    id: "fa_slot_interval_60",
    defaultMessage: "1 Hour"
  },
  fa_slot_interval_90: {
    id: "fa_slot_interval_90",
    defaultMessage: "1 Hour and 30 Mins"
  },
  fa_slot_interval_120: {
    id: "fa_slot_interval_120",
    defaultMessage: "2 Hours"
  },
  fa_slot_repeat_all: {
    id: "fa_slot_repeat_all",
    defaultMessage: "All Days"
  },
  fa_slot_repeat_week: {
    id: "fa_slot_repeat_week",
    defaultMessage: "Every Monday, Tuesday, Wednesday, Thursday and Friday"
  },
  fa_slot_repeat_mwf: {
    id: "fa_slot_repeat_mwf",
    defaultMessage: "Every Monday, Wednesday and Friday"
  },
  fa_slot_repeat_tt: {
    id: "fa_slot_repeat_tt",
    defaultMessage: "Every Tuesday and Thursday"
  },
  fa_slot_repeat_mon: {
    id: "fa_slot_repeat_mon",
    defaultMessage: "Every Monday"
  },
  fa_slot_repeat_tue: {
    id: "fa_slot_repeat_tue",
    defaultMessage: "Every Tuesday"
  },
  fa_slot_repeat_wed: {
    id: "fa_slot_repeat_wed",
    defaultMessage: "Every Wednesday"
  },
  fa_slot_repeat_thu: {
    id: "fa_slot_repeat_thu",
    defaultMessage: "Every Thursday"
  },
  fa_slot_repeat_fri: {
    id: "fa_slot_repeat_fri",
    defaultMessage: "Every Friday"
  },
  fa_slot_repeat_sat: {
    id: "fa_slot_repeat_sat",
    defaultMessage: "Every Saturday"
  },
  fa_slot_repeat_sun: {
    id: "fa_slot_repeat_sun",
    defaultMessage: "Every Sunday"
  },
  fa_slot_status_busy: {
    id: "fa_slot_status_busy",
    defaultMessage: "Busy"
  },
  fa_slot_status_free: {
    id: "fa_slot_status_free",
    defaultMessage: "Free"
  },
  fa_slot_status_busyua: {
    id: "fa_slot_status_busyua",
    defaultMessage: "Busy Unavailable"
  },
  fa_slot_status_busytt: {
    id: "fa_slot_status_busytt",
    defaultMessage: "Busy Tentative"
  },
  fa_slot_status_error: {
    id: "fa_slot_status_error",
    defaultMessage: "Entered In Error"
  },
  fa_appt_template: {
    id: "fa_appt_template",
    defaultMessage: "Appointment Template"
  },
  fa_new_appt_template: {
    id: "fa_new_appt_template",
    defaultMessage: "New Appointment Template"
  },
  fa_template_created: {
    id: "fa_template_created",
    defaultMessage: "Appointment Template has been created."
  },
  fa_template_edited: {
    id: "fa_template_edited",
    defaultMessage: "Appointment Template has been edited."
  },

  fa_appointment_desc: {
    id: "fa_appointment_desc",
    defaultMessage: "Appointment Description"
  },

  fa_patient_instructions: {
    id: "fa_patient_instructions",
    defaultMessage: "Patient Instructions"
  },
  fa_low: {
    id: "fa_low",
    defaultMessage: "Low"
  },
  fa_medium: {
    id: "fa_medium",
    defaultMessage: "Medium"
  },
  fa_high: {
    id: "fa_high",
    defaultMessage: "High"
  },
  fa_priority: { id: "fa_priority", defaultMessage: "Priority" },
  fa_patient_types: {
    id: "fa_patient_types",
    defaultMessage: "Patient Types"
  },
  fa_appointment_type: {
    id: "fa_appointment_type",
    defaultMessage: "Appointment Type"
  },
  fa_visibility: {
    id: "fa_visibility",
    defaultMessage: "Visibility"
  },
  fa_confirmation_email_sub: {
    id: "fa_confirmation_email_sub",
    defaultMessage: "Confirmation Email Subject"
  },
  fa_confirmation_email_content: {
    id: "fa_confirmation_email_content",
    defaultMessage: "Confirmation Email Content"
  },
  fa_confirmation_sms_content: {
    id: "fa_confirmation_sms_content",
    defaultMessage: "Confirmation SMS Content"
  },

  fa_confirmation_messages: {
    id: "fa_confirmation_messages",
    defaultMessage: " Confirmation Messages"
  },
  fa_reminders_config: {
    id: "fa_reminders_config",
    defaultMessage: "Reminders Configuration"
  },

  fa_delete: {
    id: "fa_delete",
    defaultMessage: "Delete"
  },
  fa_slots_created: {
    id: "fa_slots_created",
    defaultMessage: "Slots have been created."
  },
  fa_edit: {
    id: "fa_edit",
    defaultMessage: "Edit"
  },
  fa_template_deleted: {
    id: "fa_template_deleted",
    defaultMessage: "Template has been deleted."
  },
  fa_delete_template_error: {
    id: "fa_delete_template_error",
    defaultMessage: "Error while deleting the template. Please try again later."
  },

  fa_delete_template: {
    id: "fa_delete_template",
    defaultMessage: "Delete Template"
  },
  to_delete_template: {
    id: "to_delete_template",
    defaultMessage:
      "Are you sure you want to delete the appointment template? It will be permanently deleted from the system and can not be reverted."
  },
  fa_delete_schedule: {
    id: "fa_delete_schedule",
    defaultMessage: "Delete Schedule"
  },
  fa_delete_schedule_message: {
    id: "fa_delete_schedule_message",
    defaultMessage: "Are you sure you wan to delete the Schedule?"
  },
  fa_schedule_deleted: {
    id: "fa_schedule_deleted",
    defaultMessage: "Appointment Schedule has been deleted."
  },
  active: {
    id: "active",
    defaultMessage: "Active"
  },
  active_uc: {
    id: "active_uc",
    defaultMessage: "ACTIVE"
  },
  inactive: {
    id: "inactive",
    defaultMessage: "Inactive"
  },
  set_appointment_description: {
    id: "set_appointment_description",
    defaultMessage: "Please set Appointment description"
  },
  set_appointment_type: {
    id: "set_appointment_type",
    defaultMessage: "Please set Appointment type"
  },
  invitation_expired_uc: {
    id: "invitation_expired_uc",
    defaultMessage: "INVITATION EXPIRED"
  },
  active__internal_uc: {
    id: "active__internal_uc",
    defaultMessage: "ACTIVE INTERNAL"
  },
  pending_invitation_uc: {
    id: "pending_invitation_uc",
    defaultMessage: "PENDING INVITATION"
  },
  invite_sent_date: {
    id: "invite_sent_date",
    defaultMessage: "Sent on {date}"
  },
  mrn_w_value: {
    id: "mrn_w_value",
    defaultMessage: "MRN: {mrnNumber}"
  },
  attention_required_uc: {
    id: "attention_required_uc",
    defaultMessage: "ATTENTION REQUIRED"
  },
  monitor_uc: {
    id: "monitor_uc",
    defaultMessage: "MONITOR"
  },
  on_track_uc: {
    id: "on_track_uc",
    defaultMessage: "ON TRACK"
  }
});

export default Messages;
