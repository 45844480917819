import React, { PureComponent } from "react";
import { validateEmail, validateText } from "../../utils/utils";
import StepInvite from "./StepInvite";
import StepSetCredentials from "./StepSetCredentials";
import { doE164, validatePhoneNumber } from "../../utils/phone";
import userService from "../../services/userService";
import OnboardingStepsIndicator from "./OnboardingStepsIndicator";
import { Card, CardBody, CardGroup, Col, Container, Row } from "reactstrap";
import FacilityLogo from "./FacilityLogo";
import CredentialsPresentMessage from "./CredentialsPresentMessage";

class Invite extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      inputType: this.props.linkType
        ? this.props.linkType.toUpperCase()
        : "EMAIL",
      countryCode: this.props.countryCode || "+353",
      confirmationCode: this.props.confirmationCode,
      termsAccepted: this.props.termsAccepted,
      spaceTermsAccepted: this.props.spaceTermsAccepted,
      privacyAccepted: this.props.privacyAccepted,
      confirmationDone: this.props.confirmationDone,
      username: "",
      password: "",
      repeatPassword: "",
      errorEmail: false,
      errorInviteCode: false,
      errorConfirmationCode: false,
      errorUsername: false,
      errorPassword: false,
      errorRepeatPassword: false,
      challengeFields: []
    };
  }

  componentDidMount() {
    let { inviteCode } = this.props;
    if (inviteCode) {
      this.setState({ challengeLoading: true });
      userService
        .fetchChallengeFields(inviteCode)
        .then(facilityInfo => {
          if (facilityInfo) {
            this.setState({
              challengeLoading: false,
              facilityInfo,
              challengeFields: facilityInfo.challengeFields || []
            });
          } else {
            this.setState({ challengeLoading: false });
          }
        })
        .catch(e => {
          this.setState({ challengeLoading: false });
        });
    }
  }

  onSubmit = e => {
    e.preventDefault();
    const { step } = this.props;

    this.setState({
      errorInviteCode: false,
      errorPhone: false,
      errorEmail: false
    });

    switch (step) {
      case "invite":
        this.setState({
          errorPhone: false,
          errorEmail: false,
          errorTerms: false
        });

        const { onAcceptInvite, inviteCode, challenge } = this.props;

        if (challenge) {
          const { challengeFields, termsAccepted } = this.state;
          if (!termsAccepted) {
            this.setState({ errorTerms: true });
            return;
          }
          onAcceptInvite(inviteCode, null, null, challengeFields);
        } else {
          const {
            inputType,
            email,
            phone,
            countryCode,
            termsAccepted
          } = this.state;

          let validEmail = false,
            validPhone = false;
          if (inputType === "PHONE") {
            validPhone =
              phone && validatePhoneNumber(countryCode + " " + phone);
            if (!validPhone) {
              this.setState({
                errorPhone: !validPhone
              });
              return;
            }
            onAcceptInvite(inviteCode, null, doE164(countryCode, phone));
          } else {
            validEmail = validateEmail(email);
            if (!validEmail) {
              this.setState({
                errorEmail: !validEmail
              });
              return;
            }

            if (!termsAccepted) {
              this.setState({ errorTerms: true });
              return;
            }
            onAcceptInvite(inviteCode, email, null);
          }
        }
        break;

      case "setCredentials":
        const { onSetCredentials } = this.props;
        const { username, password, repeatPassword } = this.state;

        const validUsername = validateText(username, 4);
        const validPassword = validateText(password, 6);
        const validRepeatPassword = repeatPassword === password;
        this.setState({
          errorUsername: !validUsername,
          errorPassword: !validPassword,
          errorRepeatPassword: !validRepeatPassword
        });
        if (validUsername && validPassword && validRepeatPassword) {
          onSetCredentials(username, password);
        }
        break;
    }
  };

  onUpdate = (fieldName, value) => {
    let obj = {};
    obj[fieldName] = value;
    this.setState(obj);
  };

  render() {
    const { step, loading, error, errorMsg } = this.props;
    const {
      inputType,
      email,
      countryCode,
      phone,
      username,
      password,
      repeatPassword,
      errorEmail,
      errorPhone,
      errorUsername,
      errorPassword,
      errorRepeatPassword,
      challengeLoading,
      facilityInfo,
      challengeFields = []
    } = this.state;

    return (
      <div className="app flex-column justify-content-center align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md="8">
              <div className="ob-logos my-4">
                <div className="p-3">
                  <img
                    alt="myPatientSpace"
                    src="/img/mps-logo.png"
                    height={32}
                  />
                </div>

                {facilityInfo && facilityInfo.appTheme && (
                  <div className="p-3 ob-fac-logo">
                    <FacilityLogo
                      appTheme={facilityInfo.appTheme}
                      height="60px"
                    />
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row className="justify-content-center">
            <Col md="8">
              <CardGroup>
                <Card className="p-md-4 p-sm-3">
                  <CardBody>
                    <div className="mb-3 text-center">
                      <h5>
                        {"myPatientSpace Account Setup" +
                          (facilityInfo && facilityInfo.facilityName
                            ? " for " + facilityInfo.facilityName
                            : "")}
                      </h5>
                    </div>
                    {step !== "credentialsPresent" && (
                      <div className="mb-3">
                        <OnboardingStepsIndicator step={step} />
                      </div>
                    )}

                    <Row>
                      <Col md={{ size: 8, offset: 2 }}>
                        <div className="mt-3">
                          {step === "invite" && (
                            <StepInvite
                              inputType={inputType}
                              email={email}
                              countryCode={countryCode}
                              phone={phone}
                              error={error}
                              errorMsg={errorMsg}
                              loading={loading}
                              errorEmail={errorEmail}
                              errorPhone={errorPhone}
                              onSubmit={this.onSubmit}
                              onUpdateData={this.onUpdate}
                              onLogout={this.props.onLogout}
                              linkType={this.props.linkType}
                              challenge={this.props.challenge}
                              challengeFields={challengeFields}
                              challengeLoading={challengeLoading}
                              facilityInfo={facilityInfo}
                              termsAccepted={this.state.termsAccepted}
                              errorTerms={this.state.errorTerms}
                            />
                          )}

                          {step === "setCredentials" && (
                            <StepSetCredentials
                              error={error}
                              email={this.props.propsEmail}
                              errorUsername={errorUsername}
                              errorPassword={errorPassword}
                              errorRepeatPassword={errorRepeatPassword}
                              onSubmit={this.onSubmit}
                              username={username}
                              password={password}
                              repeatPassword={repeatPassword}
                              onUpdateData={this.onUpdate}
                              loading={loading}
                            />
                          )}

                          {step === "credentialsPresent" && (
                            <CredentialsPresentMessage
                              history={this.props.history}
                              email={this.props.propsEmail}
                              phone={this.props.propsPhone}
                              facilityName={
                                this.props.spaceDetails
                                  ? this.props.spaceDetails.facilityName
                                  : ""
                              }
                            />
                          )}
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Invite;
//
// let os = getMobileOperatingSystem();
// if (os === "Android") {
//   window.location = "https://mypatientspace.com/invite/" + inviteCode;
//   return null;
// } else if (os === "iOS") {
//   window.location = "mypatientspace://invite?" + inviteCode;
//   return null;
// }
