import { API_ENDPOINTS, PAGE_SIZE, PATIENT_API_ENDPOINTS } from "../config";
import { http } from "../utils/http";
import { store } from "../State/store/configureStore";
import { ROLE_PATIENT } from "../security/roles";

class SurveysService {
  add(survey) {
    return http.request(API_ENDPOINTS.addSurvey(), "post", survey);
  }

  edit(surveyId, survey) {
    return http.request(API_ENDPOINTS.loadSurvey(surveyId), "patch", survey);
  }

  load(page, all, active, patientTypeId) {
    let pageSize = PAGE_SIZE;
    if (all) {
      pageSize = 1000;
    }

    let endpoint =
      API_ENDPOINTS.loadSurveys() + `?page=${page}&size=${pageSize}`;
    if (!active) {
      endpoint += `&allStatus=True`;
    }

    if (patientTypeId) {
      endpoint += `&pTypeId=${patientTypeId}`;
    }

    return http.request(endpoint).then(data => {
      if (data && data._embedded) {
        return {
          data: page <= data.page.totalPages ? data._embedded.surveys : [],
          loadMore: data.page.totalPages > page + 1
        };
      } else {
        return {
          data: [],
          loadMore: false
        };
      }
    });
  }

  loadSingle(surveyId) {
    return http.request(API_ENDPOINTS.loadSurvey(surveyId));
  }

  loadPatientSurveys(patientId) {
    return http
      .request(API_ENDPOINTS.loadPatientSurveys(patientId))
      .then(data => {
        return data && data._embedded ? data._embedded.surveyResponses : [];
      });
  }

  deleteSurvey(surveyId) {
    return http.request(API_ENDPOINTS.loadSurvey(surveyId), "delete");
  }

  submitAnswers(messageId, responseData, patientId) {
    let role = store.getState().user.role;
    let api = messageId
      ? API_ENDPOINTS.surveyResponse(patientId, messageId)
      : API_ENDPOINTS.directSurveyResponse(patientId);
    if (role === ROLE_PATIENT) {
      api = PATIENT_API_ENDPOINTS.surveyResponse(messageId);
    }
    return http.request(api, "post", responseData);
  }

  fetchDetailedReportUrl(patientId, endDate) {
    let api = API_ENDPOINTS.detailedAssessmentReport(patientId);
    if (endDate) {
      api += "?endDate=" + endDate;
    }
    return api;
    // return http.request(api);
  }

  fetchTherapyComplianceReportUrl(patientId, endDate) {
    let api = API_ENDPOINTS.therapyComplianceReport(patientId);
    if (endDate) {
      api += "?endDate=" + endDate;
    }
    return api;
    // return http.request(api);
  }

  fetchPatientReportData(patientId, filter = {}) {
    let { fromDate, toDate } = filter;
    let api = API_ENDPOINTS.patientCustomReport(patientId);
    if (fromDate) {
      api += `&fromDate=${new Date(fromDate).getTime()}`;
    }
    if (toDate) {
      api += `&toDate=${new Date(toDate).getTime()}`;
    }
    return http.request(api);
  }

  fetchAnonymousPatientReportData = (filter = {}) => {
    let { patientTypeId, fromDate, toDate } = filter;
    let api = API_ENDPOINTS.patientCustomReportAnonymous(patientTypeId);
    if (fromDate) {
      api += `&fromDate=${new Date(fromDate).getTime()}`;
    }
    if (toDate) {
      api += `&toDate=${new Date(toDate).getTime()}`;
    }

    return http.request(api);
  };

  loadPendingSurveysForPatient(patientId) {
    return http
      .request(API_ENDPOINTS.provisionedSurveys(patientId))
      .then(data => {
        return data && data._embedded ? data._embedded.provisionedSurveys : [];
      });
  }

  loadPatientSubmittedSurveys(patientId) {
    return http
      .request(API_ENDPOINTS.patientSubmittedSurveys(patientId))
      .then(data => {
        return data ? data.availableSurveys || {} : {};
      });
  }

  loadSubmittedSurveyResponsesBySurveyId(patientId, surveyId) {
    return http
      .request(API_ENDPOINTS.patientSurveysResponsesById(patientId, surveyId))
      .then(data => {
        return data && data._embedded ? data._embedded.surveyResponses : [];
      });
  }
}

const surveysService = new SurveysService();

export default surveysService;
