import React, { PureComponent } from "react";
import {
  Row,
  Col,
  Button,
  Input,
  InputGroup,
  FormGroup,
  ButtonGroup,
  InputGroupAddon,
  InputGroupText
} from "reactstrap";
import classnames from "classnames";
import { validateEmail } from "../../utils/utils";
import CountryCodes from "../../Shared/FHIR/CountryCodes";
import { doE164, validatePhoneNumber } from "../../utils/phone";
import RegionSelector from "../RegionSelector";
import Select from "react-select";

class ForgotPasswordForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      inputType: this.props.linkType === "email" ? "EMAIL" : "PHONE"
    };
  }

  onUpdateInputType = value => {
    this.setState({ inputType: value });
  };

  onSubmit = e => {
    e.preventDefault();
    const { inputType, email, phone, countryCode, uniqueId } = this.state;
    this.setState({
      errorPhone: false,
      errorEmail: false
    });
    let validEmail = false,
      validPhone = false;
    if (inputType === "PHONE") {
      validPhone = phone && validatePhoneNumber(countryCode + " " + phone);
      if (!validPhone) {
        this.setState({
          errorPhone: !validPhone
        });
        return;
      }
      this.props.sendRequest({ phoneNumber: doE164(countryCode, phone) });
    } else if (inputType === "EMAIL") {
      validEmail = validateEmail(email);
      if (!validEmail) {
        this.setState({
          errorEmail: !validEmail
        });
        return;
      }
      this.props.sendRequest({ email: email });
    } else if (inputType === "UNIQUE_ID") {
      this.props.sendRequest({ uniqueId });
    }
  };

  onUpdateEmail = e => {
    this.setState({ email: e.target.value });
  };

  onUpdateCountryCode = cntrObj => {
    if (cntrObj) {
      this.setState({ countryCode: cntrObj.value });
    } else {
      this.setState({ countryCode: null });
    }
  };

  onUpdatePhone = e => {
    this.setState({ phone: e.target.value });
  };

  onUpdateUniqueId = e => {
    this.setState({ uniqueId: e.target.value });
  };

  render() {
    const { loading } = this.props;
    const disabled = loading;

    let {
      inputType,
      email,
      countryCode,
      phone,
      errorEmail,
      errorPhone,
      uniqueId,
      errorUniqueId
    } = this.state;

    return (
      <form onSubmit={this.onSubmit} noValidate>
        <h4 className="mb-2">Forgot Password</h4>
        <p>
          Please enter your registered{" "}
          {this.props.linkType
            ? this.props.linkType === "email"
              ? "Email"
              : "Phone number"
            : "Phone number or Email"}
          . We will send you instructions to reset your password.
        </p>

        <InputGroup className="mb-3">
          <InputGroupAddon addonType="prepend">
            <InputGroupText>
              <i className="fa fa-globe" />
            </InputGroupText>
          </InputGroupAddon>
          <RegionSelector loading={loading} />
        </InputGroup>

        {!this.props.linkType && (
          <FormGroup className="d-flex align-items-center">
            <ButtonGroup>
              <Button
                onClick={e => {
                  e.preventDefault();
                  this.onUpdateInputType("PHONE");
                }}
                className={classnames(inputType === "PHONE" ? "active" : "")}
              >
                Phone
              </Button>
              <Button
                onClick={e => {
                  e.preventDefault();
                  this.onUpdateInputType("EMAIL");
                }}
                className={classnames(inputType === "EMAIL" ? "active" : "")}
              >
                Email
              </Button>
              <Button
                onClick={e => {
                  e.preventDefault();
                  this.onUpdateInputType("UNIQUE_ID");
                }}
                className={classnames(
                  inputType === "UNIQUE_ID" ? "active" : ""
                )}
              >
                ID
              </Button>
            </ButtonGroup>
          </FormGroup>
        )}

        {inputType === "EMAIL" && (
          <InputGroup className="mb-3">
            <InputGroupAddon addonType="prepend">
              <InputGroupText
                className={classnames(errorEmail ? "is-invalid" : "")}
              >
                <i className="fa fa-envelope-open" />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              className={classnames(errorEmail ? "is-invalid" : "")}
              value={email}
              onChange={this.onUpdateEmail}
              type="email"
              placeholder="E-mail"
              disabled={loading}
            />{" "}
            <div className="invalid-feedback">Please set valid email.</div>
          </InputGroup>
        )}

        {inputType === "PHONE" && (
          <InputGroup className="mb-3 d-flex">
            <InputGroupAddon addonType="prepend">
              <InputGroupText
                className={classnames(errorPhone ? "is-invalid" : "")}
              >
                <i className="fa fa-phone" />
              </InputGroupText>
            </InputGroupAddon>
            <Select
              value={countryCode}
              onChange={this.onUpdateCountryCode}
              placeholder="Country code"
              options={CountryCodes}
              labelKey="country"
              valueKey="value"
              disabled={loading}
              style={{
                border: errorPhone ? "1px solid #f86c6b" : "1px solid #e0e6e8",
                borderRadius: 0
              }}
            />
            <Input
              value={phone}
              onChange={this.onUpdatePhone}
              disabled={loading}
              className={classnames(errorPhone ? "is-invalid ml-1" : "ml-1")}
              placeholder="Phone Number"
              autoComplete="nope"
            />
            <div className="invalid-feedback">
              Please set valid phone with country code.
            </div>
          </InputGroup>
        )}

        {inputType === "UNIQUE_ID" && (
          <InputGroup className="mb-3">
            <Input
              className={classnames(errorUniqueId ? "is-invalid" : "")}
              value={uniqueId}
              onChange={this.onUpdateUniqueId}
              type="text"
              placeholder="ID"
              disabled={loading}
            />{" "}
            <div className="invalid-feedback">Please set valid ID.</div>
          </InputGroup>
        )}

        <Row className="justify-content-end">
          <Col xs="12" className="d-flex justify-content-end">
            <Button color="primary" className="px-4" disabled={disabled}>
              Submit
            </Button>
          </Col>
        </Row>
      </form>
    );
  }
}

export default ForgotPasswordForm;
