import { Button, Card, CardBody } from "reactstrap";
import React from "react";

class NewAppointmentConfirmation extends React.PureComponent {
  render() {
    let { cancel, doAction, dateTime } = this.props;

    return (
      <div>
        <div className="row">
          <div className="col-12">
            <Card>
              <CardBody>
                <div className="mb-2">{`Scheduling an Appointment for:`}</div>
                <div>{dateTime}</div>
              </CardBody>
            </Card>

            <div
              style={{
                marginTop: 20,
                flex: 1,
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center"
              }}
            >
              <Button onClick={cancel} className="btn btn-secondary mr-2">
                Cancel
              </Button>

              <Button color="primary" onClick={doAction}>
                Confirm
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default NewAppointmentConfirmation;
