import React, { PureComponent } from "react";
import Mandatory from "./Mandatory";
import ContactPoint from "../Elements/ContactPoint";
import AttributeInfo from "./AttributeInfo";

export default class PhoneAttribute extends PureComponent {
  componentDidMount() {
    const input = document.getElementById("confirm-phone");
    if (input) {
      input.onpaste = function(e) {
        e.preventDefault();
      };
    }
  }

  render() {
    let {
      placeholder,
      attribute,
      index,
      handleOnBlur,
      changeField
    } = this.props;
    let description = attribute.description || attribute.title;

    return (
      <div
        key={index}
        className="col-12 my-1 pb-2"
        style={{ backgroundColor: "#fff" }}
        ref={attrEl => {
          if (this.props.setAttributeRef) {
            this.props.setAttributeRef(attribute.name, attrEl);
          }
        }}
      >
        {!placeholder && (
          <label style={{ fontWeight: 500 }}>
            {description}
            {attribute.mandatory && <sup style={{ color: "red" }}>*</sup>}
          </label>
        )}
        <div className="d-flex flex-column">
          <ContactPoint
            hasError={!!attribute.error}
            placeholder={placeholder ? description : ""}
            dataField={attribute.dataField}
            attributeValue={attribute.value || {}}
            setData={value => {
              attribute.value = value;
              changeField(index, attribute);
            }}
            width={100}
            ccClearable={false}
            onBlur={() => {
              handleOnBlur(index, attribute);
            }}
            id={attribute.attributeId}
            borderRadius="4px"
          />
          {placeholder && <Mandatory mandatory={attribute.mandatory} />}
          {attribute.error && (
            <div className="invalid-feedback d-block">{attribute.error}</div>
          )}
          <AttributeInfo info={attribute.info} />
        </div>
      </div>
    );
  }
}
