import React, { Component } from "react";
import Video from "twilio-video";
import MPSBarLoader from "../MPSBarLoader";
import VideoRoom from "./VideoRoom";
import { Button } from "reactstrap";
import GoToHome from "./GoToHome";
import { secondsToHourMinSecs } from "../../utils/utils";

const CALL_ERROR =
  "Unable to connect to the video call. Please try again later or contact us if the issue persists.";

function getErrorMessage(error) {
  if (error.code === 53103) {
    return "Video call is not scheduled or ended already.";
  }

  return CALL_ERROR;
}

export default class VideoComponent extends Component {
  constructor(props) {
    super(props);
    this.activeRoom = null;
    this.previewTracks = null;
    this.state = {};
  }

  componentDidMount() {
    window.addEventListener("beforeunload", this.leaveRoomIfJoined);
    this.makeVideoCall();
  }

  makeVideoCall = () => {
    let { roomName, token, participants } = this.props;
    this.setState({ loading: true, participants });

    let connectOptions = {
      name: roomName,
      logLevel: "debug"
    };

    if (this.previewTracks) {
      connectOptions.tracks = this.previewTracks;
    }

    Video.connect(token, connectOptions).then(this.roomJoined, error => {
      this.setState({
        loading: false,
        error: getErrorMessage(error)
      });
    });
  };

  attachTracks = (tracks, container) => {
    tracks.forEach(track => {
      if (track.attach) {
        container.appendChild(track.attach());
      } else if (track.track) {
        container.appendChild(track.track.attach());
      }
    });
  };

  attachParticipantTracks = (participant, container, remote) => {
    let tracks = Array.from(participant.tracks.values());
    this.attachTracks(tracks, container);
    this.setVideoStyles(container);
  };

  setVideoStyles = container => {
    let video = container.querySelector("video");
    if (video) {
      video.style.height = "100%";
      video.style.width = "100%";
      video.style.objectFit = "contain";
      // video.style.border = "1px solid rgb(221, 223, 226)";
    }
  };

  detachTracks = tracks => {
    tracks.forEach(track => {
      if (track.detach) {
        track.detach().forEach(detachedElement => {
          detachedElement.remove();
        });
      } else if (track.track) {
        track.track.detach().forEach(detachedElement => {
          detachedElement.remove();
        });
      }
    });
  };

  detachParticipantTracks = participant => {
    let tracks = Array.from(participant.tracks.values());
    this.detachTracks(tracks);
  };

  roomJoined = room => {
    this.activeRoom = room;
    this.setState({ activeRoom: room, loading: false });
    window.room = room.name;

    let previewContainer = this.localMedia;
    if (!previewContainer.querySelector("video")) {
      this.setState({ localVideo: true });
      this.attachParticipantTracks(room.localParticipant, previewContainer);
    }

    room.participants.forEach(participant => {
      let previewContainer = this.remoteMedia;
      this.attachParticipantTracks(participant, previewContainer, true);
    });

    room.on("participantConnected", participant => {});

    room.on("trackSubscribed", (track, participant) => {
      let previewContainer = this.remoteMedia;
      this.setState({ remoteVideo: true });
      this.setVideoStyles(previewContainer);
      this.attachTracks([track], previewContainer);
    });

    room.on("trackUnsubscribed", (track, participant) => {
      this.setState({ remoteVideo: false });
      this.detachTracks([track]);
    });

    room.on("participantDisconnected", participant => {
      this.detachParticipantTracks(participant);
      this.leaveRoom();
    });

    room.on("disconnected", () => {
      if (this.previewTracks) {
        this.previewTracks.forEach(track => {
          track.stop();
        });
      }
      this.detachParticipantTracks(room.localParticipant);
      room.participants.forEach(this.detachParticipantTracks);
      this.leaveRoom();
    });
  };

  leaveRoomIfJoined = () => {
    if (this.activeRoom) {
      this.activeRoom.disconnect();
      this.activeRoom = null;
      this.setState({
        remoteVideo: false,
        activeRoom: null,
        callEnded: true
      });
    }
  };

  setCallDuration = callDuration => {
    if (!this.state.callEnded) {
      this.setState({ callDuration });
    }
  };

  componentWillUnmount() {
    this.leaveRoomIfJoined();
  }

  leaveRoom = () => {
    this.leaveRoomIfJoined();
  };

  setLocalMediaRef = ref => {
    this.localMedia = ref;
  };

  setRemoteMediaRef = ref => {
    this.remoteMedia = ref;
  };

  render() {
    let { loading, error, callEnded, activeRoom } = this.state;

    if (loading) {
      return (
        <div style={{ margin: "100px auto", width: 100 }}>
          <MPSBarLoader />
        </div>
      );
    }

    if (error) {
      return (
        <div className="d-flex justify-content-center align-items-center my-4">
          <p className="text-danger">{error}</p>
        </div>
      );
    }

    if (callEnded) {
      return (
        <div className="d-flex justify-content-center align-items-center my-4 flex-column">
          <p className="font-lg">Call has ended. You both joined. </p>
          <p className="font-lg">
            Total call duration: {secondsToHourMinSecs(this.state.callDuration)}
          </p>
          {this.props.existing && (
            <div className="my-3">
              <GoToHome />
            </div>
          )}
          {this.props.newRoom && (
            <div className="my-3">
              <Button
                className="btn btn-secondary"
                onClick={() => {
                  window.close();
                }}
              >
                Close
              </Button>
            </div>
          )}
        </div>
      );
    }

    if (activeRoom) {
      return (
        <div>
          {this.state.activeRoom && (
            <VideoRoom
              activeRoom={this.state.activeRoom}
              setRemoteMedia={this.setRemoteMediaRef}
              setLocalMedia={this.setLocalMediaRef}
              remoteVideo={this.state.remoteVideo}
              leaveRoom={this.leaveRoom}
              existing={this.props.existing}
              otherParticipantName={this.props.otherParticipantName}
              localVideo={this.state.localVideo}
              setCallDuration={this.setCallDuration}
              callEnded={callEnded}
            />
          )}
        </div>
      );
    }

    return null;
  }
}
