import React from "react";

export const PRIVACY_HTML =
  '  <p class="c28">\n' +
  '    <span class="c7">myPatientSpace</span\n' +
  '    ><span class="c7 c31">&#39;s Privacy </span\n' +
  '    ><span class="c7 c31">Policy</span>\n' +
  "  </p>\n" +
  '  <p class="c12"><span class="c24 c15">Last revision Dec 2019</span></p>\n' +
  '  <p class="c6"><span class="c4 c20 c15"></span></p>\n' +
  '  <p class="c12"><span class="c15 c20">Privacy Policy</span></p>\n' +
  '  <p class="c6"><span class="c4 c2 c19 c26"></span></p>\n' +
  '  <p class="c6"><span class="c4 c2 c19 c26"></span></p>\n' +
  '  <p class="c12">\n' +
  '    <span class="c2 c19">At </span><span class="c19 c24">myPatientSpace</span\n' +
  '    ><span class="c2 c19"\n' +
  "      >, we understand that the privacy and security of your information is\n" +
  "      important to you. To this end, we endeavour to safeguard the privacy of\n" +
  "      all information you entrust us with in order to protect and respect your\n" +
  "      privacy.</span\n" +
  '    ><span class="c4 c2 c8">&nbsp;</span>\n' +
  "  </p>\n" +
  '  <p class="c6"><span class="c0"></span></p>\n' +
  '  <p class="c12">\n' +
  '    <span class="c4 c2 c8"\n' +
  "      >myPatientSpace provides services to various types of healthcare providers\n" +
  "      and in turn these healthcare providers invite their staff and patients to\n" +
  "      use myPatientSpace during their episodes of care. &nbsp;Patients and staff\n" +
  "      may also invite extended care takers (e.g. family members). In these\n" +
  "      circumstances in respect of your personal data provided by your healthcare\n" +
  "      provider to the myPatientSpace application, the healthcare provider is the\n" +
  "      data controller, and we are the data processor.</span\n" +
  "    >\n" +
  "  </p>\n" +
  '  <p class="c6"><span class="c0"></span></p>\n' +
  '  <p class="c12">\n' +
  '    <span class="c2 c15"\n' +
  "      >Our privacy policy describes which personal data we collect and how we\n" +
  "      use and share this data. We recommend that you read our privacy policy. If\n" +
  "      you have any questions regarding the way in which we protect your personal\n" +
  "      data, please contact us by writing to </span\n" +
  '    ><span class="c2 c16"\n' +
  '      ><a class="c13" href="mailto:info@MyPatientSpace.com"\n' +
  "        >info@mypatientspace.com</a\n" +
  "      ></span\n" +
  '    ><span class="c4 c2 c8">.</span>\n' +
  "  </p>\n" +
  '  <p class="c6"><span class="c4 c2 c8"></span></p>\n' +
  '  <p class="c12">\n' +
  '    <span class="c2 c15"\n' +
  "      >Any reference to &ldquo;I&rdquo;, &ldquo;we&rdquo;, or &ldquo;our&rdquo;\n" +
  "      is a reference to myPatientSpace.</span\n" +
  "    >\n" +
  "  </p>\n" +
  '  <p class="c12">\n' +
  '    <span class="c4 c2 c8"\n' +
  "      >Any reference to &ldquo;HC&rdquo; is a reference to the healthcare\n" +
  "      provider who has given you access to myPatientSpace and is the data\n" +
  "      controller.\n" +
  "    </span>\n" +
  "  </p>\n" +
  '  <p class="c6"><span class="c0"></span></p>\n' +
  '  <p class="c12">\n' +
  '    <span class="c4 c8 c24"\n' +
  "      >BY USING OUR SERVICES, YOU COMPLY WITH THE COLLECTION, USE, AND\n" +
  "      TRANSMITTAL OF YOUR PERSONAL DATA FOR PROCESSING IN THE EU AS DESCRIBED IN\n" +
  "      THIS PRIVACY POLICY.</span\n" +
  "    >\n" +
  "  </p>\n" +
  '  <p class="c6"><span class="c0"></span></p>\n' +
  '  <hr style="page-break-before:always;display:none;" />\n' +
  '  <p class="c6"><span class="c4 c2 c17"></span></p>\n' +
  '  <p class="c12"><span class="c2 c17">1. About myPatientSpace</span></p>\n' +
  '  <p class="c12">\n' +
  '    <span class="c4 c2 c8"\n' +
  "      >We are myPatientSpace Limited (&quot;myPatientSpace&quot;). We deliver a\n" +
  "      mobile application-based service in the form of the app:\n" +
  "      myPatientSpace.</span\n" +
  "    >\n" +
  "  </p>\n" +
  '  <p class="c6"><span class="c4 c2 c8"></span></p>\n' +
  '  <p class="c12"><span class="c4 c2 c17">2. Personal Data</span></p>\n' +
  '  <p class="c40">\n' +
  '    <span class="c0"\n' +
  "      >Personal data is defined by the General Data Protection Regulation (EU\n" +
  "      Regulation 2016/679) (the &ldquo;GDPR&rdquo;) as &lsquo;any information\n" +
  "      relating to an identifiable person who can be directly or indirectly\n" +
  "      identified in particular by reference to an identifier&rsquo;.</span\n" +
  "    >\n" +
  "  </p>\n" +
  '  <p class="c33">\n' +
  '    <span class="c0"\n' +
  "      >Personal data is, in simpler terms, any information about you that\n" +
  "      enables you to be identified. Personal data covers obvious information\n" +
  "      such as your name and contact details, but it also covers less obvious\n" +
  "      information such as identification numbers, electronic location data, and\n" +
  "      other online identifiers. We may also process certain special category\n" +
  "      data which may include health information. The personal data we collect is\n" +
  "      outlined in section 4 below.</span\n" +
  "    >\n" +
  "  </p>\n" +
  '  <p class="c6"><span class="c4 c8 c32"></span></p>\n' +
  '  <p class="c12"><span class="c4 c2 c17">3. Your Rights</span></p>\n' +
  '  <p class="c34">\n' +
  '    <span class="c0"\n' +
  "      >You have several rights in relation to how we use your information:</span\n" +
  "    >\n" +
  "  </p>\n" +
  '  <ul class="c22 lst-kix_list_3-0 start">\n' +
  '    <li class="c5">\n' +
  '      <span class="c0">The right to be informed: through this policy</span>\n' +
  "    </li>\n" +
  '    <li class="c1">\n' +
  '      <span class="c2">Access your information</span><span class="c2">, </span\n' +
  '      ><span class="c2">receive copies of the information he</span\n' +
  '      ><span class="c2">ld</span><span class="c2">&nbsp;about you</span\n' +
  '      ><span class="c2"\n' +
  "        >, have inaccurate information corrected and incomplete information\n" +
  "        updated or have your information deleted.</span\n" +
  '      ><span class="c2">&nbsp;</span\n' +
  '      ><span class="c2 c27"\n' +
  "        >In the event that you wish to exercise these rights, please contact\n" +
  "        your hospital.&nbsp;\n" +
  "      </span>\n" +
  "    </li>\n" +
  '    <li class="c5">\n' +
  '      <span class="c0"\n' +
  "        >Object to particular uses of your personal data - However, doing so may\n" +
  "        have an impact on the services and products we can / are willing to\n" +
  "        provide.</span\n" +
  "      >\n" +
  "    </li>\n" +
  '    <li class="c5">\n' +
  '      <span class="c0"\n' +
  "        >Object to use of your personal data for direct marketing purposes. If\n" +
  "        you object to this use, we will stop using your data for direct\n" +
  "        marketing purposes.</span\n" +
  "      >\n" +
  "    </li>\n" +
  '    <li class="c5">\n' +
  '      <span class="c0"\n' +
  "        >Have your data deleted or its use restricted &ndash; you have a right\n" +
  "        to this under certain circumstances. For example, where you withdraw\n" +
  "        consent you gave us\n" +
  "      </span>\n" +
  "    </li>\n" +
  '    <li class="c5">\n' +
  '      <span class="c0"\n' +
  "        >Obtain a transferable copy of certain data which can be transferred to\n" +
  "        another provider, known as &ldquo;the right to data\n" +
  "        portability&rdquo;.</span\n" +
  "      >\n" +
  "    </li>\n" +
  '    <li class="c28 c21 c30">\n' +
  '      <span class="c0"\n' +
  "        >Withdraw consent at any time, where any processing is based on\n" +
  "        consent</span\n" +
  "      >\n" +
  "    </li>\n" +
  "  </ul>\n" +
  '  <p class="c34">\n' +
  '    <span class="c2 c4"\n' +
  "      >If you have a cause for complaint, you can contact the Irish Data\n" +
  "      Protection Commission or other relevant supervisory authority.</span\n" +
  '    ><span class="c4 c32 c26 c35">&nbsp;</span>\n' +
  "  </p>\n" +
  '  <p class="c6" id="h.gjdgxs"><span class="c0"></span></p>\n' +
  '  <p class="c12"><span class="c2 c17">4. Data Collection</span></p>\n' +
  '  <p class="c18">\n' +
  '    <span class="c2">m</span\n' +
  '    ><span class="c0"\n' +
  "      >yPatientSpace collects data to conduct business and provide you with our\n" +
  "      services. We collect both personal data and other information for these\n" +
  "      purposes.</span\n" +
  "    >\n" +
  "  </p>\n" +
  '  <p class="c12">\n' +
  '    <span class="c2 c15"\n' +
  "      >(a) Personal and sensitive personal data - &quot;Personal data&quot; is\n" +
  "      data that can be used directly or indirectly, alone or with other\n" +
  "      information, to identify you as an individual user of myPatientSpace. This\n" +
  "      is first and foremost data about your contact information, your health and\n" +
  "      information concerning your treatment journey. Some of the information\n" +
  "      collected will depend on what your HC has configured. The following are\n" +
  "      examples of personal data that you or your healthcare provider may submit\n" +
  "      when you create a user account and when you use myPatientSpace:</span\n" +
  "    >\n" +
  "  </p>\n" +
  '  <ol class="c22 lst-kix_list_1-0 start" start="1">\n' +
  '    <li class="c14">\n' +
  '      <span class="c2 c15"\n' +
  "        >Information about your name, phone number, email address and\n" +
  "        password.</span\n" +
  "      >\n" +
  "    </li>\n" +
  '    <li class="c1">\n' +
  '      <span class="c2 c15"\n' +
  "        >Information about height, weight, body measurements, age, and other\n" +
  "        health data as configured by HC.\n" +
  "      </span>\n" +
  "    </li>\n" +
  '    <li class="c1">\n' +
  '      <span class="c2 c15"\n' +
  "        >PROM (Patient Reported Outcome Measures) such as Oxford knee and hip\n" +
  "        scores or Sleep Data</span\n" +
  "      >\n" +
  "    </li>\n" +
  '    <li class="c1">\n' +
  '      <span class="c2 c15"\n' +
  "        >Information about doctor diagnosis and specific information about the\n" +
  "        condition and procedures</span\n" +
  "      >\n" +
  "    </li>\n" +
  '    <li class="c1">\n' +
  '      <span class="c2 c15"\n' +
  "        >Data concerning the number of completed daily tasks and how you rated\n" +
  "        symptoms such your pain levels.</span\n" +
  "      >\n" +
  "    </li>\n" +
  '    <li class="c9"><span class="c2 c15">Your address</span></li>\n' +
  "  </ol>\n" +
  '  <p class="c18">\n' +
  '    <span class="c0"\n' +
  "      >(b) Other Information - &quot;Other information&quot; is anonymous,\n" +
  "      aggregated, de-identified, or other types of information that do not\n" +
  "      reveal your identity. Some examples are age, sex, browser, operating\n" +
  "      system, number of PROMS taken or the amount of time spent on our services.\n" +
  "      We collect and use this information to understand how you, and our users\n" +
  "      in general, use our services to continuously improve, innovate, and\n" +
  "      produce products and services that satisfy our users&#39; demands. We\n" +
  "      generally do not view other information as personal data. If we chain\n" +
  "      together other information in a way that makes you identifiable as an\n" +
  "      individual user, we will handle that information as personal data.</span\n" +
  "    >\n" +
  "  </p>\n" +
  '  <p class="c18 c29"><span class="c0"></span></p>\n' +
  '  <p class="c12"><span class="c2 c17">5. How We Use Personal Data</span></p>\n' +
  '  <p class="c12">\n' +
  '    <span class="c2 c15"\n' +
  "      >myPatientSpace uses the data we collect to conduct our business,\n" +
  "      advertise, or deliver our products and services, improve existing products\n" +
  "      and services, develop new products and services, and to improve and\n" +
  "      personalize your user experience when you interact with us. We can use\n" +
  "      this data to:</span\n" +
  "    >\n" +
  "  </p>\n" +
  '  <p class="c12">\n' +
  '    <span class="c2 c15"\n' +
  "      >(3.1) Deliver our services and maintain an effective operation of our\n" +
  "      IT-structure - We use the data we collect to deliver the services we\n" +
  "      offer. This includes operating, maintaining, and delivering all content\n" +
  "      and functions of our services to you.\n" +
  "    </span>\n" +
  "  </p>\n" +
  '  <p class="c12"><span class="c2 c15">&nbsp;</span></p>\n' +
  '  <p class="c12">\n' +
  '    <span class="c2 c15"\n' +
  "      >(3) Communicate with you and respond to your requests\n" +
  "    </span>\n" +
  "  </p>\n" +
  '  <p class="c12">\n' +
  '    <span class="c2 c15"\n' +
  "      >We can use your personal data to respond to your requests regarding\n" +
  "      technical support, online services, product information, or any other\n" +
  "      communication you wish to initiate with us. This includes access to your\n" +
  "      account to comply with requests for technical support. Please note that\n" +
  "      regardless of your email settings we can send you messages regarding the\n" +
  "      performance of services, such as our Terms of Service or this Privacy\n" +
  "      Policy, or other formal communications regarding our Products or Services\n" +
  "      that you have purchased or used. </span\n" +
  '    ><span class="c2 c27 c11"\n' +
  "      >You may opt-out from receiving communications from myPatientSpace (other\n" +
  "      than those necessary for myPatientSpace to provide the service for which\n" +
  "      you have subscribed) at anytime by emailing us at&nbsp;</span\n" +
  '    ><span class="c23 c2 c27"\n' +
  '      ><a class="c13" href="mailto:support@mypatientspace.com"\n' +
  "        >support@mypatientspace.com</a\n" +
  "      ></span\n" +
  '    ><span class="c2 c3"\n' +
  "      >&nbsp;This will mean your account is no longer active and you will need\n" +
  "      to set up a new account with us to avail of our service.</span\n" +
  "    >\n" +
  "  </p>\n" +
  '  <p class="c6"><span class="c0"></span></p>\n' +
  '  <p class="c12"><span class="c2 c17">6. How we handle personal data</span></p>\n' +
  '  <p class="c12">\n' +
  '    <span class="c4 c2 c8"\n' +
  "      >myPatientSpace will only disclose your personal data with your knowledge\n" +
  "      and as stated in this Privacy Policy, or as indicated at the time of\n" +
  "      collection. This includes information that enables us to fulfil our\n" +
  "      obligations to you and conduct our business. We only share your personal\n" +
  "      data with third parties in certain individual cases, specifically with\n" +
  "      your consent, or as necessary to complete a transaction or provide a\n" +
  "      product, service, or feature that you have requested. We may disclose your\n" +
  "      personal data as described in this Privacy Policy and as described in the\n" +
  "      following ways:</span\n" +
  "    >\n" +
  "  </p>\n" +
  '  <p class="c6"><span class="c0"></span></p>\n' +
  '  <p class="c18">\n' +
  '    <span class="c2 c15">(6.1) </span\n' +
  '    ><span class="c2 c19"\n' +
  "      >health professionals, independent consultants and hospitals that work\n" +
  "      with myPatientSpace and offer the use of this application as part of the\n" +
  "      provision of medical&nbsp;treatment;\n" +
  "    </span>\n" +
  "  </p>\n" +
  '  <p class="c18">\n' +
  '    <span class="c2 c19"\n' +
  "      >(6.2) IT service providers that either host or have access to our data as\n" +
  "      part of their product offering;\n" +
  "    </span>\n" +
  "  </p>\n" +
  '  <p class="c18">\n' +
  '    <span class="c2 c19"\n' +
  "      >(6.3) health insurers &nbsp;where the treatment is covered by your\n" +
  "      private health insurance policy and you have consented to provide\n" +
  "      information such as patient reported outcomes.</span\n" +
  "    >\n" +
  "  </p>\n" +
  '  <p class="c18">\n' +
  '    <span class="c2 c19"\n' +
  "      >(6.4) regulatory bodies such as national registries if you have consented\n" +
  "      to participate.</span\n" +
  "    >\n" +
  "  </p>\n" +
  '  <p class="c12">\n' +
  '    <span class="c2 c15"\n' +
  "      >(6.5) As we grow and develop - Business transactions and reorganisations.\n" +
  "      Should myPatientSpace ever merge with another company or if myPatientSpace\n" +
  "      should decide to buy, sell, or reorganise parts of or all its\n" +
  "      business(es), we may disclose or transfer to the extent permitted by law\n" +
  "      and in accordance with applicable requirements to notify you your personal\n" +
  "      data to prospective or actual buyers or the subsequent business entity in\n" +
  "      connection with any of these transactions or reorganisations.</span\n" +
  "    >\n" +
  "  </p>\n" +
  '  <p class="c12">\n' +
  '    <span class="c2 c15"\n' +
  "      >(6.6) As required by law and special circumstances We may be forced to\n" +
  "      disclose your personal data if: (i) it is reasonably necessary to comply\n" +
  "      with legal proceedings (such as a court order, search warrant, etc.) or\n" +
  "      other legal requirements of any public authority, (ii) such a disclosure\n" +
  "      would potentially reduce our liability in a real or potential trial, (iii)\n" +
  "      it is necessary to protect our legal rights or property, or (iv) it is\n" +
  "      necessary to protect the legal rights, property, or other parties&#39;\n" +
  "      physical security or for the prevention or discovery of crime and such a\n" +
  "      disclosure is legitimate.</span\n" +
  "    >\n" +
  "  </p>\n" +
  '  <p class="c12">\n' +
  '    <span class="c2 c15"\n' +
  "      >(6.7) Exchange of information with other companies We exchange anonymous\n" +
  "      information about the use of the myPatientSpace app and website with the\n" +
  "      following companies: [Oxford University, Aptible, AWS, Stripe, Google,\n" +
  "      Logentries] These companies help us evaluate and analyse product and\n" +
  "      consumer trends as well as evaluate the impact of, for example,\n" +
  "      advertising campaigns through Google and Facebook. We exchange credit card\n" +
  "      information as well as addresses with Stripe, Inc. This is mainly for\n" +
  "      providers. Please note that myPatientSpace does not store information\n" +
  "      about your credit card, as this information is directly forwarded to\n" +
  "      Stripe, Inc. We exchange your phone number with AWS, an SMS-messaging\n" +
  "      service. &nbsp;We exchange the number of questionnaires completed with\n" +
  "      Oxford but no specifics of your personal data.\n" +
  "    </span>\n" +
  "  </p>\n" +
  '  <p class="c12">\n' +
  '    <span class="c2 c11 c27"\n' +
  "      >(6.8) any member of our group of companies, which means our subsidiaries,\n" +
  "      our ultimate holding company and its subsidiaries.</span\n" +
  "    >\n" +
  "  </p>\n" +
  '  <p class="c6"><span class="c4 c2 c17"></span></p>\n' +
  '  <p class="c12"><span class="c2 c17">7. Information security</span></p>\n' +
  '  <p class="c12">\n' +
  '    <span class="c4 c2 c8"\n' +
  "      >myPatientSpace is committed to protecting the security of your personal\n" +
  "      data. We use technical and organizational measures designed to protect\n" +
  "      your information from unauthorized access, theft, and loss. We also\n" +
  "      recommend that you take additional measures to protect yourself and your\n" +
  "      information, such as installing antivirus software, closing browsers after\n" +
  "      use, keeping your login information and passwords secret, and making sure\n" +
  "      you regularly update software and apps you have downloaded to ensure that\n" +
  "      you have activated the latest security features on your devices.</span\n" +
  "    >\n" +
  "  </p>\n" +
  '  <p class="c6"><span class="c0"></span></p>\n' +
  '  <p class="c12"><span class="c4 c2 c17">8. Storing data</span></p>\n' +
  '  <p class="c12">\n' +
  '    <span class="c4 c2 c8"\n' +
  "      >myPatientSpace stores your personal data as long as you have an Account,\n" +
  "      or as long as it is necessary to deliver our Services to you. We also keep\n" +
  "      and use your personal data, as necessary, to comply with legal\n" +
  "      obligations, resolve conflicts, and enforce our agreements. &nbsp; The\n" +
  "      duration of how long your data is stored may be specified by the HC who\n" +
  "      provides you access to our service.\n" +
  "    </span>\n" +
  "  </p>\n" +
  '  <p class="c6"><span class="c0"></span></p>\n' +
  '  <p class="c12">\n' +
  '    <span class="c2 c17">9. Purpose for processing your data</span>\n' +
  "  </p>\n" +
  '  <p class="c12">\n' +
  '    <span class="c2 c11"\n' +
  "      >We will process any personal data that you provide to us for the\n" +
  "      following purposes:<br\n" +
  "    /></span>\n" +
  "  </p>\n" +
  '  <ul class="c22 lst-kix_list_2-0 start">\n' +
  '    <li class="c30 c37">\n' +
  '      <span class="c2 c11"\n' +
  "        >To provide you with the services you have ordered;</span\n" +
  "      >\n" +
  "    </li>\n" +
  '    <li class="c10">\n' +
  '      <span class="c2 c11"\n' +
  "        >To carry out our obligations arising from any contracts entered into\n" +
  "        between you and HC;</span\n" +
  "      >\n" +
  "    </li>\n" +
  '    <li class="c10">\n' +
  '      <span class="c2 c11"\n' +
  "        >To deliver and maintain a high-quality service and to improve and\n" +
  "        develop the service provided;</span\n" +
  "      >\n" +
  "    </li>\n" +
  '    <li class="c10">\n' +
  '      <span class="c2 c11"\n' +
  "        >To allow you to access the myPatientSpace application and to\n" +
  "        participate in interactive features of our Service, when you choose to\n" +
  "        do so;</span\n" +
  "      >\n" +
  "    </li>\n" +
  '    <li class="c10">\n' +
  '      <span class="c2 c11"\n' +
  "        >To provide you with information, products or services that you request\n" +
  "        from us or that we feel may interest you, where you have consented to be\n" +
  "        contacted for such purposes;</span\n" +
  "      >\n" +
  "    </li>\n" +
  '    <li class="c10">\n' +
  '      <span class="c2 c11"\n' +
  "        >To respond to any communications you might send to us;</span\n" +
  "      >\n" +
  "    </li>\n" +
  '    <li class="c10">\n' +
  '      <span class="c2 c11"\n' +
  "        >To inform you about changes to the Service, our Agreement with you or\n" +
  "        website that we believe will be of interest to you;</span\n" +
  "      >\n" +
  "    </li>\n" +
  '    <li class="c10">\n' +
  '      <span class="c2 c11"\n' +
  "        >As the myPatientSpace application is being actively developed we might\n" +
  "        contact you to get feedback on the service provided;</span\n" +
  "      >\n" +
  "    </li>\n" +
  '    <li class="c10">\n' +
  '      <span class="c2 c11"\n' +
  "        >To determine whether you are in compliance with our Agreement with you\n" +
  "        and to impose sanctions where necessary;</span\n" +
  "      >\n" +
  "    </li>\n" +
  '    <li class="c10">\n' +
  '      <span class="c2 c11"\n' +
  "        >As proof of identity before we disclose personal information to you\n" +
  "        that we hold about you (in order to establish that we are disclosing the\n" +
  "        personal information to you and not to someone pretending to be\n" +
  "        you);</span\n" +
  "      >\n" +
  "    </li>\n" +
  '    <li class="c10">\n' +
  '      <span class="c2 c11"\n' +
  "        >To process payments from or to you if applicable;</span\n" +
  "      >\n" +
  "    </li>\n" +
  '    <li class="c10">\n' +
  '      <span class="c2 c11"\n' +
  "        >Compare information for accuracy and verify it with third\n" +
  "        parties.</span\n" +
  "      >\n" +
  "    </li>\n" +
  '    <li class="c10">\n' +
  '      <span class="c2 c11"\n' +
  "        >For safety purposes, to provide you with customer support and to\n" +
  "        resolve technical or other problems; and</span\n" +
  "      >\n" +
  "    </li>\n" +
  '    <li class="c30 c39">\n' +
  '      <span class="c2 c11"\n' +
  "        >To determine whether you or other users are in compliance with this\n" +
  "        Agreement relating to the application, including the policies and terms\n" +
  "        and conditions of the application.</span\n" +
  "      >\n" +
  "    </li>\n" +
  "  </ul>\n" +
  '  <p class="c12">\n' +
  '    <span class="c2 c15">10</span><span class="c2 c17">. Cookies</span>\n' +
  "  </p>\n" +
  '  <p class="c12">\n' +
  '    <span class="c2 c15"\n' +
  "      >How we use data collection tools and online advertising - myPatientSpace\n" +
  "      currently does not use cookies but it may use cookies to collect data that\n" +
  "      helps us to personalize your use of our Services in the future.</span\n" +
  "    >\n" +
  "  </p>\n" +
  '  <p class="c12"><span class="c2 c15">&nbsp;</span></p>\n' +
  '  <p class="c12"><span class="c2 c17">11. Children</span></p>\n' +
  '  <p class="c12">\n' +
  '    <span class="c2 c15"\n' +
  "      >We do not knowingly collect personal data from children under </span\n" +
  '    ><span class="c2 c15">1</span\n' +
  '    ><span class="c4 c2 c8"\n' +
  "      >6. If you become aware that a child has provided us with personal data\n" +
  "      without parental consent, please contact us at info@myPatientSpace.com. If\n" +
  "      we become aware that a child under 16 has provided us with personal data\n" +
  "      without the parents&rsquo; consent, we will remove the information and\n" +
  "      delete the child&#39;s account.</span\n" +
  "    >\n" +
  "  </p>\n" +
  '  <p class="c6"><span class="c0"></span></p>\n' +
  '  <p class="c12">\n' +
  '    <span class="c2 c17">12. &nbsp;</span><span class="c2 c17">Information</span\n' +
  '    ><span class="c2 c17">&nbsp;Sharing</span>\n' +
  "  </p>\n" +
  '  <p class="c12">\n' +
  '    <span class="c4 c2 c8">Residents of the European Economic Area </span>\n' +
  "  </p>\n" +
  '  <p class="c12">\n' +
  '    <span class="c4 c2 c8"\n' +
  "      >If you choose not to provide personal data - You may choose not to supply\n" +
  "      myPatientSpace with your personal data. To do this, please send an email\n" +
  "      to info@myPatientSpace.com. If you choose not to provide your personal\n" +
  "      data, the app&#39;s personalised care journey program will be\n" +
  "      disabled.</span\n" +
  "    >\n" +
  "  </p>\n" +
  '  <p class="c6"><span class="c0"></span></p>\n' +
  '  <p class="c12">\n' +
  '    <span class="c4 c2 c8">Third parties not under our control &ndash; </span>\n' +
  "  </p>\n" +
  '  <p class="c12">\n' +
  '    <span class="c4 c2 c8"\n' +
  "      >Please note that this Privacy Policy does not apply to practices for\n" +
  "      companies we do not own or manage or people we do not employ or manage.\n" +
  "      For example, if you download one of our applications on your smartphone,\n" +
  "      the manufacturer of your smartphone may have a policy that applies to its\n" +
  "      own practices for collecting information through that smartphone. We\n" +
  "      recommend that you read the privacy policies for each device, website, and\n" +
  "      service you are using.</span\n" +
  "    >\n" +
  "  </p>\n" +
  '  <p class="c6"><span class="c0"></span></p>\n' +
  '  <p class="c12"><span class="c2 c17">13. How to contact us</span></p>\n' +
  '  <p class="c12">\n' +
  '    <span class="c2 c15"\n' +
  "      >If you have any questions, comments or concerns about the way we handle\n" +
  "      your personal data, please contact us by sending an email to </span\n" +
  '    ><span class="c2 c23"\n' +
  '      ><a class="c13" href="mailto:info@MyPatientSpace.com"\n' +
  "        >info@mypatientspace.com</a\n" +
  "      ></span\n" +
  '    ><span class="c4 c2 c8">.</span>\n' +
  "  </p>\n" +
  '  <p class="c6"><span class="c0"></span></p>\n' +
  '  <p class="c12"><span class="c2 c17">14. Updates to Privacy Policy</span></p>\n' +
  '  <p class="c12">\n' +
  '    <span class="c4 c2 c8"\n' +
  "      >We may update this Privacy Policy from time to time without notice. You\n" +
  "      can see when this Policy was last modified by checking the Effective Date.\n" +
  "      We advise you periodically to review this privacy policy to be sure you\n" +
  "      understand our privacy practices. If you have a registered account, we may\n" +
  "      notify you of changes to our privacy policy by email and we may ask you to\n" +
  "      confirm, acknowledge, and agree to the changes the next time you use our\n" +
  "      Services.</span\n" +
  "    >\n" +
  "  </p>\n" +
  '  <p class="c6"><span class="c0"></span></p>\n' +
  '  <p class="c12">\n' +
  '    <span class="c2 c17"\n' +
  "      >15. Access to Information and Updating and Verifying Personal Data\n" +
  "    </span>\n" +
  "  </p>\n" +
  '  <p class="c12">\n' +
  '    <span class="c2 c15">m</span\n' +
  '    ><span class="c2 c15"\n' +
  "      >yPatientSpace keeps your personal data up to date. If you live in the\n" +
  "      European Economic Area, you may request access to, as well as\n" +
  "      modification, opposition, and deletion of your personal data that is in\n" +
  "      the system. To access your personal data, please contact myPatientSpace at\n" +
  "      info@mypatientspace.com. Subject to applicable law, we may charge for this\n" +
  "      service and will comply with reasonable requests as soon as possible and\n" +
  "      in any case within the deadlines prescribed by law. </span\n" +
  '    ><span class="c2 c15"\n' +
  "      >&nbsp;You may also contact your HCP to request access to, or request\n" +
  "      modifications to your personal data.\n" +
  "    </span>\n" +
  "  </p>\n" +
  '  <p class="c6"><span class="c0"></span></p>\n' +
  '  <p class="c12"><span class="c2 c17">16. Our Details </span></p>\n' +
  '  <p class="c12">\n' +
  '    <span class="c2 c11"\n' +
  "      >This application is owned and operated by myPatientSpace Limited.</span\n" +
  "    >\n" +
  "  </p>\n" +
  '  <p class="c12">\n' +
  '    <span class="c2 c11">We are registered in Ireland under </span\n' +
  '    ><span class="c24 c11">Company Registration Number 616748</span\n' +
  '    ><span class="c4 c2 c11 c26">&nbsp;and our registered office is at:</span>\n' +
  "  </p>\n" +
  '  <p class="c12">\n' +
  '    <span class="c2 c11"\n' +
  "      ><br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span\n" +
  '    ><span class="c24 c25">19 The Rise, Malahide, Co. Dublin, Ireland.</span>\n' +
  "  </p>\n" +
  '  <p class="c12 c21">\n' +
  '    <span class="c4 c2 c11 c26"\n' +
  "      >Our principal place of business is:<br />Malahide, Ireland.</span\n" +
  "    >\n" +
  "  </p>\n" +
  '  <p class="c6 c21"><span class="c4 c2 c11 c26"></span></p>\n' +
  '  <p class="c12"><span class="c2 c11">You can contact us:</span></p>\n' +
  '  <p class="c12">\n' +
  '    <span class="c2 c11">(a) by post, to the postal address given above;</span>\n' +
  "  </p>\n" +
  '  <p class="c12">\n' +
  '    <span class="c2 c11">(b) using our website contact form;</span>\n' +
  "  </p>\n" +
  '  <p class="c12">\n' +
  '    <span class="c2 c11">(c) by telephone, on +353860441538; or</span>\n' +
  "  </p>\n" +
  '  <p class="c12">\n' +
  '    <span class="c2 c11"\n' +
  "      >(d) by email, using the email address published on our website from time\n" +
  "      to time.</span\n" +
  "    >\n" +
  "  </p>\n" +
  '  <p class="c12"><span class="c2 c11">&nbsp;</span></p>\n';

export default class MYPPrivacyPolicy extends React.PureComponent {
  render() {
    return <div dangerouslySetInnerHTML={{ __html: PRIVACY_HTML }} />;
  }
}
