import { FEATURES } from "../../services/featuresService";
import { connect } from "react-redux";
import React from "react";
import MPSButton from "../../components/Buttons/Buttons/MPSButton";
import { isStaff } from "../../security/roles";

class StatusActions extends React.PureComponent {
  render() {
    let { status, onClick, canEdit } = this.props;

    let editEnabled =
      canEdit && status.type !== "INTERACTION" && !status.isChannelMessage;

    if (
      status.detailMessage ||
      status.textMessage ||
      (status.contents && status.contents.length > 0) ||
      status.artifactAttached
    ) {
      return (
        <div className="status-actions">
          <MPSButton
            type="block"
            size={"sm"}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              onClick(status, "open");
            }}
          >
            See more
          </MPSButton>
          {editEnabled && (
            <button
              className="btn btn-outline-dark btn-sm mx-1"
              style={{ border: "1px solid #29363d" }}
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                onClick(status, "edit");
              }}
            >
              Edit
            </button>
          )}
          {editEnabled && (
            <button
              className="btn btn-outline-danger btn-sm ml-2"
              style={{ border: "1px solid #f86c6b" }}
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                onClick(status, "delete");
              }}
            >
              Delete
            </button>
          )}
        </div>
      );
    }
    return null;
  }
}

const mapStateToProps = (state, ownProps) => {
  let pf = state.user.data.provisionedFeatures || [];
  let ed = pf.indexOf(FEATURES.EDIT_DELETE_STATUS_MESSAGE) !== -1;
  let userRole = state.user.role;

  return {
    canEdit: ed && isStaff(userRole)
  };
};

export default connect(mapStateToProps)(StatusActions);
