// @flow

import {
  ACCEPT_INVITE,
  ACCEPT_TERMS,
  CONFIRMATION_CODE,
  DISMISS_ERROR,
  FETCH_FACILITY_DETAILS,
  LOG_IN,
  LOG_OUT,
  RESET_LOADING_USER,
  SET_CREDENTIALS,
  SET_USER_NAME,
  UPDATE_AVAILABILITY_STATUS
} from "./actionTypes";
import storageService from "../../services/storageService";
import { STORAGE_TYPE } from "../store/configureStore";

export function onResetLoading() {
  return { type: RESET_LOADING_USER };
}

export function onAcceptInvite(inviteCode, email, phone, challengeFields) {
  return { type: ACCEPT_INVITE, inviteCode, email, phone, challengeFields };
}
export function onAcceptTerms() {
  return { type: ACCEPT_TERMS };
}
export function onConfirmationCode(confirmationCode, inviteCode) {
  return { type: CONFIRMATION_CODE, confirmationCode, inviteCode };
}
export function onSetCredentials(loginUserId, password) {
  return { type: SET_CREDENTIALS, loginUserId, password };
}

export function onDismissError() {
  // console.log('DISMISS ERROR');
  return { type: DISMISS_ERROR };
}

export function onLogin(username, password, redirect) {
  // console.log('actions onLogin', username, password);
  return { type: LOG_IN, username, password, redirect };
}
export function onLogout(message) {
  storageService.clearSessionStorage();
  storageService.removeFromLocalStorage(STORAGE_TYPE);
  return { type: LOG_OUT, message };
}

export function onFetchFacilityDetails() {
  return { type: FETCH_FACILITY_DETAILS };
}

export function onUpdateAvailabilityStatus(availabilityStatus) {
  return { type: UPDATE_AVAILABILITY_STATUS, availabilityStatus };
}

export function onSetOnboardingUserName(userName) {
  return { type: SET_USER_NAME, userName };
}

// export function onSwitchUserType(userType, organizationId) {
//   // console.log(' USER TYPE', userType, organizationId);
//   return { type: SWITCH_USER_TYPE, userType, organizationId };
// }
