import React, { PureComponent } from "react";
import { Card, CardBody, CardGroup, Col, Container, Row } from "reactstrap";
import organizationsService from "../../services/organizationsService";
import UserOnboardingStepInvite from "./UserOnboardingStepInvite";
import UserOnboardingStepSetCredentials from "./UserOnboardingStepSetCredentials";
import FacilityLogo from "./FacilityLogo";
import CredentialsPresentMessage from "./CredentialsPresentMessage";
import UserOnboardingAcceptTerms from "./UserOnboardingAcceptTerms";
import UserOboardingLoadingIndicator from "./UserOboardingLoadingIndicator";
import UserOboardingInviteError from "./UserOboardingInviteError";
import UserOboardingConfirmationMessage from "./UserOboardingConfirmationMessage";
import SignupIntro from "../Signup/SignupIntro";
import SignupConsent from "../Signup/SignupConsent";
import ContactVerificationPage from "./ContactVerificationPage";

class UserOnboardingView extends PureComponent {
  getSupportEmailNode = () => {
    let { facilityInfo } = this.props;
    if (!facilityInfo) {
      return null;
    }
    let email = organizationsService.fetchSupportEmailFromFacilityInfo(
      facilityInfo
    );
    return (
      <a href={`mailto:${email}`} className="cursor-pointer">
        {email}
      </a>
    );
  };

  render() {
    const {
      loading,
      error,
      errorMsg,
      step,
      facilityInfo,
      challengeFields,
      inviteError,
      inviteErrMessage,
      errorCode,
      onboardingType,
      linkType,
      selfSignup,
      confirmSuccessMessage
    } = this.props;

    if (loading) {
      return <UserOboardingLoadingIndicator />;
    }
    if (inviteError) {
      return (
        <UserOboardingInviteError
          message={inviteErrMessage}
          errorCode={errorCode}
          onboardingType={onboardingType}
          linkType={linkType}
        />
      );
    }
    if (step === "show-confirmation") {
      return (
        <UserOboardingConfirmationMessage
          linkType={linkType}
          confirmSuccessMessage={confirmSuccessMessage}
          history={this.props.history}
          onboardingType={onboardingType}
        />
      );
    }

    return (
      <div className="app flex-column justify-content-center align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md="8">
              <div className="ob-logos my-3">
                {facilityInfo && facilityInfo.appTheme && (
                  <div className="p-3">
                    <FacilityLogo
                      appTheme={facilityInfo.appTheme}
                      height="42px"
                    />
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </Container>

        <Container>
          <Row className="justify-content-center">
            <Col md="8" sm="12">
              <CardGroup>
                <Card className="p-md-4 p-sm-3">
                  <CardBody>
                    <div className="mb-3 text-center">
                      {facilityInfo.introductionTitle ? (
                        <h5>{facilityInfo.introductionTitle}</h5>
                      ) : (
                        <h5>
                          {"Account Setup" +
                            (facilityInfo && facilityInfo.facilityName
                              ? " for " + facilityInfo.facilityName
                              : "")}
                        </h5>
                      )}
                    </div>

                    <Row>
                      <Col md={{ size: 10, offset: 1 }} sm={{ size: 12 }}>
                        <div className="mt-3">
                          {error && (
                            <p className="text-muted text-center">
                              <span style={{ color: "red" }}>{errorMsg}</span>
                            </p>
                          )}

                          {step === "intro" && (
                            <SignupIntro
                              facilityInfo={facilityInfo}
                              incrementStep={() => {
                                this.props.goToNextFrom("intro");
                              }}
                              noTitle
                            />
                          )}

                          {step === "consent" && (
                            <SignupConsent
                              facilityInfo={facilityInfo}
                              incrementStep={() => {
                                this.props.goToNextFrom("consent");
                              }}
                              decrementStep={() => {
                                this.props.goBackFrom("consent");
                              }}
                            />
                          )}

                          {step === "invite" && (
                            <UserOnboardingStepInvite
                              confirmIdentity={this.props.confirmIdentity}
                              challengeFields={challengeFields}
                              onboardingType={onboardingType}
                              facilityName={
                                facilityInfo ? facilityInfo.facilityName : ""
                              }
                              supportEmail={organizationsService.fetchSupportEmailFromFacilityInfo(
                                facilityInfo
                              )}
                              error={error}
                              consented={facilityInfo.consentText}
                              facilityInfo={facilityInfo}
                            />
                          )}

                          {step === "invite-terms" && (
                            <UserOnboardingStepInvite
                              confirmIdentity={this.props.confirmIdentity}
                              challengeFields={challengeFields}
                              showTerms
                              onboardingType={onboardingType}
                              facilityName={
                                facilityInfo ? facilityInfo.facilityName : ""
                              }
                              supportEmail={organizationsService.fetchSupportEmailFromFacilityInfo(
                                facilityInfo
                              )}
                              error={error}
                              consented={facilityInfo.consentText}
                              facilityInfo={facilityInfo}
                            />
                          )}

                          {step === "contactVerification" && (
                            <ContactVerificationPage
                              email={this.props.email}
                              phone={this.props.phone}
                              submit={this.props.verifyContact}
                            />
                          )}

                          {step === "set-credentials" && (
                            <UserOnboardingStepSetCredentials
                              setCredentials={this.props.setCredentials}
                              facilityInfo={facilityInfo}
                              email={this.props.email}
                              selfSignup={selfSignup}
                              defaultValueForLoginUserId={
                                this.props.defaultValueForLoginUserId
                              }
                            />
                          )}

                          {step === "terms" && (
                            <UserOnboardingAcceptTerms
                              confirmIdentity={this.props.confirmIdentity}
                              facilityInfo={facilityInfo}
                            />
                          )}

                          {step === "credentials-present-message" && (
                            <CredentialsPresentMessage
                              history={this.props.history}
                              facilityName={facilityInfo.facilityName}
                            />
                          )}
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </CardGroup>

              <div className="mt-3 text-center">
                If you're having trouble with setting up your account, please
                contact support at: {this.getSupportEmailNode()}
              </div>
            </Col>
          </Row>
        </Container>

        <Container>
          <Row className="justify-content-center">
            <Col md="8">
              <div className="ob-logos my-3">
                <div className="p-3">
                  <img
                    alt="myPatientSpace"
                    src="/img/mps-logo.png"
                    height={24}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default UserOnboardingView;
