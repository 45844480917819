import { PATIENT_API_ENDPOINTS as API_ENDPOINTS } from "../config";
import { http } from "../utils/http";
import StorageService from "./storageService";

const PATIENT_APP_HOME_VIEW_TABS = "PATIENT_APP_HOME_VIEW_TABS";

class PatientAppServices {
  static loadCarePlan() {
    return http.request(API_ENDPOINTS.carePlan, "get");
  }

  static loadPatientProfile() {
    return http.request(API_ENDPOINTS.patientProfile, "get");
  }

  static updateProfileAttribute(data) {
    return http.request(API_ENDPOINTS.updateProfileAttribute, "post", data);
  }

  static loadStatusMessages(page) {
    return http
      .request(API_ENDPOINTS.statusMessages + `&page=${page}&size=30`)
      .then(data => {
        if (data && data._embedded) {
          return {
            data:
              page <= data.page.totalPages ? data._embedded.statusMessages : [],
            loadMore: data.page.totalPages > page + 1
          };
        } else {
          return {
            data: [],
            loadMore: false
          };
        }
      });
  }

  static loadProvisionedSurveys() {
    return http.request(API_ENDPOINTS.provisionedSurveys).then(data => {
      return data && data._embedded ? data._embedded.provisionedSurveys : [];
    });
  }

  static loadSurveyResponses() {
    return http.request(API_ENDPOINTS.surveyResponses).then(data => {
      return data && data._embedded ? data._embedded.surveyResponses : [];
    });
  }

  static loadTasks(completed) {
    return http
      .request(API_ENDPOINTS.tasks + (completed ? "?completedOnly=true" : ""))
      .then(response => {
        if (response && response._embedded) {
          return response._embedded.tasks || [];
        }
        return [];
      });
  }

  static loadMembers() {
    return http.request();
  }

  static loadSurvey(surveyLink) {
    return http.request(surveyLink);
  }

  static loadInfoPanel() {
    return http.request(API_ENDPOINTS.infoPanel);
  }

  static submitConsent(messageId, consentId, data) {
    return http.request(
      API_ENDPOINTS.consentSubmission(messageId, consentId),
      "post",
      data
    );
  }

  // static blobToFile(theBlob, fileName) {
  //   theBlob.lastModifiedDate = new Date().getTime();
  //   theBlob.name = fileName;
  //   return theBlob;
  // }

  static getHomeViewTabs() {
    let tabs = StorageService.getFromLocalStorage(PATIENT_APP_HOME_VIEW_TABS);
    if (!tabs) {
      tabs = [
        {
          id: "dashboard",
          label: "DASHBOARD"
        },

        {
          id: "updates",
          label: "UPDATES"
        },

        {
          id: "tasks",
          label: "TASKS"
        },

        {
          id: "usage",
          label: "USAGE"
        },
        {
          id: "info",
          label: "INFO"
        },
        {
          id: "progress",
          label: "PROGRESS"
        }
      ];
    }
    return tabs;
  }

  storeHomeViewTabs(tabs) {
    StorageService.addToLocalStorage(PATIENT_APP_HOME_VIEW_TABS, tabs);
  }

  // API: Get appointment schedule /api/patient/schedules/{id}
  // Get app allointments for a staff /api/patient/appointments?staffId= etc
  //     Create appointment api/patient/appointments

  static getAppointmentSchedule(scheduleId) {
    return http.request(API_ENDPOINTS.appointmentSchedule(scheduleId));
  }

  static createAppointment = data => {
    return http.request(
      API_ENDPOINTS.createAppointmentFromTemplate(),
      "post",
      data
    );
  };
}

export default PatientAppServices;
