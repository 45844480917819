import React, { PureComponent } from "react";
import { getValueFieldNameForFilter, getValueWhenInFilter } from "../Utils";
import Mandatory from "./Mandatory";
import { Input } from "reactstrap";
import classnames from "classnames";
import AttributeInfo from "./AttributeInfo";

export default class TextObservation extends PureComponent {
  render() {
    let {
      placeholder,
      attribute,
      index,
      handleOnBlur,
      changeField
    } = this.props;
    let description = attribute.description || attribute.title;
    return (
      <div
        key={index}
        className="col-12 my-1 pb-2"
        style={{ backgroundColor: "#fff" }}
        ref={attrEl => {
          if (this.props.setAttributeRef) {
            this.props.setAttributeRef(attribute.name, attrEl);
          }
        }}
      >
        {!placeholder && (
          <label style={{ fontWeight: 500 }}>
            {description}
            {attribute.mandatory && <sup style={{ color: "red" }}>*</sup>}
          </label>
        )}
        <div className="d-flex  flex-column">
          <Input
            onBlur={() => {
              handleOnBlur(index, attribute);
            }}
            placeholder={placeholder ? description : ""}
            type="text"
            value={getValueWhenInFilter(attribute)}
            onChange={e => {
              let entered = e.target.value;
              if (entered) {
                attribute.value = getValueFieldNameForFilter(attribute, {
                  value: { valueString: entered },
                  code: { code: attribute.observationCode.code }
                });
              } else {
                attribute.value = null;
              }
              changeField(index, attribute);
            }}
            className={classnames(
              attribute.error ? "is-invalid form-control" : "form-control"
            )}
            style={{ borderRadius: "4px" }}
          />
          {placeholder && <Mandatory mandatory={attribute.mandatory} />}
          <div className="invalid-feedback">{attribute.error}</div>
          <AttributeInfo info={attribute.info} />
        </div>
      </div>
    );
  }
}
