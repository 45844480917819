import { store } from "../State/store/configureStore";

export const FEATURES = {
  ADD_PATIENT: "AddPatient",
  ADD_MEMBER: "AddMember",
  PATIENT_COMMUNICATION: "PatientCommunication",
  PUSH_NOTIFICATION_FOR_STATUS_UPDATE: "PushNotificationForStatusUpdate",
  PATIENT_INVITE_MANAGEMENT: "PatientInviteManagement",
  SMS_NOTIFICATION_FOR_ONBOARDING: "SMSNotificationForOnBoarding",
  EMAIL_NOTIFICATION_FOR_ON_BOARDING: "EmailNotificationForOnBoarding",
  TASKS: "Tasks",
  PUSH_NOTIFICATION_FOR_URGENT_TASKS: "PushNotificationForUrgentTasks",
  PUSH_NOTIFICATION_FOR_ALL_TASKS: "PushNotificationForAllTasks",
  HARD_DELETE_ARTIFACTS: "HardDeleteArtifacts",
  CUSTOM_PATIENT_LISTING_ACTIONS: "CustomPatientListingActions",
  FETCH_DETAILED_REPORT: "FetchDetailedReport",
  FETCH_THERAPY_COMPLIANCE_REPORT: "FetchTherapyComplianceReport",
  PACKAGE_IMPORT_EXPORT: "Package_Import_Export",
  REMINDER_SERVICE: "ReminderService",
  EDIT_DELETE_STATUS_MESSAGE: "AllowEditDeleteStatusMessage",
  CUSTOM_PATIENT_REPORT: "CustomPatientReport",
  APPOINTMENT: "Appointment",
  SUBMIT_SURVEY_FROM_STAFF: "SubmitSurveyFromStaff",
  VIDEO_CONFERENCE: "VideoConference",
  IMPORT_PATIENT_CSV: "ImportPatientCSV",
  CARE_TEAM_BASED_VISIBILITY: "CareTeamBasedVisibility",
  UNIQUE_ID_BASED_PATIENT_LOAD: "UniqueIDBasedPatientLoad",
  ADD_STAFF: "AddStaff",
  IMPORT_STAFF_CSV: "ImportStaffCSV",
  ENABLE_PATIENT_APP_IN_WEB: "EnablePatientAppInWeb"
};

class FeaturesService {
  static canAddPatient() {
    let features = store.getState().user.provisionedFeatures;
    return features && features.indexOf(FEATURES.ADD_PATIENT) !== -1;
  }

  static canTasks() {
    let features = store.getState().user.provisionedFeatures;
    return features && features.indexOf(FEATURES.TASKS) !== -1;
  }

  static canReminder() {
    let features = store.getState().user.provisionedFeatures;
    return features && features.indexOf(FEATURES.REMINDER_SERVICE) !== -1;
  }

  static canDoAppointments() {
    let features = store.getState().user.provisionedFeatures;
    return features && features.indexOf(FEATURES.APPOINTMENT) !== -1;
  }

  static canStaffSubmitSurvey() {
    let features = store.getState().user.provisionedFeatures;
    return (
      features && features.indexOf(FEATURES.SUBMIT_SURVEY_FROM_STAFF) !== -1
    );
  }

  static canHaveCareTeamBasedVisibility() {
    let features = store.getState().user.provisionedFeatures;
    return (
      features && features.indexOf(FEATURES.CARE_TEAM_BASED_VISIBILITY) !== -1
    );
  }

  static isUniqueIdBasedPatientLoad() {
    let features = store.getState().user.provisionedFeatures;
    return (
      features && features.indexOf(FEATURES.UNIQUE_ID_BASED_PATIENT_LOAD) !== -1
    );
  }
}

export default FeaturesService;
