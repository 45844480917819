import React, { PureComponent } from "react";
import userService from "../../services/userService";
import UserOnboardingView from "./UserOnboardingView";
import { getAPIUrl } from "../../config";

function getStepForSignupFlow(facilityInfo, onboardingType) {
  if (onboardingType !== "signup") {
    return getStep(facilityInfo, onboardingType);
  }

  if (facilityInfo.introductionText) {
    return "intro";
  } else if (facilityInfo.consentText) {
    return "consent";
  }

  return getStep(facilityInfo, onboardingType);
}

function getStep(facilityInfo, onboardingType) {
  let step = "invite";
  let { credentialsPresent, challengeFields } = facilityInfo;
  if (credentialsPresent) {
    if (onboardingType === "signup" && facilityInfo.consentText) {
      step = "credentials-present-message";
    } else {
      step = challengeFields.length === 0 ? "terms" : "invite-terms";
    }
  } else {
    step = challengeFields.length === 0 ? "set-credentials" : "invite";
  }
  return step;
}

class UserOnboardingContainer extends PureComponent {
  constructor(props) {
    super(props);
    if (this.props.facilityInfo) {
      this.state = this.getStateFromFacilityInfo(
        this.props.facilityInfo,
        this.props.onboardingType
      );
    } else {
      this.state = {
        step: null,
        challengeFields: []
      };
    }
  }

  goToNextFrom = step => {
    let { facilityInfo } = this.state;
    if (step === "intro") {
      if (facilityInfo.consentText) {
        this.setState({ step: "consent" });
        return;
      } else {
        this.setState({
          step: getStep(facilityInfo, this.props.onboardingType)
        });
        return;
      }
    }
    if (step === "consent") {
      this.setState({ step: getStep(facilityInfo, this.props.onboardingType) });
    }
    if (step === "contactVerification") {
      this.setState({ step: "set-credentials" });
    }
  };

  goBackFrom = step => {
    let { facilityInfo } = this.state;
    if (step === "consent") {
      if (facilityInfo.introductionText) {
        this.setState({ step: "intro" });
      }
    }
  };

  componentDidMount() {
    let { region } = this.props;
    userService.setServerURL(getAPIUrl(region));

    this.props.onLogout();
    this.props.onSetOnboardingUserName("");
    let { inviteCode, onboardingType, linkType } = this.props;
    if (this.props.facilityInfo) {
      return;
    }

    this.setState({ challengeLoading: true, confirmSuccessMessage: null });

    let api = null;
    if (onboardingType === "confirm") {
      api = userService.confirmEmail;
    } else if (onboardingType === "signup") {
      api = userService.fetchChallengeFieldsFromFacilityCode;
    } else {
      api = userService.fetchChallengeFields;
    }

    api(inviteCode, linkType)
      .then(facilityInfo => {
        if (!facilityInfo) {
          this.setState({ challengeLoading: false });
          return;
        }
        this.setState(
          this.getStateFromFacilityInfo(facilityInfo, onboardingType)
        );
      })
      .catch(e => {
        this.setState({
          challengeLoading: false,
          inviteError: true,
          inviteErrMessage: e.message,
          errorCode: e.errorCode
        });
      });
  }

  getStateFromFacilityInfo = (facilityInfo, onboardingType) => {
    let { credentialsPresent, challengeFields = [], email } = facilityInfo;
    let step = "invite";
    if (onboardingType === "confirm") {
      step = "show-confirmation";
      return {
        step: step,
        challengeLoading: false,
        confirmSuccessMessage: facilityInfo.confirmSuccessMessage
      };
    } else {
      return {
        credentialsPresent,
        step: getStepForSignupFlow(facilityInfo, onboardingType),
        challengeLoading: false,
        facilityInfo,
        challengeFields: challengeFields,
        email: email
      };
    }
  };

  confirmIdentity = challengeFields => {
    this.setState({ error: false, errorMsg: "" });
    let step = this.state.step;
    // let acceptedTermsAndCondition = step === "terms" || step === "invite-terms";
    let acceptedTermsAndCondition = true;
    userService
      .onboardingConfirmIdentity(
        this.props.inviteCode,
        challengeFields,
        acceptedTermsAndCondition,
        this.props.onboardingType
      )
      .then(response => {
        let { facilityInfo } = this.props;
        if (step === "terms" || step === "invite-terms") {
          this.setState({ step: "credentials-present-message" });
        } else {
          if (response.credentialsPresent) {
            this.setState({ step: "credentials-present-message" });
          } else {
            this.setState({
              step:
                this.props.onboardingType === "signup" &&
                response.verifyContactDetails
                  ? "contactVerification"
                  : "set-credentials",
              patientId: response.patientId,
              defaultValueForLoginUserId: response.defaultValueForLoginUserId,
              email: response.email,
              phone: response.phoneNumber
            });
          }
        }
      })
      .catch(e => {
        let message = e.message;
        if (e.errorCode === "mps.challenge.failed") {
          message = "Verification Failed";
        }
        this.setState({ error: true, errorMsg: message });
      });
  };

  verifyContact = verificationData => {
    this.setState({ error: false, errorMsg: "" });
    let { challengeFields, patientId } = this.state;

    verificationData.patientId = patientId;
    userService
      .onboardingConfirmIdentity(
        this.props.inviteCode,
        challengeFields,
        true,
        this.props.onboardingType,
        true,
        verificationData
      )
      .then(response => {
        this.setState({
          step: "set-credentials",
          patientId: response.patientId,
          defaultValueForLoginUserId: response.defaultValueForLoginUserId,
          email: response.email,
          challengeFields
        });
      })
      .catch(e => {
        let message = e.message;
        if (e.errorCode === "mps.challenge.failed") {
          message = "Verification Failed";
        }
        this.setState({ error: true, errorMsg: message });
      });
  };

  setCredentials = (username, password) => {
    userService
      .onboardingSetCredentials(
        this.props.inviteCode,
        username,
        password,
        true,
        this.props.onboardingType,
        this.state.patientId
      )
      .then(response => {
        this.props.onSetOnboardingUserName(username);
        this.props.onLogin(username, password, true);
      });
  };

  render() {
    let {
      challengeLoading,
      facilityInfo,
      step,
      challengeFields = [],
      error,
      errorMsg,
      inviteError,
      inviteErrMessage,
      errorCode,
      email,
      phone,
      confirmSuccessMessage,
      defaultValueForLoginUserId
    } = this.state;

    let { onboardingType, linkType, selfSignup } = this.props;

    if (!step) {
      return null;
    }

    return (
      <UserOnboardingView
        onboardingType={onboardingType}
        loading={challengeLoading}
        error={error}
        errorMsg={errorMsg}
        step={step}
        facilityInfo={facilityInfo}
        challengeFields={challengeFields}
        confirmIdentity={this.confirmIdentity}
        setCredentials={this.setCredentials}
        inviteError={inviteError}
        inviteErrMessage={inviteErrMessage}
        errorCode={errorCode}
        history={this.props.history}
        email={email}
        phone={phone}
        linkType={linkType}
        selfSignup={selfSignup}
        confirmSuccessMessage={confirmSuccessMessage}
        goToNextFrom={this.goToNextFrom}
        goBackFrom={this.goBackFrom}
        defaultValueForLoginUserId={defaultValueForLoginUserId}
        verifyContact={this.verifyContact}
      />
    );
  }
}

export default UserOnboardingContainer;
