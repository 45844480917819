// Styles
// Import Flag Icons Set
// import 'flag-icon-css/css/flag-icon.min.css';
// Import Font Awesome Icons Set
// import 'font-awesome/css/font-awesome.min.css';
// Import Simple Line Icons Set
// import 'simple-line-icons/css/simple-line-icons.css';
// Import Main styles for this application
import "./utils/objectPolyfill";
import "./scss/style.scss";
// Temp fix for reactstrap
import "./scss/core/_dropdown-menu-right.scss";
import "react-select/scss/default.scss";
import "medium-draft/lib/basic.css";

import locationHelperBuilder from "redux-auth-wrapper/history4/locationHelper";
import { Route, Router, Switch } from "react-router-dom";
import { persistor, store } from "./State/store/configureStore";
import asyncComponent from "./components/AsyncComponent";
import Login from "./Common/Login/";
import Onboarding from "./Common/Onboarding/";
import UserOnboarding from "./Common/UserOnboarding/";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import React from "react";
import ReactDOM from "react-dom";
import { connectedRouterRedirect } from "redux-auth-wrapper/history4/redirect";
import history from "./history";
import ForgotPasswordView from "./Common/ForgotPassword/ForgotPasswordView";
import Toaster from "./components/Alerts/Toaster";
import ResetPasswordView from "./Common/ResetPassword/ResetPasswordView";
import SignupContainer from "./Common/Signup/SignupContainer";
import {
  isFacilityAdmin,
  isMpsAdmin,
  isPatientOrMember,
  isStaff
} from "./security/roles";
import UnsubscribeContainer from "./Common/Notifications/UnsubscribeContainer";
import ExistingRoomContainer from "./components/VideoCall/ExistingRoomContainer";
import isIOSChromeOrSafari from "./components/VideoCall/Utils";
import UnsupportedVideoClients from "./components/VideoCall/UnsupportedVideoClients";
import NewAppointmentContainer from "./PatientApp/containers/NewAppointmentContainer";

const AsyncFull = asyncComponent(() => import("./containers/Full/"));
const AsyncPatientAppLayout = asyncComponent(() =>
  import("./PatientApp/components/PatientAppLayout")
);

const userIsAuthenticated = connectedRouterRedirect({
  // The url to redirect user to if they fail
  redirectPath: "/login",
  // If selector is true, wrapper will not redirect
  // For example let's check that state contains user data
  authenticatedSelector: state => state.user.loggedIn,
  // A nice display name for this check
  wrapperDisplayName: "UserIsAuthenticated",
  allowRedirectBack: false
  // allowRedirectBack: (nextState, redirectPath) => {
  //   if (
  //     nextState.location &&
  //     nextState.location.pathname ===
  //       "/messages/5c77827f1e9d980007df4b1d/survey/5b76a8901615dc0004e83cb8"
  //   ) {
  //     return true;
  //   }
  //   console.log(redirectPath);
  //   return false;
  // }
});

const userIsAuthenticatedForVideo = connectedRouterRedirect({
  redirectPath: "/login",
  authenticatedSelector: state => state.user.loggedIn,
  wrapperDisplayName: "UserIsAuthenticated",
  allowRedirectBack: (nextState, redirectPath) => {
    console.log(redirectPath);
    return true;
  }
});

const locationHelper = locationHelperBuilder({});
const userIsNotAuthenticated = connectedRouterRedirect({
  // This sends the user either to the query param route if we have one, or to the landing page if none is specified and the user is already logged in
  // redirectPath: (state, ownProps) =>
  //   isPatientOrMember(state.user.role) ? "/app" : "/",
  redirectPath: (state, ownProps) => {
    let param = locationHelper.getRedirectQueryParam(ownProps);
    if (param) {
      return param;
    }
    return isPatientOrMember(state.user.role) ? "/app" : "/";
  },

  // This prevents us from adding the query parameter when we send the user away from the login page
  allowRedirectBack: false,
  // If selector is true, wrapper will not redirect
  // So if there is no user data, then we show the page
  authenticatedSelector: state => !state.user.loggedIn,
  // A nice display name for this check
  wrapperDisplayName: "UserIsNotAuthenticated"
});

const userIsPatient = connectedRouterRedirect({
  redirectPath: "/login",
  authenticatedSelector: state => {
    return state.user.loggedIn && isPatientOrMember(state.user.role);
  },
  wrapperDisplayName: "User Is Patient",
  allowRedirectBack: false
});

const userIsNotPatient = connectedRouterRedirect({
  redirectPath: "/login",
  authenticatedSelector: state =>
    state.user.loggedIn &&
    (isStaff(state.user.role) ||
      isFacilityAdmin(state.user.role) ||
      isMpsAdmin(state.user.role)),
  wrapperDisplayName: "User Is Not Patient",
  allowRedirectBack: false
});

console.log("process", process.env);

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Toaster />
      <Router history={history}>
        <Switch>
          <Route
            exact
            path="/login/:invited/:facilityName"
            name="Login Page"
            component={userIsNotAuthenticated(Login)}
          />

          <Route
            exact
            path="/login"
            name="Login Page"
            component={userIsNotAuthenticated(Login)}
          />

          <Route
            exact
            path="/forgot-password"
            name="Forgot Password"
            component={ForgotPasswordView}
          />

          <Route
            exact
            path="/reset-password/:token"
            name="Forgot Password"
            component={ResetPasswordView}
          />

          <Route
            path="/invite/:inviteCode"
            name="Onboarding"
            component={Onboarding}
          />

          <Route path="/acceptTerms" name="Onboarding" component={Onboarding} />

          <Route
            path="/confirmation/:confirmationCode?"
            name="Onboarding"
            component={Onboarding}
          />

          <Route
            path="/setCredentials"
            name="Onboarding"
            component={userIsNotAuthenticated(Onboarding)}
          />

          <Route
            path="/credentials-present"
            name="Onboarding"
            component={userIsNotAuthenticated(Onboarding)}
          />

          <Route
            exact
            path="/unsubscribe/:code"
            name="Unsubscribe Page"
            component={userIsNotAuthenticated(UnsubscribeContainer)}
          />

          <Route
            path="/ob/:onboardingType/:inviteCode?"
            name="UserOnboarding"
            component={UserOnboarding}
          />

          <Route
            path="/signup/:code?"
            name="Self Sign Up"
            component={SignupContainer}
          />

          {/*<Route*/}
          {/*  path="/video-call/:roomName"*/}
          {/*  name="Video calls"*/}
          {/*  component={AnonymousVideoCallContainer}*/}
          {/*/>*/}

          {!isIOSChromeOrSafari() && (
            <Route
              path="/join-video-call/:roomName"
              name="Video calls"
              component={userIsAuthenticatedForVideo(ExistingRoomContainer)}
            />
          )}

          {isIOSChromeOrSafari() && (
            <Route
              path="/join-video-call/:roomName"
              name="Video calls"
              component={UnsupportedVideoClients}
            />
          )}

          {/*<Route*/}
          {/*  path="/join-video-call-r/:roomName"*/}
          {/*  name="Video calls"*/}
          {/*  component={userIsAuthenticatedForVideo(ExistingRoomContainer)}*/}
          {/*/>*/}

          {/*<Route*/}
          {/*  path="/covid-19-dashboard"*/}
          {/*  name="Video calls"*/}
          {/*  component={Covid19StatsContainer}*/}
          {/*/>*/}

          <Route
            path="/appointments/:scheduleId/:templateId/book"
            name="New Appointment"
            component={userIsAuthenticatedForVideo(NewAppointmentContainer)}
          />

          {/*{!isProd() && (*/}
          {/*  <Route*/}
          {/*    path="/app"*/}
          {/*    name="PatientHome"*/}
          {/*    component={userIsPatient(AsyncPatientAppLayout)}*/}
          {/*  />*/}
          {/*)}*/}

          {/*{!isProd() ? (*/}
          {/*  <Route*/}
          {/*    path="/"*/}
          {/*    name="Home"*/}
          {/*    component={userIsNotPatient(AsyncFull)}*/}
          {/*  />*/}
          {/*) : (*/}
          {/*  <Route*/}
          {/*    path="/"*/}
          {/*    name="Home"*/}
          {/*    component={userIsAuthenticated(AsyncFull)}*/}
          {/*  />*/}
          {/*)}*/}

          <Route
            path="/app"
            name="PatientHome"
            component={userIsPatient(AsyncPatientAppLayout)}
          />
          <Route path="/" name="Home" component={userIsNotPatient(AsyncFull)} />
        </Switch>
      </Router>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);
