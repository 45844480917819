import React from "react";
import { Calendar, Views } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import localizer from "react-big-calendar/lib/localizers/moment";
import moment, { min } from "moment";
import { connect } from "react-redux";
import { getLangFromLocale, isRTLLocale } from "../../utils/utils";

let allViews = Object.keys(Views).map(k => Views[k]);
const globalizeLocalizer = localizer(moment);

function getCurrentView(currentView) {
  if (currentView === "WEEK") {
    return Views.WEEK;
  }
  if (currentView === "WORK_WEEK") {
    return Views.WORK_WEEK;
  }
  return Views.MONTH;
}

class MPSCalendar extends React.PureComponent {
  constructor(props) {
    super(props);
    let minTime = props.minTime;
    if (!minTime) {
      minTime = new Date();
      minTime.setHours(0, 0, 0);
    }

    let maxTime = props.maxTime;
    if (!maxTime) {
      maxTime = new Date();
      maxTime.setHours(23, 0, 0);
    }
    this.state = {
      currentView: getCurrentView(props.currentView),
      currentDate: moment().toDate(),
      minTime,
      maxTime,
      scrollToTime: props.scrollToTime
    };
  }

  componentDidMount() {
    let locale = getLangFromLocale(this.props.locale);
    if (locale !== "en") {
      require(`moment/locale/${locale}`);
    }
  }

  handleNavigate = (date, view, action) => {
    const scrollTime = new Date();
    scrollTime.setHours(8, 0, 0);
    this.setState({
      currentView: view,
      currentDate: date,
      scrollToTime: scrollTime
    });
    if (
      this.props.onChangeMonth
      // && view === "month" &&
      // (action === "NEXT" || action === "PREV")
    ) {
      this.props.onChangeMonth(moment(date).format("MMYYYY"));
    }
  };
  onView = view => {
    const scrollTime = new Date();
    scrollTime.setHours(7, 45, 0);
    this.setState({ currentView: view, scrollToTime: scrollTime });
  };

  handleSelectSlot = data => {
    let { start } = data;
    let { currentView } = this.state;
    if (currentView === Views.DAY) {
      this.setState({ currentDate: moment(start).toDate() });
      this.props.onSelectSlot(data);
    } else {
      this.setState({
        currentDate: moment(start).toDate(),
        currentView: Views.DAY
      });
    }
  };

  onShowMore = (events, date, noOfEvents) => {
    this.setState({
      currentDate: moment(date).toDate(),
      currentView: Views.DAY
    });
  };

  handleSelectEvent = event => {
    let { onSelectEvent } = this.props;
    // let { currentView } = this.state;
    // if (currentView === Views.MONTH) {
    //   this.setState({
    //     currentDate: moment(event.start).toDate(),
    //     currentView: Views.DAY
    //   });
    // }
    onSelectEvent(event);
  };

  render() {
    let {
      events = [],
      eventTitleAccessor,
      selectable,
      eventTitleDayAccessor,
      selectedEvent,
      eventStyleGetter,
      agendaLength,
      views
    } = this.props;
    let { currentView, currentDate, minTime, maxTime } = this.state;
    return (
      <Calendar
        length={agendaLength}
        events={events}
        selectable={selectable}
        onSelectEvent={this.handleSelectEvent}
        onSelectSlot={this.handleSelectSlot}
        views={views || allViews}
        step={15}
        showMultiDayTimes
        date={currentDate}
        onNavigate={this.handleNavigate}
        onView={this.onView}
        localizer={globalizeLocalizer}
        view={currentView}
        onDrillDown={this.onDrillDown}
        onShowMore={this.onShowMore}
        titleAccessor={
          currentView === Views.MONTH
            ? eventTitleAccessor
            : eventTitleDayAccessor
            ? eventTitleDayAccessor
            : null
        }
        min={minTime}
        max={maxTime}
        selected={selectedEvent}
        eventPropGetter={currentView !== Views.AGENDA ? eventStyleGetter : null}
        rtl={!!isRTLLocale(getLangFromLocale(this.props.locale))}
        scrollToTime={this.state.scrollToTime}
      />
    );
  }
}

const mapstatetoprops = state => ({
  locale: state.user.data.preferredLocale
});

export default connect(mapstatetoprops)(MPSCalendar);
