import {
  Button,
  Col,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  FormGroup
} from "reactstrap";
import React from "react";
import classnames from "classnames";
import { validateText } from "../../utils/utils";
import StepAcceptTerms from "../Onboarding/StepAcceptTerms";

class UserOnboardingStepSetCredentials extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      repeatPassword: "",
      useEmail: false
    };
  }

  onUpdateUsername = e => {
    this.setState({ username: e.target.value });
  };

  onUseEmailClicked = () => {
    let old = this.state.useEmail;
    this.setState({
      useEmail: !old,
      username: !old
        ? this.props.email
          ? this.props.email.toLowerCase()
          : ""
        : ""
    });
  };

  onUpdatePassword = e => {
    this.setState({ password: e.target.value });
  };

  onUpdateRepeatPassword = e => {
    this.setState({ repeatPassword: e.target.value });
  };

  onUpdateTermsAccepted = (name, value) => {
    this.setState({ [name]: value });
  };

  onSubmit = e => {
    e.preventDefault();
    const { setCredentials, selfSignup } = this.props;
    const { username, password, repeatPassword, termsAccepted } = this.state;

    const validUsername = validateText(username, 4);
    const validPassword = validateText(password, 6);
    const validRepeatPassword = repeatPassword === password;

    if (
      validUsername &&
      validPassword &&
      validRepeatPassword
      // (selfSignup || termsAccepted)
    ) {
      setCredentials(username, password);
    } else {
      this.setState({
        errorUsername: !validUsername,
        errorPassword: !validPassword,
        errorRepeatPassword: !validRepeatPassword
        // errorTerms: !termsAccepted
      });
    }
  };

  render() {
    let { selfSignup } = this.props;
    let {
      username,
      password,
      repeatPassword,
      errorUsername,
      errorPassword,
      errorRepeatPassword,
      termsAccepted,
      errorTerms
    } = this.state;

    return (
      <Form onSubmit={this.onSubmit}>
        <h3 className="text-center mb-3 font-sm-size">Set Your Credentials</h3>
        <p className="text-muted">
          Set username and password for your account.
        </p>

        <FormGroup className="d-flex flex-column">
          <div className="mb-1">
            User Name{" "}
            <div>
              <small>Only letters, numbers and underline are allowed</small>
            </div>
          </div>

          {this.props.email && (
            <div className="my-2 d-flex">
              <div>
                <Input
                  type="checkbox"
                  className="ml-0 mr-3"
                  style={{ position: "relative", marginTop: "0px" }}
                  checked={this.state.useEmail}
                  onChange={this.onUseEmailClicked}
                />
              </div>
              Use my email as username
            </div>
          )}

          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText
                className={classnames(errorUsername ? "is-invalid" : "")}
              >
                <i className="fa fa-user" />
              </InputGroupText>
            </InputGroupAddon>

            <Input
              className={classnames(errorUsername ? "is-invalid" : "")}
              value={username}
              onChange={this.onUpdateUsername}
              type="text"
              autoCapitalize="none"
            />
            <div className="invalid-feedback">Please set username.</div>
          </InputGroup>
        </FormGroup>

        <FormGroup className="d-flex flex-column">
          <div className="mb-1">
            Password{" "}
            <div>
              <small>
                Minimum 8 characters with 1 lowercase & 1 uppercase letter, 1
                number, and no whitespaces.
              </small>
            </div>
          </div>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText
                className={classnames(errorPassword ? "is-invalid" : "")}
              >
                <i className="fa fa-lock" />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              value={password}
              className={classnames(errorPassword ? "is-invalid" : "")}
              onChange={this.onUpdatePassword}
              type="password"
              autoCapitalize="none"
            />
            <div className="invalid-feedback">Please set valid password.</div>
          </InputGroup>
        </FormGroup>

        <FormGroup className="d-flex flex-column">
          <div className="mb-1">Confirm Password</div>

          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText
                className={classnames(errorPassword ? "is-invalid" : "")}
              >
                <i className="fa fa-lock" />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              value={repeatPassword}
              className={classnames(errorRepeatPassword ? "is-invalid" : "")}
              onChange={this.onUpdateRepeatPassword}
              type="password"
              autoCapitalize="none"
            />
            <div className="invalid-feedback">
              Repeated password must be same as password.
            </div>
          </InputGroup>
        </FormGroup>

        {/*{!selfSignup && (*/}
        {/*  <div>*/}
        {/*    <StepAcceptTerms*/}
        {/*      onUpdateData={this.onUpdateTermsAccepted}*/}
        {/*      facilityInfo={this.props.facilityInfo}*/}
        {/*      termsAccepted={termsAccepted}*/}
        {/*    />*/}
        {/*    {errorTerms && (*/}
        {/*      <p className="text-muted">*/}
        {/*        <span style={{ color: "red" }}>*/}
        {/*          Please accept privacy policy and terms and conditions.*/}
        {/*        </span>*/}
        {/*      </p>*/}
        {/*    )}*/}
        {/*  </div>*/}
        {/*)}*/}

        <Row>
          <Col xs="12">
            <div className="text-right">
              <Button color="primary" size="lg" className="px-4" block>
                Create Account
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    );
  }
}

export default UserOnboardingStepSetCredentials;
