import React from "react";
import {
  Button,
  Card,
  CardBody,
  CardGroup,
  Col,
  Container,
  Row
} from "reactstrap";
import RegionSelector from "../RegionSelector";

export default class SignupFacilitySelector extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    if (this.inputEl) {
      this.inputEl.focus();
    }
  }

  submitForm = e => {
    e.preventDefault();
    let { facilityCode } = this.state;
    if (facilityCode) {
      this.props.submitCode(facilityCode);
    }
  };

  render() {
    let { facilityCode } = this.state;
    let { facilityErrorMessage, facilityError } = this.props;
    return (
      <div className="app flex-column justify-content-center align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md="6" sm={12}>
              <div className="ob-logos mb-3 justify-content-center my-4">
                <div className="">
                  <img
                    alt="myPatientSpace"
                    src="/img/logo-symbol.png"
                    height={32}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </Container>

        <Container>
          <Row className="justify-content-center">
            <Col md="6" sm={12}>
              <CardGroup>
                <Card>
                  <CardBody>
                    <form
                      className="d-flex flex-column"
                      onSubmit={this.submitForm}
                    >
                      <h5 className="mt-3 text-center">
                        This Service is Invite-Only
                      </h5>

                      <div className="my-3" style={{ fontSize: "16px" }}>
                        To use you need to create an account. Start by entering
                        the Code given by your Health Care Provider.
                      </div>

                      {facilityError && (
                        <div>
                          <p className="text-muted">
                            <span style={{ color: "red" }}>
                              {facilityErrorMessage ===
                              "No facility information found for the code given"
                                ? "Invalid Code. Please try again."
                                : facilityErrorMessage}
                            </span>
                          </p>
                        </div>
                      )}

                      <div className="mb-3">
                        <label style={{ fontSize: "20px" }}>Region</label>

                        <RegionSelector
                          style={{
                            fontSize: "20px",
                            height: "42px",
                            border: "2px solid #e0e6e8"
                          }}
                        />
                      </div>

                      <div>
                        <label style={{ fontSize: "20px" }}>
                          Hospital Code
                        </label>

                        <input
                          style={{
                            height: "42px",
                            textAlign: "center",
                            fontSize: "20px",
                            letterSpacing: "3px",
                            borderWidth: "2px",
                            borderRadius: "4px"
                          }}
                          ref={attrEl => {
                            this.inputEl = attrEl;
                          }}
                          className="form-control"
                          type="text"
                          value={facilityCode}
                          onChange={e => {
                            this.setState({ facilityCode: e.target.value });
                          }}
                          autoComplete="nope"
                        />
                      </div>

                      <div className="d-flex mt-3">
                        <Button
                          size="lg"
                          color="primary"
                          className="px-4"
                          disabled={!facilityCode}
                          style={{
                            cursor: !facilityCode ? "default" : "pointer",
                            width: "100%"
                          }}
                        >
                          Continue
                        </Button>
                      </div>
                    </form>

                    <div className="mt-5" style={{ fontSize: "13px" }}>
                      <p>
                        Contact the Organization or Healthcare Provider who
                        invited you if you do not have this information. The
                        code would have been provided to you at the hospital, or
                        on a printed leaflet.
                      </p>
                      <p
                        style={{
                          fontWeight: 500,
                          backgroundColor: "#f1f1f1",
                          padding: "5px"
                        }}
                      >
                        If you've been sent an invite that you received via
                        Email or SMS text, please click the link there and
                        follow on-screen instructions to setup your account.
                      </p>
                      <p>
                        If you believe you've entered the code correctly but are
                        seeing an error, please contact{" "}
                        <a href="mailto:support@mypatientspace.com">
                          support@mypatientspace.com
                        </a>
                        .
                      </p>
                    </div>
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
