import React, { PureComponent } from "react";
import {
  Container,
  Row,
  Col,
  CardGroup,
  Card,
  CardBody,
  Button,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from "reactstrap";
import classnames from "classnames";
import MPSBarLoader from "../../components/MPSBarLoader";
import RegionSelector from "../RegionSelector";

const EVENT_KEY_DOWN = "keydown";
const EVENT_KEY_UP = "keyup";

class Login extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isCapsLockActive: false,
      user: "",
      pass: ""
    };
  }

  componentDidMount() {
    this.props.onResetLoading();
    let pass = document.getElementById("login-txt-pwd");
    pass.addEventListener(EVENT_KEY_DOWN, this.wasCapsLockActivated);
    pass.addEventListener(EVENT_KEY_UP, this.wasCapsLockDeactivated);
  }

  componentWillUnmount() {
    let pass = document.getElementById("login-txt-pwd");
    pass.removeEventListener(EVENT_KEY_DOWN, this.wasCapsLockActivated);
    pass.removeEventListener(EVENT_KEY_UP, this.wasCapsLockDeactivated);
  }

  wasCapsLockActivated = event => {
    if (
      event.getModifierState &&
      event.getModifierState("CapsLock") &&
      this.state.isCapsLockActive === false
    ) {
      this.setState({ isCapsLockActive: true });
    }
  };

  wasCapsLockDeactivated = event => {
    if (
      event.getModifierState &&
      !event.getModifierState("CapsLock") &&
      this.state.isCapsLockActive === true
    ) {
      this.setState({ isCapsLockActive: false });
    }
  };

  onLogin = e => {
    e.preventDefault();
    this.props.onLogin(this.state.user, this.state.pass);
  };

  onUpdateUser = e => {
    this.setState({ user: e.target.value });
  };

  onUpdatePass = e => {
    this.setState({ pass: e.target.value });
  };

  render() {
    let {
      loading,
      loginError,
      invited,
      facilityName,
      logoutMessage
    } = this.props;
    let disabled = loading;

    if (facilityName) {
      facilityName = facilityName.replace(/-/g, " ");
    }

    return (
      <div className="app flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md="8">
              <CardGroup>
                <Card className="p-4">
                  <div className="d-flex justify-content-center mb-4">
                    <img
                      alt="myPatientSpace"
                      src="/img/logo-symbol.png"
                      height={42}
                    />
                  </div>

                  <CardBody>
                    {logoutMessage && (
                      <p style={{ color: "#28623c" }}>{logoutMessage}</p>
                    )}

                    <div className="d-flex mb-3">
                      <h4>Login to your Account</h4>
                    </div>

                    {invited && (
                      <div style={{ color: "#28623c" }} className="mb-4">
                        {`Welcome back! You have just joined ${facilityName}. You already have an account with myPatientSpace.
                        Please login to access your account.`}
                        {/*<MPSButton*/}
                        {/*type="link"*/}
                        {/*className="mx-1"*/}
                        {/*onClick={() => {*/}
                        {/*this.props.history.push("/forgot-password");*/}
                        {/*}}*/}
                        {/*target="_blank"*/}
                        {/*>*/}
                        {/*here*/}
                        {/*</MPSButton>*/}
                        {/*to reset.*/}
                      </div>
                    )}

                    <form onSubmit={this.onLogin}>
                      {loginError && (
                        <p className="text-danger">
                          Error logging in. Please provide correct Username and
                          Password.
                        </p>
                      )}
                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText
                            className={classnames(
                              loginError ? "is-invalid" : ""
                            )}
                          >
                            <i className="fa fa-globe" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <RegionSelector loading={loading} error={loginError} />
                      </InputGroup>

                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText
                            className={classnames(
                              loginError ? "is-invalid" : ""
                            )}
                          >
                            <i className="fa fa-user" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          value={this.state.user}
                          onChange={this.onUpdateUser}
                          type="text"
                          autoCapitalize="none"
                          placeholder="Username"
                          disabled={disabled}
                          className={classnames(loginError ? "is-invalid" : "")}
                          id="login-txt-username"
                        />
                      </InputGroup>
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText
                            className={classnames(
                              loginError ? "is-invalid" : ""
                            )}
                          >
                            <i className="fa fa-lock" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          value={this.state.pass}
                          onChange={this.onUpdatePass}
                          type="password"
                          placeholder="Password"
                          disabled={disabled}
                          autoCapitalize="none"
                          className={classnames(loginError ? "is-invalid" : "")}
                          id="login-txt-pwd"
                        />
                      </InputGroup>

                      <div
                        className="mb-4 mt-1 text-info"
                        style={{ fontWeight: 500 }}
                      >
                        {this.state.isCapsLockActive && "Caps Lock On"}
                      </div>

                      <Row>
                        <Col xs="6" className="d-flex align-items-center">
                          {!loading && (
                            <Button
                              color="primary"
                              className="px-4"
                              disabled={disabled}
                              id="login-btn-submit"
                            >
                              Login
                            </Button>
                          )}
                          {loading && (
                            <div style={{ width: 50 }}>
                              <MPSBarLoader />
                            </div>
                          )}
                        </Col>
                        <Col xs="6" className="text-right">
                          <Button
                            id="login-link-fpwd"
                            color="link"
                            className="px-0"
                            onClick={e => {
                              e.preventDefault();
                              e.stopPropagation();
                              this.props.history.push("/forgot-password");
                            }}
                          >
                            Forgot password?
                          </Button>
                        </Col>
                      </Row>
                    </form>
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Login;
