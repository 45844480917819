import React, { PureComponent } from "react";
import { Button, Col, Form, Row } from "reactstrap";
import UserOnboardingChallengeInvite, {
  getOnboardingAttributeValue
} from "./UserOnboardingChallengeInvite";
import StepAcceptTerms from "../Onboarding/StepAcceptTerms";
import { validatePhoneNumber } from "../../utils/phone";

class UserOnboardingStepInvite extends PureComponent {
  constructor(props) {
    super(props);
    let cf = this.props.challengeFields || [];
    cf.forEach(attr => {
      attr.mandatory = true;
    });
    this.state = { challengeFields: cf || [] };
  }

  onUpdateData = (name, value) => {
    this.setState({ [name]: value });
  };

  validate = () => {
    this.setState({ errorTerms: false, fieldErrors: false });
    const { challengeFields: fields, termsAccepted } = this.state;

    let formValid = true;
    for (let i = 0; i < fields.length; i++) {
      let attribute = { ...fields[i] };
      let value = getOnboardingAttributeValue(attribute);
      if (attribute.error) {
        formValid = false;
      } else {
        if (!value) {
          attribute.error = attribute.title + " is required.";
          formValid = false;
        }
      }

      if (
        attribute.value &&
        (attribute.dataField === "PatientContactPhone" ||
          attribute.dataField === "PatientContactTel")
      ) {
        let newAV = JSON.parse(JSON.stringify(attribute.value));
        let phone = newAV.valueContactPoints[0].value;
        let cc = newAV.valueContactPoints[0].countryCode;

        if (
          !phone ||
          (phone && (!cc || !validatePhoneNumber(cc + " " + phone)))
        ) {
          attribute.error = "Please provide a valid phone with country code";
          formValid = false;
        }
      }
      fields[i] = { ...attribute };
    }

    this.setState({ challengeFields: [...fields] });

    // if (this.props.showTerms && !termsAccepted) {
    if (!this.props.consented && !termsAccepted) {
      this.setState({ errorTerms: true });
      formValid = false;
    }

    return formValid;
  };

  onSubmit = e => {
    e.preventDefault();
    let valid = this.validate();
    if (!valid) {
      return;
    }
    this.props.confirmIdentity(this.state.challengeFields);
  };

  onUpdateTermsAccepted = (name, value) => {
    this.setState({ [name]: value });
  };

  render() {
    let {
      challengeFields,
      termsAccepted,
      errorTerms,
      fieldErrors
    } = this.state;
    let {
      showTerms,
      onboardingType,
      facilityName,
      consented,
      facilityInfo
    } = this.props;

    return (
      <Form onSubmit={this.onSubmit} noValidate>
        <h3 className="text-center mb-3 font-sm-size">Confirm Identity</h3>

        {onboardingType !== "signup" && (
          <p className="text-muted">
            To accept your invitation, please confirm your identity by providing
            the following information.
            {/*You’ve been invited to join myPatientSpace. If you accept this*/}
            {/*invitation, we just need to confirm your identity with additional*/}
            {/*details.*/}
          </p>
        )}

        {onboardingType === "signup" && (
          <p className="text-muted">
            To Sign Up to myPatientSpace service, please enter the following
            details to confirm your identity.
          </p>
        )}

        {fieldErrors && (
          <p className="text-muted">
            <span style={{ color: "red" }}>{fieldErrors}</span>
          </p>
        )}

        <UserOnboardingChallengeInvite
          onUpdateData={this.onUpdateData}
          challengeFields={challengeFields}
          error={this.props.error}
          supportEmail={this.props.supportEmail}
        />

        {!consented && (
          <div>
            <StepAcceptTerms
              onUpdateData={this.onUpdateTermsAccepted}
              facilityInfo={this.props.facilityInfo}
              termsAccepted={termsAccepted}
            />
            {errorTerms && (
              <p className="text-muted">
                <span style={{ color: "red" }}>
                  Please accept privacy policy and terms and conditions.
                </span>
              </p>
            )}
          </div>
        )}

        <Row>
          <Col xs="12">
            <div className="text-right">
              <Button size="lg" color="primary" className="px-4" block>
                {onboardingType === "signup" ? "Continue" : "Accept Invite"}
              </Button>
            </div>
          </Col>
        </Row>

        {onboardingType === "signup" && (
          <p className="text-muted mt-3">
            {`Note: ${
              facilityInfo.helpText
                ? facilityInfo.helpText
                : `If you don't have these details, please contact ${facilityName} to get them.`
            }`}
          </p>
        )}
      </Form>
    );
  }
}

export default UserOnboardingStepInvite;
