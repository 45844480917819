import React, { PureComponent } from "react";
import Mandatory from "./Mandatory";
import { getDataHolder, getValueWhenInFilter } from "../Utils";
import AttributeInfo from "./AttributeInfo";
import Select from "react-select";

export default class AttributeWithOptions extends PureComponent {
  render() {
    let {
      placeholder,
      attribute,
      index,
      changeField,
      handleOnBlur
    } = this.props;
    let description = attribute.description || attribute.title;
    let type = attribute.type || attribute.attributeType;
    let dh = getDataHolder(type);

    return (
      <div
        key={index}
        className="col-12 my-1 pb-2"
        style={{ backgroundColor: "#fff" }}
        ref={attrEl => {
          if (this.props.setAttributeRef) {
            this.props.setAttributeRef(attribute.name, attrEl);
          }
        }}
      >
        {!placeholder && (
          <label style={{ fontWeight: 500 }}>
            {description}
            {attribute.mandatory && <sup style={{ color: "red" }}>*</sup>}
          </label>
        )}
        <div className="d-flex flex-column">
          <Select
            placeholder={placeholder ? description : ""}
            style={{
              border: attribute.error
                ? "1px solid #f86c6b"
                : "1px solid #e0e6e8"
            }}
            name="form-field-name"
            value={getValueWhenInFilter(attribute)}
            onChange={option => {
              let av = null;
              if (type === "STRING") {
                av = { valueString: option ? option.value : null };
              } else {
                av = {
                  valueCode: {
                    code: option ? option.value : null,
                    display: option ? option.label : null
                  }
                };
              }
              attribute.value = av;
              changeField(index, attribute);
            }}
            options={attribute.options.map(option => {
              if (type === "STRING") {
                return {
                  value: option.valueString,
                  label: option.valueString
                };
              } else {
                let val = option[dh].value || option[dh].code;
                let label = option[dh].value || option[dh].display;
                return { value: val, label: label };
              }
            })}
            onBlur={() => {
              handleOnBlur(index, attribute);
            }}
          />
          {placeholder && <Mandatory mandatory={attribute.mandatory} />}
          {attribute.error && (
            <div className="invalid-feedback d-block">{attribute.error}</div>
          )}
          <AttributeInfo info={attribute.info} />
        </div>
      </div>
    );
  }
}
