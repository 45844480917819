import React, { PureComponent } from "react";
import Select from "react-select";
import classnames from "classnames";
import userService from "../services/userService";
import StorageService from "../services/storageService";
import { API_URL_KEY } from "../utils/http";
import { getServerRegions } from "../config";

class RegionSelector extends PureComponent {
  constructor(props) {
    super(props);
    let regionUrl = StorageService.getFromLocalStorage(API_URL_KEY);
    this.state = {
      region: regionUrl
    };
  }

  onUpdateRegion = option => {
    this.setState({ region: option.url });
    userService.setServerURL(option.url);
  };

  render() {
    let { loading, error, style } = this.props;
    return (
      <Select
        value={this.state.region}
        onChange={this.onUpdateRegion}
        options={getServerRegions()}
        valueKey="url"
        labelKey="region"
        placeholder="Region"
        disabled={loading}
        className={classnames(error ? "is-invalid" : "")}
        id="login-select-region"
        clearable={false}
        style={style || {}}
      />
    );
  }
}

export default RegionSelector;
