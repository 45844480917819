import { http } from "../utils/http";
import { API_ENDPOINTS, PAGE_SIZE } from "../config";
import { store } from "../State/store/configureStore";
import { ROLE_MPS_ADMIN } from "../security/roles";

class StatusService {
  add(role, patientId, data) {
    return http.request(API_ENDPOINTS.addStatus(role, patientId), "post", data);
  }

  addFromSurvey(patientId, surveyId, data) {
    return http.request(
      API_ENDPOINTS.addStatusFromSurvey(patientId, surveyId),
      "post",
      data
    );
  }

  addLibraryStatus(patientId, libraryId, data) {
    return http.request(
      API_ENDPOINTS.addLibraryStatus(patientId, libraryId),
      "post",
      data
    );
  }

  addConsentStatus(patientId, consentId, data) {
    return http.request(
      API_ENDPOINTS.addConsentDocumentStatus(patientId, consentId),
      "post",
      data
    );
  }

  load(role, patientId, page) {
    return http
      .request(
        API_ENDPOINTS.loadStatuses(role, patientId) + `&page=${page}&size=30`
      )
      .then(data => {
        if (data && data._embedded) {
          return {
            data:
              page <= data.page.totalPages ? data._embedded.statusMessages : [],
            loadMore: data.page.totalPages > page + 1
          };
        } else {
          return {
            data: [],
            loadMore: false
          };
        }
      });
  }

  loadStaffNotes(patientId, type, pageNumber = 0) {
    let api = API_ENDPOINTS.loadStaffNotes(patientId);
    if (type === "PERSONAL_NOTES") {
      api = API_ENDPOINTS.loadPersonalNotes(patientId);
    } else if (type === "INTERACTIONS") {
      api = API_ENDPOINTS.loadPatientInteractions(patientId);
    }

    return http.request(api + `&page=${pageNumber}&size=20`).then(data => {
      if (data && data._embedded) {
        return {
          statusMessages: data._embedded.statusMessages || [],
          pageInfo: data.page
        };
      }
      return {
        statusMessages: [],
        pageInfo: { number: -1 }
      };
    });
  }

  addTemplate(data) {
    return http.request(API_ENDPOINTS.addStatusTemplate(), "post", data);
  }

  loadTemplate(templateId) {
    return http.request(API_ENDPOINTS.loadStatusTemplates() + `/${templateId}`);
  }

  editTemplate(templateId, data) {
    return http.request(
      API_ENDPOINTS.loadStatusTemplates() + `/${templateId}`,
      "patch",
      data
    );
  }

  loadTemplates(page, all, patientTypeId) {
    let pageSize = PAGE_SIZE;
    if (all) {
      pageSize = 500;
    }
    let api =
      API_ENDPOINTS.loadStatusTemplates() + `?page=${page}&size=${pageSize}`;
    if (patientTypeId) {
      api += `&pTypeId=${patientTypeId}`;
    }

    return http.request(api).then(data => {
      if (data && data._embedded) {
        return {
          list:
            page <= data.page.totalPages ? data._embedded.messageTemplates : [],
          loadMore: data.page.totalPages > page + 1
        };
      } else {
        return {
          list: [],
          loadMore: false
        };
      }
    });
  }

  loadTemplateParameters(type = "STATUS_MESSAGE") {
    let role = store.getState().user.role;
    let api = API_ENDPOINTS.loadTemplateParameters(type);
    if (role === ROLE_MPS_ADMIN) {
      api = API_ENDPOINTS.mpsAdminTemplateParameters;
    }
    return http.request(api).then(data => {
      return data && data._embedded ? data._embedded.valueParameters : [];
    });
  }

  deleteTemplate(templateId) {
    return http.request(API_ENDPOINTS.messageTemplate(templateId), "delete");
  }

  deleteStatus = (patientId, statusId) => {
    return http.request(
      API_ENDPOINTS.statusById(patientId, statusId),
      "delete"
    );
  };

  editStatus = (patientId, statusId, data) => {
    return http.request(
      API_ENDPOINTS.statusById(patientId, statusId),
      "patch",
      data
    );
  };

  markAsRead(messageId) {
    return http.request(API_ENDPOINTS.markStatusAsRead, "post", [messageId]);
  }

  sendTaskNotesToStaffNotes(role, task) {
    let patientId = task.taskFor.patientId;

    let assigneeName = "";
    if (task.taskAssignee) {
      if (task.taskAssignee.referenceType === "CARE_TEAM") {
        assigneeName = task.taskAssignee.display;
      } else {
        assigneeName = task.taskAssignee.displayHumanName
          ? task.taskAssignee.displayHumanName.text
          : "";
      }
    }

    let submitData = {
      title: "Notes from Task: " + task.title,
      type: "STATUS_UPDATE",
      senderCategory: "PATIENT",
      visibility: "STAFF",
      detailMessage:
        '<p class="md-block-unstyled"><em class="md-inline-italic">Assigned To:</em> ' +
        assigneeName +
        ' | <em class="md-inline-italic">Created On:</em> ' +
        new Date(task.createdOn).toLocaleString() +
        '</p><p class="md-block-unstyled"><em class="md-inline-italic">Notes:</em> ' +
        task.taskNote +
        "</p>"
    };

    return http.request(
      API_ENDPOINTS.addStatus(role, patientId),
      "post",
      submitData
    );
  }
}

const statusService = new StatusService();

export default statusService;
