import React, { PureComponent } from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import classnames from "classnames";
import Mandatory from "./Mandatory";
import AttributeInfo from "./AttributeInfo";
import DateField from "../../../../components/Date/DateField";
import ModalPopup from "../../../../components/ModalPopup";

function getMinValue(attribute) {
  if (
    attribute.dataField === "PatientProcedure" ||
    attribute.dataField === "PatientProcedureDate"
  ) {
    return new Date();
  }
  return null;
}
function getMaxValue(attribute) {
  if (attribute.dataField === "PatientDOB") {
    return new Date();
  }
  return null;
}

export default class DateAttribute extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  showDOBHelp = () => {
    this.setState({ help: true });
  };

  closeDOBHelp = () => {
    this.setState({ help: false });
  };

  render() {
    let {
      placeholder,
      attribute,
      index,
      handleOnBlur,
      changeField
    } = this.props;
    let description = attribute.description || attribute.title;
    let value = attribute.value ? attribute.value.valueString : null;
    // if (attribute.dataField === "PatientDOB") {
    //   if (attribute.value) {
    //     value = attribute.value.valueString;
    //   }
    // } else {
    //   value = getValueWhenInFilter(attribute);
    // }
    return (
      <div
        key={index}
        className="col-12 my-1 pb-2"
        style={{ backgroundColor: "#fff" }}
        ref={attrEl => {
          if (this.props.setAttributeRef) {
            this.props.setAttributeRef(attribute.name, attrEl);
          }
        }}
      >
        <div className="d-flex flex-column">
          {!placeholder && (
            <div className="d-flex align-items-center justify-content-between">
              <label style={{ fontWeight: 500 }}>
                {description}
                {attribute.mandatory && <sup style={{ color: "red" }}>*</sup>}
              </label>

              {/*<ReactCSSTransitionGroup*/}
              {/*  transitionName="example"*/}
              {/*  transitionEnterTimeout={1000}*/}
              {/*  transitionLeaveTimeout={500}*/}
              {/*>*/}
              {/*  {attribute.dataField === "PatientDOB" && this.props.error && (*/}
              {/*    <div className="mb-2">*/}
              {/*      <a*/}
              {/*        onClick={this.showDOBHelp}*/}
              {/*        style={{*/}
              {/*          padding: "0.1rem 1.5rem",*/}
              {/*          backgroundColor: "#f8cb00",*/}
              {/*          color: "#e83e8c",*/}
              {/*          fontWeight: 500*/}
              {/*        }}*/}
              {/*        className="btn btn-secondary"*/}
              {/*      >*/}
              {/*        Please Note!*/}
              {/*      </a>*/}
              {/*    </div>*/}
              {/*  )}*/}
              {/*</ReactCSSTransitionGroup>*/}
            </div>
          )}

          {placeholder && (
            <label style={{ marginBottom: 0 }} className="text-muted">
              {description}
            </label>
          )}
        </div>
        <div className="d-flex flex-column">
          <DateField
            tabIndex={index}
            style={{ height: "37px", borderRadius: "4px" }}
            className={classnames(
              attribute.error ? "is-invalid form-control" : "form-control"
            )}
            error={attribute.error}
            value={value}
            onChange={val => {
              // if (attribute.dataField === "PatientDOB") {
              attribute.value = { valueString: val };
              // } else {
              //   attribute.value = getValueFieldNameForFilter(attribute, val);
              // }
              changeField(index, attribute);
            }}
            min={getMinValue(attribute)}
            max={getMaxValue(attribute)}
            onBlur={() => {
              handleOnBlur(index, attribute);
            }}
            clearable={!!value}
            placeholder={description}
            mandatory={attribute.mandatory}
          />

          {/*<input*/}
          {/*  style={{ height: "37px" }}*/}
          {/*  type="date"*/}
          {/*  onBlur={() => {*/}
          {/*    handleOnBlur(index, attribute);*/}
          {/*  }}*/}
          {/*  value={getValueWhenInFilter(attribute)}*/}
          {/*  onChange={e => {*/}
          {/*    attribute.value = getValueFieldNameForFilter(*/}
          {/*      attribute,*/}
          {/*      e.target.value*/}
          {/*    );*/}
          {/*    changeField(index, attribute);*/}
          {/*  }}*/}
          {/*  className={classnames(*/}
          {/*    attribute.error ? "is-invalid form-control" : "form-control"*/}
          {/*  )}*/}
          {/*  min={getMinValue(attribute)}*/}
          {/*  max={getMaxValue(attribute)}*/}
          {/*/>*/}
          {/*{value && (*/}
          {/*  <a*/}
          {/*    className="close ml-1 d-flex align-items-center"*/}
          {/*    onClick={() => {*/}
          {/*      attribute.value = null;*/}
          {/*      changeField(index, attribute);*/}
          {/*    }}*/}
          {/*    style={{*/}
          {/*      cursor: "pointer",*/}
          {/*      position: "absolute",*/}
          {/*      right: 0,*/}
          {/*      top: 25*/}
          {/*    }}*/}
          {/*  >*/}
          {/*    <span aria-hidden="true">×</span>*/}
          {/*  </a>*/}
          {/*)}*/}
          {placeholder && <Mandatory mandatory={attribute.mandatory} />}
          {attribute.error && (
            <div className="invalid-feedback d-block">{attribute.error}</div>
          )}
          <AttributeInfo info={attribute.info} />
        </div>
        {/*{this.state.help && (*/}
        {/*  <ModalPopup title={"Date Of Birth"} onClose={this.closeDOBHelp}>*/}
        {/*    <div>*/}
        {/*      <p>*/}
        {/*        If you are entering your Date of Birth correctly but you are*/}
        {/*        seeing a ‘Verification Failed’ error:*/}
        {/*      </p>*/}
        {/*      <p>*/}
        {/*        We are aware of an issue with Date of Birth having been entered*/}
        {/*        wrong into the system - it is shifted by a day - hence failing*/}
        {/*        the validation.  Please contact your provider (ResMed PEI) and*/}
        {/*        let them know so they can correct it.*/}
        {/*      </p>*/}
        {/*      <p>*/}
        {/*        Meanwhile, you can continue to setup your account, by entering a*/}
        {/*        date one day BEFORE your date of birth (for example: if your DOB*/}
        {/*        is 19 Nov 1965, please enter 18 Nov 1965).*/}
        {/*      </p>*/}
        {/*      <p>*/}
        {/*        {`Feel free to contact us at ${this.props.supportEmail} - with*/}
        {/*            your email or phone number, and we can help you get set up.`}*/}
        {/*      </p>*/}
        {/*      <p> We apologise for the inconvenience.</p>*/}
        {/*    </div>*/}
        {/*  </ModalPopup>*/}
        {/*)}*/}
      </div>
    );
  }
}
