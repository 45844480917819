import React, { PureComponent } from "react";
import { getValueFieldNameForFilter, getValueWhenInFilter } from "../Utils";
import Mandatory from "./Mandatory";
import AttributeInfo from "./AttributeInfo";
import Select from "react-select";

export default class OptionsObservation extends PureComponent {
  render() {
    let {
      placeholder,
      attribute,
      index,
      changeField,
      handleOnBlur
    } = this.props;
    let description = attribute.description || attribute.title;
    return (
      <div
        key={index}
        className="col-12 my-1 pb-2"
        style={{ backgroundColor: "#fff" }}
        ref={attrEl => {
          if (this.props.setAttributeRef) {
            this.props.setAttributeRef(attribute.name, attrEl);
          }
        }}
      >
        {!placeholder && (
          <label style={{ fontWeight: 500 }}>
            {description}
            {attribute.mandatory && <sup style={{ color: "red" }}>*</sup>}
          </label>
        )}
        <div className="d-flex flex-column">
          <Select
            placeholder={placeholder ? description : ""}
            style={{
              border: attribute.error
                ? "1px solid #f86c6b"
                : "1px solid #e0e6e8"
            }}
            name="form-field-name"
            value={getValueWhenInFilter(attribute)}
            onChange={option => {
              attribute.value = getValueFieldNameForFilter(
                attribute,
                option ? option.value : null
              );
              changeField(index, attribute);
            }}
            valueKey="valueSelect"
            options={attribute.options.map(option => {
              let val = option.valueObservation.value.valueString;
              return {
                valueSelect: val,
                value: option.valueObservation,
                label: val
              };
            })}
            onBlur={() => {
              handleOnBlur(index, attribute);
            }}
          />
          {placeholder && <Mandatory mandatory={attribute.mandatory} />}
          {attribute.error && (
            <div className="invalid-feedback d-block">{attribute.error}</div>
          )}
          <AttributeInfo info={attribute.info} />
        </div>
      </div>
    );
  }
}
