import React, { PureComponent } from "react";
import { Card, CardBody, CardGroup, Col, Container, Row } from "reactstrap";
import { Link } from "react-router-dom";

class UserOboardingInviteError extends PureComponent {
  render() {
    return (
      <div className="app flex-column justify-content-center align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md="8">
              <div className="ob-logos my-4">
                <div className="p-3">
                  <img
                    alt="myPatientSpace"
                    src="/img/mps-logo.png"
                    height={32}
                  />
                </div>
              </div>

              <CardGroup>
                <Card className="p-md-4 p-sm-3">
                  <CardBody>
                    <div>
                      {this.props.errorCode === "mps.user.already.onboarded" ? (
                        <div>
                          <h5
                            className="text-muted text-center mb-3PatientStatsBox"
                            style={{ marginBottom: 0 }}
                          >
                            <span style={{ color: "red" }}>
                              You have already accepted the invitation and your
                              account is active.{" "}
                            </span>
                          </h5>

                          <div className="mt-3">
                            Have you forgotten your username and password? No
                            problem, we will send you a link to reset password,
                            to your{" "}
                            {this.props.linkType === "email"
                              ? " email "
                              : " phone "}{" "}
                            together with your username. Simply enter your{" "}
                            {this.props.linkType === "email"
                              ? " email address "
                              : " phone number "}
                            <Link
                              to={`/forgot-password?cp=${this.props.linkType}`}
                            >
                              here
                            </Link>
                            .
                          </div>
                        </div>
                      ) : (
                        <h5
                          className="text-muted text-center"
                          style={{ marginBottom: 0 }}
                        >
                          <span style={{ color: "red" }}>
                            {this.props.message}
                          </span>
                        </h5>
                      )}

                      {this.props.onboardingType === "confirm" ? (
                        <div className="mt-3">
                          <p>
                            You are seeing this page because you attempted to
                            Activate your Account from an email or SMS. It
                            appears you may have done this already at least
                            once. If so, your account is active. Now, you just
                            need to download, install the myPatientSpace App,
                            and login using the credentials you setup.
                          </p>
                          <p>
                            If you don't remember your username or password, you
                            can reset your password{" "}
                            <Link to="/forgot-password">here</Link>, by entering
                            the email or phone number you used when signing up
                            (we will send you your username).
                          </p>
                          <p>
                            Please reach out to us on{" "}
                            <b>support@mypatientspace.com</b> or{" "}
                            <b>+353 1 267 6625</b> if you continue to have
                            problems signing up.
                          </p>
                        </div>
                      ) : (
                        this.props.errorCode !==
                          "mps.user.already.onboarded" && (
                          <p className="mt-3">
                            Please reach out to us on{" "}
                            <b>support@mypatientspace.com</b> or{" "}
                            <b>+353 1 267 6625</b> if you continue to have
                            problems signing up, or contact your provider to
                            request a new invitation.
                          </p>
                        )
                      )}
                    </div>
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default UserOboardingInviteError;
