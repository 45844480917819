import Messages from "../../../Shared/Messages";
import React from "react";
import MPSCalendar from "../../../components/Calendar";
import moment from "moment";
import { formatSlotDate } from "../../../utils/utils";
import MPSBarLoader from "../../../components/MPSBarLoader";
import { Button } from "reactstrap";
import ModalPopup from "../../../components/ModalPopup";
import I18nProvider from "../../../services/I18nProvider";
import NewAppointmentConfirmation from "./NewAppointmentConfirmation";
const intl = I18nProvider.getIntlProvider();

function getStaffName(actors) {
  return actors
    .filter(a => a.referenceType === "STAFF")
    .map(a => (a.displayHumanName ? a.displayHumanName.text : ""))
    .join(", ");
}

function titleAccessor(e) {
  // return `${moment(e.start).format("HH:mm")} - ${moment(e.end).format(
  //   "HH:mm"
  // )}/${e.slotStatus.toLowerCase()}`;
  return "";
}

function dayTitleAccessor(e) {
  // return `${e.slotStatus}`;

  let actors = e.actors || [];
  return getStaffName(actors);
}

class NewAppointmentCalendar extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onSelectSlot = slot => {
    this.setState({ selectedSlot: slot });
  };

  slotStyleGetter = (event, start, end, isSelected) => {
    let backgroundColor = "#" + event.hexColor;
    let isFilled =
      this.state.selectedSlot && this.state.selectedSlot.id === event.id;
    let style = {
      borderColor: isFilled ? "#265985" : "#468153",
      backgroundColor: isFilled ? "#265985" : "#468153",
      color: "#fff",
      cursor: isFilled ? "default" : "pointer"
    };
    return {
      style: style
    };
  };

  submitSlot = () => {
    this.props.createAppointment(this.state.selectedSlot);
    this.closeConfirmation();
  };

  showConfirmation = () => {
    this.setState({ confirmation: true });
  };

  closeConfirmation = () => {
    this.setState({ confirmation: false });
  };

  render() {
    let { selectedSlot, confirmation } = this.state;

    let { loading, schedule = {}, submitting } = this.props;

    if (loading) {
      return (
        <div style={{ margin: "100px auto", width: 100 }}>
          <MPSBarLoader />
        </div>
      );
    }

    let slots = schedule.slots || [];
    slots = JSON.parse(JSON.stringify(slots));
    slots = slots
      .filter(s => s.slotStatus === "FREE")
      .filter(s => moment().isBefore(moment(s.start)));
    slots = slots.map(slot => {
      return { ...slot, start: new Date(slot.start), end: new Date(slot.end) };
    });

    return (
      <div className="app my-4">
        <div className="container">
          <div className="d-flex justify-content-between my-2">
            <div className="ob-logos">
              <div>
                <img alt="myPatientSpace" src="/img/mps-logo.png" height={24} />
              </div>
            </div>
          </div>

          <h5 className="mb-2 font-lg text-center">
            Book Appointment - {schedule.name}
          </h5>

          {!selectedSlot && (
            <div className="d-flex justify-content-center mb-2">
              <div className="font-lg">Select a slot to continue booking</div>
            </div>
          )}

          <Actions
            selectedSlot={selectedSlot}
            submitting={submitting}
            showConfirmation={this.showConfirmation}
          />

          <div>
            <div style={{ height: "500px" }}>
              <MPSCalendar
                currentView="WORK_WEEK"
                agendaLength={14}
                onSelectEvent={this.onSelectSlot}
                events={slots}
                eventTitleAccessor={titleAccessor}
                eventTitleDayAccessor={dayTitleAccessor}
                selectable={false}
                selectedEvent={selectedSlot}
                eventStyleGetter={this.slotStyleGetter}
                scrollToTime={new Date()}
                views={["month", "week", "work_week", "day"]}
              />
            </div>
          </div>

          <Actions
            selectedSlot={selectedSlot}
            submitting={submitting}
            showConfirmation={this.showConfirmation}
          />
        </div>

        {confirmation && (
          <ModalPopup
            onClose={this.closeConfirmation}
            title={intl.formatMessage(Messages.confirm_appointment)}
          >
            <NewAppointmentConfirmation
              dateTime={formatSlotDate(
                this.state.selectedSlot.start,
                this.state.selectedSlot.end
              )}
              doAction={this.submitSlot}
              cancel={this.closeConfirmation}
            />
          </ModalPopup>
        )}

        {/*{selectedSlot && (*/}
        {/*  <ModalPopup*/}
        {/*    title={"New Appointment"}*/}
        {/*    onClose={this.hideAppointmentModal}*/}
        {/*  >*/}
        {/*    <AppointmentForm*/}
        {/*      slot={selectedSlot}*/}
        {/*      submitting={this.props.submitting}*/}
        {/*      submit={this.createAppointment}*/}
        {/*    />*/}
        {/*  </ModalPopup>*/}
        {/*)}*/}
      </div>
    );
  }
}

function Actions({ selectedSlot, submitting, showConfirmation }) {
  if (!selectedSlot) {
    return null;
  }
  return (
    <div className="d-flex justify-content-between align-items-center my-2">
      {selectedSlot && (
        <div className="text-center" style={{ fontWeight: 500 }}>
          {`You have selected slot ${formatSlotDate(
            selectedSlot.start,
            selectedSlot.end
          )} for appointment with ${getStaffName(selectedSlot.actors)}`}
        </div>
      )}

      {selectedSlot && (
        <div>
          {!submitting && (
            <Button color="primary" size="md" onClick={showConfirmation}>
              Book Appointment
            </Button>
          )}

          {submitting && (
            <div className="d-flex align-items-center" style={{ width: 100 }}>
              <MPSBarLoader />
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default NewAppointmentCalendar;
