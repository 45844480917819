import "regenerator-runtime/runtime";

// Redux Store Configuration
import { applyMiddleware, createStore } from "redux";
import { persistReducer, persistStore } from "redux-persist";

import { composeWithDevTools } from "remote-redux-devtools";
import createSagaMiddleware from "redux-saga";
import rootReducer from "../reducers/index";
import rootSaga from "../sagas/index";
import localStorage from "redux-persist/lib/storage";
import sessionStorage from "redux-persist/lib/storage/session";
import { parseURLQueryParams } from "../../utils/utils";
import storageService from "../../services/storageService";

export const STORAGE_TYPE = "STORAGE_TYPE";
// create the saga middleware
const sagaMiddleware = createSagaMiddleware();

const composeEnhancers = composeWithDevTools({ suppressConnectErrors: false });
const middleware = applyMiddleware(sagaMiddleware);

let query = parseURLQueryParams(window.location.search);
let storageType =
  query && query.store
    ? query.store
    : storageService.getFromLocalStorage(STORAGE_TYPE);
let storage = storageType === "local" ? localStorage : sessionStorage;
storageService.addToLocalStorage(STORAGE_TYPE, storageType || null);

const persistConfig = {
  key: "root",
  blacklist: [
    "members",
    "organizations",
    "organizationAdmins",
    "staff",
    "statuses",
    "patientTypes",
    "contentLibrary",
    "toaster",
    "uploadProgress",
    "masterData"
  ],
  storage
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
const initialState = {};

export const store = createStore(
  persistedReducer,
  initialState,
  composeEnhancers(middleware)
);

sagaMiddleware.run(rootSaga);

export const persistor = persistStore(store);
// persistor.purge();
