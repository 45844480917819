import React from "react";
import { getMobileOperatingSystem } from "../../Common/Onboarding/Utils";
import Select from "react-select";

const currentYear = new Date().getFullYear();
const getFromYear = min => {
  if (min) {
    return new Date(min.getFullYear(), min.getMonth()).getFullYear();
  }
  return new Date(1900, 0).getFullYear();
};
const getToYear = max => {
  if (max) {
    return new Date(max.getFullYear(), max.getMonth()).getFullYear();
  }
  return new Date(currentYear + 50, 11).getFullYear();
};

function getDates() {
  let dates = [];
  for (let i = 1; i <= 31; i++) {
    dates.push({ label: i, value: i < 10 ? `0${i}` : `${i}` });
  }
  return dates;
}

function getMonths() {
  return [
    { label: "Jan", value: "01" },
    { label: "Feb", value: "02" },
    { label: "Mar", value: "03" },
    { label: "Apr", value: "04" },
    { label: "May", value: "05" },
    { label: "Jun", value: "06" },
    { label: "Jul", value: "07" },
    { label: "Aug", value: "08" },
    { label: "Sep", value: "09" },
    { label: "Oct", value: "10" },
    { label: "Nov", value: "11" },
    { label: "Dec", value: "12" }
  ];
}

function getYears(from, to) {
  let years = [];
  for (let i = from; i <= to; i++) {
    years.push({ value: i, label: i });
  }

  return years;
}

export default class DateField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onChangeField = (name, value) => {
    if (!value) {
      this.setState({ [name]: null });
      this.props.onChange(null);
      return;
    }
    this.setDate(name, value.value);
  };

  setDate = (name, value) => {
    let cloned = { ...this.state };
    cloned[name] = value;
    let { day, month, year } = cloned;
    if (day && month && year) {
      // let d = new Date(
      //   year,
      //   parseInt(month, 10) - 1,
      //   parseInt(day, 10),
      //   12,
      //   0,
      //   0,
      //   0
      // );
      this.setState({ [name]: value });
      this.props.onChange(
        `${parseInt(month, 10)}/${parseInt(day, 10)}/${year}`
      ); //"MM/dd/yyyy"

      // if (!isNaN(d)) {
      //   this.setState({
      //     day: d.getDate() < 10 ? `0${d.getDate()}` : `${d.getDate()}`,
      //     month:
      //       d.getMonth() + 1 < 10
      //         ? `0${d.getMonth() + 1}`
      //         : `${d.getMonth() + 1}`,
      //     year: d.getFullYear()
      //   });
      //   this.props.onChange(d);
      // } else {
      //   this.setState({ [name]: value });
      //   this.props.onChange(null);
      // }
    } else {
      this.setState({ [name]: value });
      this.props.onChange(null);
    }
  };

  onChangeDay = day => {
    this.onChangeField("day", day);
  };

  onChangeMonth = month => {
    this.onChangeField("month", month);
  };

  onChangeYear = year => {
    this.onChangeField("year", year);
  };

  onDayInputChange = inputValue => {
    console.group("Input Changed");
    console.log(inputValue);
    console.groupEnd();
  };

  allFocused = () => {
    return this.dayFocused && this.monthFocused && this.yearFocused;
  };

  render() {
    let { error, onBlur, tabIndex, min, max } = this.props;
    let { day, month, year, sError } = this.state;

    let hasError = error || sError;
    let style = {
      border: hasError ? "1px solid #f86c6b" : "1px solid rgb(224, 230, 232)",
      ...this.props.style
    };
    let mos = getMobileOperatingSystem();
    return (
      <React.Fragment>
        <div
          className="d-flex justify-content-between"
          tabIndex={tabIndex}
          onBlur={() => {
            console.log("on blur");
            if (this.allFocused()) {
              onBlur();
            }
          }}
        >
          <Select
            style={style}
            options={getDates()}
            value={day}
            className="date-select"
            placeholder="Day"
            onChange={this.onChangeDay}
            noResultsText="Invalid date"
            searchable={mos === "unknown"}
            // onInputChange={this.onDayInputChange}
            onFocus={e => {
              this.dayFocused = true;
            }}
            getStyles={() => ({
              multiValueLabel: {
                whiteSpace: "normal"
              }
            })}
          />
          <Select
            style={style}
            className="date-select mx-1"
            options={getMonths()}
            value={month}
            placeholder="Month"
            onChange={this.onChangeMonth}
            noResultsText="Invalid month"
            searchable={mos === "unknown"}
            onFocus={e => {
              this.monthFocused = true;
            }}
            getStyles={() => ({
              multiValueLabel: {
                whiteSpace: "normal"
              }
            })}
          />
          <Select
            style={style}
            options={getYears(getFromYear(min), getToYear(max))}
            value={year}
            className="date-select"
            placeholder="Year"
            onChange={this.onChangeYear}
            noResultsText="Invalid year"
            searchable={mos === "unknown"}
            onFocus={e => {
              this.yearFocused = true;
            }}
            getStyles={() => ({
              multiValueLabel: {
                whiteSpace: "normal"
              }
            })}
          />
        </div>
        {sError && (
          <div className="invalid-feedback d-block">
            Please enter valid date
          </div>
        )}
      </React.Fragment>
    );
  }
}
