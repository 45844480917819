import { http } from "../utils/http";
import { API_ENDPOINTS, PAGE_SIZE } from "../config";

class PatientTypesService {
  static addAttribute(profileId, data) {
    return http.request(
      API_ENDPOINTS.addProfileAttribute(profileId),
      "post",
      data
    );
  }

  static updateAttribute(profileId, attributeId, data) {
    return http.request(
      API_ENDPOINTS.updateProfileAttribute(profileId, attributeId),
      "patch",
      data
    );
  }

  static deleteAttribute(profileId, attributeId) {
    return http.request(
      API_ENDPOINTS.deleteProfileAttribute(profileId, attributeId),
      "delete"
    );
  }

  static addProfileMarkup(profileId, data) {
    return http.request(
      API_ENDPOINTS.addProfileMarkup(profileId),
      "post",
      data
    );
  }

  static add(name, description, provisionedPackage) {
    const data = {
      patientType: name,
      patientTypeText: description,
      provisionedPackage: provisionedPackage
    };
    return http
      .request(API_ENDPOINTS.addPatientType(), "post", data)
      .then(data => {
        return data.id;
      });
  }

  static edit(patientTypeId, form) {
    form.surveyNameForDashboard = form.surveyName;
    return http.request(
      API_ENDPOINTS.editPatientType(patientTypeId),
      "patch",
      form
    );
  }

  static loadTemplateData() {
    return http.request(API_ENDPOINTS.loadPatientTypesTemplate());
  }

  static loadSingle(id) {
    return http.request(API_ENDPOINTS.loadSinglePatientType(id));
  }

  static loadDocumentTopics(patientTypeId) {
    return http
      .request(API_ENDPOINTS.loadPatientTypeDocumentTopics(patientTypeId))
      .then(response => {
        return response ? (response.data ? response.data : []) : [];
      });
  }

  static load(page, all) {
    let endPoint =
      API_ENDPOINTS.loadPatientTypes() + `?page=${page}&size=${PAGE_SIZE}`;
    if (all) {
      endPoint = API_ENDPOINTS.loadPatientTypes() + `?page=0&size=1000`;
    }

    return http.request(endPoint).then(data => {
      if (data && data._embedded) {
        return {
          data: page <= data.page.totalPages ? data._embedded.patientTypes : [],
          loadMore: data.page.totalPages > page + 1
        };
      } else {
        return {
          data: [],
          loadMore: false
        };
      }
    });
  }

  static loadAll() {
    return http
      .request(API_ENDPOINTS.loadPatientTypes() + `?page=0&size=500`)
      .then(data => {
        return data && data._embedded ? data._embedded.patientTypes : [];
      });
  }

  static loadUIAttributes(patientTypeId, page, size = 20) {
    return http
      .request(
        API_ENDPOINTS.addProfileAttribute(patientTypeId) +
          `?page=${page}&size=${size}`
      )
      .then(response => {
        if (response && response._embedded) {
          return {
            attributes: response._embedded.uIAttributes || [],
            pageInfo: response.page
          };
        }
        return {
          attributes: [],
          pageInfo: { number: -1 }
        };
      });
  }

  static loadAllAttributes(patientTypeId) {
    return http
      .request(
        API_ENDPOINTS.addProfileAttribute(patientTypeId) + `?page=0&size=1000`
      )
      .then(data => {
        return data && data._embedded ? data._embedded.uIAttributes : [];
      });
  }

  static loadUiAttributeDataFields(mps) {
    let url = API_ENDPOINTS.uiAttributeDataFields;
    if (mps) {
      url = API_ENDPOINTS.uiAttributeDataFieldsMPS;
    }
    return http.request(url).then(data => {
      return data && data._embedded ? data._embedded.valueParameters : [];
    });
  }

  static deletePatientType(typeId) {
    return http.request(
      API_ENDPOINTS.addPatientType() + "/" + typeId,
      "delete"
    );
  }

  static copyAndCreatePackage(name, description, provisionedPackage) {
    const data = {
      patientType: name,
      patientTypeText: description,
      clonePatientTypeAndCreatePackage: true,
      packageToClone: provisionedPackage
    };
    return http
      .request(API_ENDPOINTS.addPatientType(), "post", data)
      .then(data => {
        return data.id;
      });
  }

  static exportPackage(patientTypeId) {
    return http
      .request(API_ENDPOINTS.exportPatientTypePackage(patientTypeId), "post")
      .then(data => {
        return JSON.stringify(data);
      });
  }

  static importPackage(data) {
    return http
      .request(API_ENDPOINTS.importPatientTypePackage, "post", data)
      .then(response => {
        return response.id;
      });
  }
}

const patientTypesService = PatientTypesService;

export default patientTypesService;
