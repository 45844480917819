import React from "react";
import { showLocalizedToaster } from "../../State/actions/toaster";
import { connect } from "react-redux";
import PatientAppServices from "../../services/PatientAppServices";
import NewAppointmentCalendar from "../components/Appointments/NewAppointmentCalendar";

class NewAppointmentContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.setState({ loading: true });
    PatientAppServices.getAppointmentSchedule(this.props.scheduleId)
      .then(schedule => {
        this.setState({ schedule, loading: false });
      })
      .catch(e => {
        this.setState({ loading: false });
      });
  }

  createAppointment = slot => {
    let { schedule } = this.state;
    let appointmentData = {
      start: slot.start,
      end: slot.end,
      templateId: this.props.templateId,
      participants: [
        ...slot.actors,
        {
          patientId: this.props.patientId,
          referenceType: "PATIENT",
          displayHumanName: this.props.loggedInUser
        },
        {
          referenceType: "MASTERDATA_KEY",
          masterDataValue: {
            code: schedule.appointmentLocationMDValue.code,
            display: schedule.appointmentLocationMDValue.display
          }
        }
      ]
    };

    this.setState({ submitting: true });
    PatientAppServices.createAppointment(appointmentData)
      .then(response => {
        this.setState({ created: true, submitting: false });
      })
      .catch(e => {
        this.setState({ submitting: false });
      });
  };

  render() {
    let { loading, schedule, submitting, created } = this.state;

    if (created) {
      return (
        <div
          className="d-flex justify-content-center align-items-center font-lg"
          style={{ height: "100vh" }}
        >
          Appointment is booked.
        </div>
      );
    }

    return (
      <NewAppointmentCalendar
        history={this.props.history}
        loading={loading}
        schedule={schedule}
        submitting={submitting}
        createAppointment={this.createAppointment}
        loggedInUser={this.props.loggedInUser}
      />
    );
  }
}

const mapDispatchToProps = {
  showToaster: showLocalizedToaster
};

const mapStateToProps = (state, ownProps) => {
  return {
    scheduleId: ownProps.match.params.scheduleId,
    loggedInUser: state.user.data ? state.user.data.loggedInUser : {},
    userRole: state.user.data ? state.user.data.role : {},
    appointmentTemplateForVideoCall:
      state.user.data.appointmentTemplateForVideoCall,
    templateId: ownProps.match ? ownProps.match.params.templateId : null,
    patientId: state.user.data.patientId
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewAppointmentContainer);
