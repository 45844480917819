import {
  LOAD_ORGANIZATION,
  LOAD_ORGANIZATIONS,
  LOAD_ORGANIZATION_DONE,
  ORGANIZATIONS_LOADED,
  ORGANIZATION_ADD,
  ORGANIZATION_ADD_DONE,
  ORGANIZATION_UPDATE,
  ORGANIZATION_UPDATE_BRANDING,
  ORGANIZATION_UPDATE_BRANDING_DONE,
  ORGANIZATION_UPDATE_DONE,
  ORGANIZATION_EDIT,
  SHOW_TOASTER
} from "../actions/actionTypes";
import { put, select, take } from "redux-saga/effects";

import OrganizationsService from "../../services/organizationsService";
import history from "../../history";
import { updateSpaceDetails } from "./userSagas";

export function* addOrganization() {
  while (true) {
    const action = yield take(ORGANIZATION_ADD);
    yield put({ type: ORGANIZATION_ADD });
    try {
      const data = action.data;
      const result = yield OrganizationsService.add(data);
      yield put({ type: ORGANIZATION_ADD_DONE, data: result });
      yield put({
        type: SHOW_TOASTER,
        data: { message: "Facility has been created.", type: "success" }
      });
      history.push("/facilities");
    } catch (error) {
      // console.log('ERROR', error);
    }
  }
}

// export function* updateOrganization() {
//   while (true) {
//     const action = yield take(ORGANIZATION_UPDATE);
//     try {
//       const { id, name, address, terms } = action.data;
//       const role = yield select(state => state.user.role);
//       const result = yield OrganizationsService.update(
//         role,
//         id,
//         name,
//         address,
//         terms
//       );
//       yield updateSpaceDetails();
//       yield put({
//         type: ORGANIZATION_UPDATE_DONE,
//         data: result
//       });
//       history.push("/facilities");
//     } catch (error) {
//       // console.log('ERROR', error);
//     }
//   }
// }

export function* editOrganization() {
  while (true) {
    const action = yield take(ORGANIZATION_EDIT);
    yield put({ type: ORGANIZATION_UPDATE });
    try {
      const { id, data, origin } = action;
      const role = yield select(state => state.user.role);
      const result = yield OrganizationsService.edit(role, id, data);
      yield put({ type: ORGANIZATION_UPDATE_DONE, data: result });
      yield put({
        type: SHOW_TOASTER,
        data: { message: "Facility has been updated.", type: "success" }
      });
      if (origin) {
        history.push(`/configuration/${origin}`);
      } else {
        history.push("/configuration/facility-info");
      }
    } catch (error) {
      // console.log('ERROR', error);
    }
  }
}

export function* updateOrganizationBranding() {
  while (true) {
    const action = yield take(ORGANIZATION_UPDATE_BRANDING);
    yield put({ type: ORGANIZATION_UPDATE_BRANDING });
    // console.log('UPDATE BRANDING', action);
    try {
      const { id, data } = action;
      const role = yield select(state => state.user.role);
      const result = yield OrganizationsService.updateBranding(role, id, data);
      // load organization data as not all data is in the form
      yield updateSpaceDetails();
      const orgData = yield OrganizationsService.loadSingle(role, id);
      yield put({ type: ORGANIZATION_UPDATE_BRANDING_DONE, data: orgData });
      history.push("/configuration/branding");
    } catch (error) {
      // console.log('ERROR', error);
    }
  }
}

export function* loadOrganizations() {
  while (true) {
    const action = yield take(LOAD_ORGANIZATIONS);

    // get current page and load more
    const organizations = yield select(state => state.organizations);
    // check if loadMore is true or already loading
    // console.log('load organizations', organizations);
    if (
      !organizations.loading &&
      "loadMore" in organizations &&
      !organizations.loadMore
    ) {
      // load more should not fire as loadMore is false
      return false;
    }
    // get page
    const page = organizations.page || 0;
    // start loading more

    // put will set loading flag
    yield put({ type: LOAD_ORGANIZATIONS });
    // load organizations
    try {
      const result = yield OrganizationsService.load(page);
      // console.log('ORGTEST SAGE RESULT RETURNED ', result);
      yield put({
        type: ORGANIZATIONS_LOADED,
        list: result.data,
        page: page + 1,
        loadMore: result.loadMore
      });
    } catch (error) {
      // console.log('SAGE RESULT ERROR ');
      yield put({
        type: ORGANIZATIONS_LOADED,
        list: [],
        page: page + 1,
        loadMore: false
      });
      // console.log('ORGTEST ERROR', error);
    }
  }
}

export function* loadOrganization() {
  while (true) {
    const action = yield take(LOAD_ORGANIZATION);
    // put will set loading flag
    yield put({ type: LOAD_ORGANIZATION });
    // load organizations
    try {
      const role = yield select(state => state.user.role);
      const result = yield OrganizationsService.loadSingle(role, action.id);

      // console.log('DATA SAGA', result);
      yield put({
        type: LOAD_ORGANIZATION_DONE,
        role,
        data: result
      });
    } catch (error) {
      // console.log('ERROR', error);
    }
  }
}
