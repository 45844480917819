import React, { Component } from "react";
import Select from "react-select";
import { FormGroup, Input } from "reactstrap";

class CCCObservation extends Component {
  static getDisplayValue(attributeValue) {
    if (
      attributeValue.valueObservation &&
      attributeValue.valueObservation.value &&
      attributeValue.valueObservation.value.valueCode
    ) {
      return (
        attributeValue.valueObservation.value.valueCode.code +
        " - " +
        attributeValue.valueObservation.value.valueCode.display
      );
    }
  }

  static getOptionsList(options) {
    return options.map(option => {
      let val = option.valueObservation.value.valueCode;
      return {
        value: val.code,
        label: val.code + " - " + val.display
      };
    });
  }

  render() {
    let { attributeValue, choiceList, options, setData } = this.props;

    attributeValue.valueObservation = attributeValue.valueObservation || {};
    attributeValue.valueObservation.value =
      attributeValue.valueObservation.value || {};
    let obsVal = attributeValue.valueObservation.value.valueCode
      ? attributeValue.valueObservation.value.valueCode.code
      : null;

    if (choiceList) {
      return (
        <div className="inputHolder flex-row">
          <Select
            className="ui-attribute-el-select"
            style={{
              minWidth: "175px",
              with: "auto",
              color: "#3e515b"
            }}
            name="form-field-name"
            value={obsVal}
            onChange={option => {
              if (option) {
                // attributeValue.valueObservation.value = option.valObj;
                attributeValue = option.valObj;
                // attributeValue.valueObservation.value.valueCode =
                //   option.value;
                // attributeValue.valueObservation.code =
                //   attributeValue.valueObservation.code || observationCode;
              } else {
                attributeValue.valueObservation.value = {};
                // attributeValue.valueObservation.code = {};
              }
              setData(attributeValue);
            }}
            options={options.map(option => {
              let val = option.valueObservation.value.valueCode;
              return {
                value: val.code,
                valObj: option,
                label: val.code + " - " + val.display
              };
            })}
            onBlur={this.props.onBlur}
          />
        </div>
      );
    } else {
      return (
        <div>
          <div className="inputHolder flex-row">
            <Input
              value={obsVal}
              onChange={e => {
                attributeValue.valueObservation.value.valueCode =
                  attributeValue.valueObservation.value.valueCode || {};
                attributeValue.valueObservation.value.valueCode.code =
                  e.target.value;
                setData(attributeValue);
              }}
              type="text"
              placeholder="Code"
              onBlur={this.props.onBlur}
              className="ui-attribute-el"
            />

            <Input
              value={obsVal}
              onChange={e => {
                attributeValue.valueObservation.value.valueCode =
                  attributeValue.valueObservation.value.valueCode || {};
                attributeValue.valueObservation.value.valueCode.display =
                  e.target.value;
                setData(attributeValue);
              }}
              type="text"
              placeholder="Display"
              onBlur={this.props.onBlur}
              className="ui-attribute-el"
            />
          </div>
        </div>
      );
    }
  }
}

export default CCCObservation;
