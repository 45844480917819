import {
  LOAD_PATIENTS,
  LOAD_PATIENT,
  PATIENT_ADD,
  PATIENTS_RESET_LOADING,
  PATIENT_DELETE,
  SET_PATIENTS_FILTER,
  PATIENTS_RESET_TO_MODE,
  SET_ACTIVE_PATIENT,
  ADD_TO_FILTER_FROM_DASHBOARD
} from "./actionTypes";

export function onLoadPatient(id) {
  return { type: LOAD_PATIENT, id };
}

export function onLoadPatients(searchCriteria) {
  return { type: LOAD_PATIENTS, searchCriteria };
}

export function onAddPatient(data) {
  return { type: PATIENT_ADD, data: data };
}

export function onDeletePatient(patientId) {
  return { type: PATIENT_DELETE, patientId };
}

export function onResetLoading() {
  return { type: PATIENTS_RESET_LOADING };
}

export function onResetToMode(mode) {
  return { type: PATIENTS_RESET_TO_MODE, mode };
}

export function onSetFilter(filter) {
  return { type: SET_PATIENTS_FILTER, filter };
}

export function onSetActivePatient(patientId) {
  return { type: SET_ACTIVE_PATIENT, patientId };
}

export function onAddToPatientFilter(fields) {
  return { type: ADD_TO_FILTER_FROM_DASHBOARD, fields };
}
