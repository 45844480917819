// @flow

import {
  DISMISS_ERROR,
  LOAD_PATIENT,
  LOAD_PATIENTS,
  LOAD_PATIENT_DONE,
  LOG_OUT,
  PATIENTS_LOADED,
  PATIENTS_RESET_LOADING,
  PATIENT_ADD,
  PATIENT_ADD_DONE,
  PATIENT_ADD_ERROR,
  PATIENT_DELETE_DONE,
  PATIENT_DELETE_ERROR,
  PATIENT_UPDATE,
  PATIENT_UPDATE_DONE,
  SET_PATIENTS_FILTER,
  PATIENTS_RESET_TO_MODE,
  SET_ACTIVE_PATIENT,
  ADD_TO_FILTER_FROM_DASHBOARD
} from "../actions/actionTypes";
import { REHYDRATE } from "redux-persist";
import { findWithAttr } from "../../utils/utils";

const initialState = {
  mode: "GETPATIENTS_ALL",
  fresh: true,
  filter: null,
  list: [],
  loading: false,
  page: 0,
  loadMore: true,

  error: false,

  addSuccess: false,
  inviteSuccess: false,
  activePatientId: null
};

const patientsReducer = (state = initialState, action) => {
  let list, index;
  switch (action.type) {
    case PATIENTS_RESET_LOADING:
      return {
        ...state,
        list: [],
        loading: false,
        page: 0,
        loadMore: true,
        error: false,
        addSuccess: false,
        inviteSuccess: false,
        refresh: false
      };

    case PATIENT_UPDATE:
    case PATIENT_ADD:
    case LOAD_PATIENT:
    case LOAD_PATIENTS:
      return { ...state, loading: true };

    case DISMISS_ERROR:
      return { ...state, error: false, errorMsg: "" };

    case PATIENT_ADD_ERROR:
    case PATIENT_DELETE_ERROR:
      return { ...state, loading: false, error: true, errorMsg: action.error };

    case LOAD_PATIENT_DONE:
    case PATIENT_UPDATE_DONE:
    case PATIENT_ADD_DONE:
      list = state.list.slice(0);

      const patientId = action.data.patientId || -1;
      index = findWithAttr(list, "patientId", patientId);

      // not found then return prev state
      if (index === -1)
        return {
          ...state,
          loading: false,
          list: [action.data].concat(list),
          addPatientId: patientId,
          addSuccess:
            action.type === PATIENT_ADD_DONE ||
            action.type === PATIENT_UPDATE_DONE
        };

      // if found, replace whole object
      list[index] = action.data;

      return {
        ...state,
        loading: false,
        list: list,
        addPatientId: patientId,
        addSuccess:
          action.type === PATIENT_ADD_DONE ||
          action.type === PATIENT_UPDATE_DONE
      };

    case PATIENT_DELETE_DONE:
      list = state.list.slice(0);

      index = findWithAttr(list, "patientId", action.patientId);
      if (index === -1) return state;
      list.splice(index, 1);
      return { ...state, list };

    // case PATIENT_UPDATE_DONE:
    //   list = state.list.slice(0);
    //   return { ...state, loading: false, list };

    case PATIENTS_LOADED:
      return {
        ...state,
        loading: false,
        page: action.page,
        loadMore: action.loadMore,
        total: action.total,
        list:
          action.page === 0
            ? action.list
            : state.list.slice(0).concat(action.list)
      };

    case SET_PATIENTS_FILTER:
      return {
        ...state,
        filter: action.filter,
        fresh: false
      };

    case ADD_TO_FILTER_FROM_DASHBOARD:
      let filter = state.filter || {};
      if (
        filter.uiFieldsWithoutOperators &&
        filter.uiFieldsWithoutOperators.length >= 1
      ) {
        return getStateAfterAddingToFilter(
          state,
          action,
          "uiFieldsWithoutOperators"
        );
      } else if (
        filter.uiFieldsWithOperators &&
        filter.uiFieldsWithOperators.length >= 1
      ) {
        return getStateAfterAddingToFilter(
          state,
          action,
          "uiFieldsWithOperators"
        );
      } else {
        return { ...state };
      }

    case PATIENTS_RESET_TO_MODE:
      return { ...initialState, mode: action.mode };

    case SET_ACTIVE_PATIENT:
      return { ...state, activePatientId: action.patientId };

    case LOG_OUT:
      return initialState;

    case REHYDRATE:
      if (action.payload && action.payload.patients) {
        return { ...state, ...action.payload.patients, loading: false };
      }
      return state;

    default:
      return state;
  }
};

function getStateAfterAddingToFilter(state, action, uiFieldsName) {
  let filter = state.filter;
  let uiFields = filter[uiFieldsName];
  uiFields.forEach(uiField => {
    uiField.value = null;
  });
  action.fields.forEach(aField => {
    let index = uiFields.findIndex(field => field.name === aField.fieldName);

    if (index !== -1) {
      let filterField = uiFields[index];
      filterField.value = {
        valueObservation: {
          value: { valueString: aField.fieldValue },
          code: { code: filterField.observationCode.code }
        }
      };
      uiFields[index] = { ...filterField };
      filter[uiFieldsName] = [...uiFields];
    }
  });

  return { ...state, filter: { ...filter }, fresh: true };
}

export default patientsReducer;
