import React, { PureComponent } from "react";
import SignupIntro from "./SignupIntro";
import SignupForm from "./SignupForm";
import SignupConfirmation from "./SignupConfirmation";
import { Card, CardBody, CardGroup, Col, Container, Row } from "reactstrap";
import FacilityLogo from "../UserOnboarding/FacilityLogo";
import MPSBarLoader from "../../components/MPSBarLoader";
import SignupConsent from "./SignupConsent";

class SignupView extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { step: 0 };
    this.noOfSteps = 4;
  }

  decrementStep = () => {
    this.setState({ step: this.state.step - 1 });
    window.scrollTo(0, 0);
  };

  incrementStep = () => {
    this.setState({ step: this.state.step + 1 });
    window.scrollTo(0, 0);
  };

  signUp = data => {
    let { attributes, loginUserId, password } = data;
    this.setState({ submitting: true, confirmationError: null });
    this.props
      .doSignUp(attributes, loginUserId, password)
      .then(response => {
        this.setState({
          submitting: false,
          postSubmitMessage: response.postSubmitMessage
        });
        this.incrementStep();
      })
      .catch(e => {
        this.setState({
          submitting: false,
          confirmationError:
            e.message || "Error while signing up. Please try again later."
        });
      });
  };

  render() {
    let { facilityInfo, loading } = this.props;
    if (loading) {
      return (
        <div style={{ margin: "100px auto", width: 100 }}>
          <MPSBarLoader />
        </div>
      );
    }
    if (!facilityInfo) {
      return null;
    }

    let { step, submitting, confirmationError } = this.state;
    let { addPatientProfile = {} } = facilityInfo;
    let sections = addPatientProfile.sections || [];

    return (
      <div className="app flex-column justify-content-center align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col lg={6} md={10} sm={12}>
              <div className="ob-logos mb-3 justify-content-around mt-2">
                {facilityInfo && facilityInfo.appTheme && (
                  <div className="">
                    <FacilityLogo
                      appTheme={facilityInfo.appTheme}
                      height="60px"
                    />
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </Container>

        <Container>
          <Row className="justify-content-center">
            <Col lg={6} md={10} sm={12}>
              <CardGroup>
                <Card>
                  <CardBody>
                    {step === 0 && (
                      <SignupIntro
                        facilityInfo={facilityInfo}
                        incrementStep={this.incrementStep}
                      />
                    )}

                    {step === 1 && (
                      <SignupConsent
                        facilityInfo={facilityInfo}
                        incrementStep={this.incrementStep}
                        decrementStep={this.decrementStep}
                      />
                    )}

                    {step === this.noOfSteps - 1 && (
                      <SignupConfirmation
                        facilityInfo={facilityInfo}
                        postSubmitMessage={this.state.postSubmitMessage}
                        fromApp={this.props.fromApp}
                        deviceType={this.props.deviceType}
                      />
                    )}

                    {step > 1 && step < this.noOfSteps - 1 && (
                      <SignupForm
                        submitting={submitting}
                        facilityId={facilityInfo.facilityId}
                        sections={sections}
                        incrementStep={this.incrementStep}
                        decrementStep={this.decrementStep}
                        submit={this.signUp}
                        confirmationError={confirmationError}
                        facilityCode={this.props.facilityCode}
                      />
                    )}
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row>
        </Container>

        <Container>
          <Row className="justify-content-center">
            <Col lg={6} md={10} sm={12}>
              <div className="ob-logos mb-3 justify-content-around mt-4">
                <div className="">
                  <img
                    alt="myPatientSpace"
                    src="/img/mps-logo.png"
                    height={24}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default SignupView;
