import { createIntl, createIntlCache } from "react-intl";
import { store } from "../State/store/configureStore";
import { getLangFromLocale } from "../utils/utils";
// import messagesEn from "./../Locales/en.json";
// import messagesAr from "./../Locales/ar.json";
// import { IntlProvider, addLocaleData } from "react-intl";
// import localeEn from "react-intl/locale-data/en";
// addLocaleData([...localeEn]);
// const locale = "fr";
// const messages = {
//   en: messagesEn,
//   ar: messagesAr
// };
// const intlProvider = new IntlProvider({ locale, messages });
// const { intl } = intlProvider.getChildContext();
//
// export default intl;

// This is optional but highly recommended
// since it prevents memory leak
const cache = createIntlCache();
let intl = null;

String.prototype.replaceAt = function(index, replacement) {
  return (
    this.substr(0, index) +
    replacement +
    this.substr(index + replacement.length)
  );
};

export function getLocaleMessages(messages, locale) {
  let endUserLabel = store.getState().user.data.endUserLabel;

  if (
    locale === "en" &&
    endUserLabel &&
    endUserLabel.toLowerCase() !== "patient"
  ) {
    endUserLabel = endUserLabel.toLowerCase();
    let allSmallEL = endUserLabel;
    let camelEL = endUserLabel.replaceAt(
      0,
      endUserLabel.charAt(0).toUpperCase()
    );

    Object.keys(messages).forEach(key => {
      let message = messages[key];

      // if (message.toLowerCase().indexOf(" patient") !== -1) {
      //   let ch = message.charAt(message.toLowerCase().indexOf(" patient") + 1);
      //   if (ch === "P") {
      //     messages[key] = message.replace(/Patient/gi, camelEL);
      //   } else if (ch === "p") {
      //     messages[key] = message.replace(/patient/gi, allSmallEL);
      //   }
      // } else if (
      //   message.toLowerCase().indexOf("patient ") !== -1 ||
      if (message.toLowerCase().indexOf("patient") !== -1) {
        let ch = message.charAt(message.toLowerCase().indexOf("patient"));
        if (ch === "P") {
          messages[key] = message.replace(/Patient/gi, camelEL);
        } else if (ch === "p") {
          messages[key] = message.replace(/patient/gi, allSmallEL);
        }
      }
    });
  }

  return messages;

  // add_patient: "Add Patient";
}

function getProvider() {
  if (!intl) {
    let locale =
      getLangFromLocale(store.getState().user.data.preferredLocale) || "en";
    if (locale) {
      let preMessages = require(`../Locales/${locale || "en"}.json`);
      let messages = getLocaleMessages(preMessages, locale);
      intl = createIntl(
        {
          locale: locale,
          messages: messages
        },
        cache
      );
    }
  }
  return intl;
}

function resetProvider() {
  intl = null;
}

// Call imperatively
// intl.formatNumber(20)

export default {
  getIntlProvider: getProvider,
  resetIntlProvider: resetProvider
};
