import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { Container, Row, Col, CardGroup, Card, CardBody } from "reactstrap";
import userService from "../../services/userService";
import ForgotPasswordForm from "./ForgotPasswordForm";
import { showErrorToaster } from "../../State/actions/toaster";
import MPSBarLoader from "../../components/MPSBarLoader";
import { parseURLQueryParams } from "../../utils/utils";

class ForgotPasswordView extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  sendForgotPasswordReq = phone => {
    this.setState({ loading: true });
    userService
      .processForgotPasswordRequest(phone)
      .then(response => {
        this.setState({ loading: false });
        this.setState({ status: "succeeded" });
      })
      .catch(error => {
        this.setState({ loading: false });
        this.props.showErrorToaster(
          error,
          "Request for password reset has failed. Please try again.",
          7
        );
      });
  };

  render() {
    let { status, loading } = this.state;
    return (
      <div className="app flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md="8">
              {loading && <MPSBarLoader />}
              <CardGroup>
                <Card className="p-4">
                  <div className="d-flex justify-content-center mb-4">
                    <img src="/img/logo-symbol.png" height={42} />
                  </div>
                  <CardBody>
                    {!status && (
                      <ForgotPasswordForm
                        sendRequest={this.sendForgotPasswordReq}
                        linkType={this.props.linkType}
                      />
                    )}
                    {status === "succeeded" && (
                      <div className="text-center">
                        Please check your SMS and/or Email. We have sent you the
                        steps to reset your password.
                      </div>
                    )}
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

const actions = {
  showErrorToaster
};

const data = (state, ownProps) => {
  let params = null;
  if (ownProps.location && ownProps.location.search) {
    params = parseURLQueryParams(ownProps.location.search);
  }

  return {
    linkType: params ? params.cp : null
  };
};

export default connect(
  data,
  actions
)(ForgotPasswordView);
