import React, { Component } from "react";
import { FormGroup, Input } from "reactstrap";
import moment from "moment";

class DateObservation extends Component {
  static getDisplayValue(attributeValue) {
    if (
      attributeValue.valueObservation &&
      attributeValue.valueObservation.value
    ) {
      if (attributeValue.valueObservation.value.valueDate) {
        return new Date(
          attributeValue.valueObservation.value.valueDate
        ).toLocaleDateString();
      }
      return null;
    }
  }

  static getValueForSubmission(attributeValue) {
    if (
      attributeValue.valueObservation &&
      attributeValue.valueObservation.value &&
      attributeValue.valueObservation.value.valueDate
    ) {
      attributeValue.valueObservation.value.valueDate = new Date(
        attributeValue.valueObservation.value.valueDate
      ).getTime();
    }
    return attributeValue;
  }

  render() {
    let { attributeValue, setData, observationCode } = this.props;

    attributeValue.valueObservation = attributeValue.valueObservation || {};
    attributeValue.valueObservation.value =
      attributeValue.valueObservation.value || {};
    let obsVal = attributeValue.valueObservation.value.valueDate;
    attributeValue.valueObservation.code = observationCode;

    return (
      <div className="inputHolder flex-row">
        <Input
          className="ui-attribute-el"
          defaultValue={obsVal ? moment(obsVal).format("YYYY-MM-DD") : null}
          // value={moment(obsVal).format("YYYY-MM-DD")}
          onChange={e => {
            attributeValue.valueObservation.value.valueDate = e.target.value;
            setData(attributeValue);
          }}
          type="date"
          onBlur={this.props.onBlur}
        />
      </div>
    );
  }
}

export default DateObservation;
